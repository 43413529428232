<template>
	<section id="tw-view-media-library" class="tw-content col-12">
		<div class="bg-white">
			<div class="p-4">
				<div class="tw-table-header row">
					<div class="tw-table-search-wrapper col-lg-6 text-end">
                        <input type="search" class="form-control" v-model="querySearch" placeholder="Search Media" />
                        <span class="tw-table-header-search-icon"><i class="fas fa-search"></i></span>
                    </div>
                    <div class="col text-end mt-2 mt-lg-0 d-flex">
                        <div id="tw-media-lib-types-wrapper" class="position-relative tw-default-text-color">
                            <span class="position-absolute top-0 bottom-0 start-0 end-0 tw-light-grey-bg-color d-flex align-items-center ps-3">
                                <i class="fas fa-filter me-2"></i> Filter:
                            </span>
                            <select class="form-select text-capitalize tw-default-text-color" v-model="type" id="tw-media-lib-types">
                                <option :value="false" selected>All</option>
                                <option v-for="(type, index) in types" :value="type.slug" :key="index" v-html="type.label" class="text-capitalize ps-2"></option>
                            </select>
                        </div>
						<button class="tw-default-bg-color text-white d-block d-lg-flex align-items-center ms-auto justify-content-center px-3 me-auto me-lg-0" v-if="selected" @click.prevent="bulkDelete">
							<i class="fa fa-trash"></i>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1">Delete {{ selected }} Media</span>
						</button>
                        <button  for="media" class="tw-red-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0" @click="triggerFileInputClick">
							<span class="d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
								<i class="fas fa-plus"></i>
							</span>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1 pe-5">Add Media</span>
                        </button>
                        <input type="file" class="d-none" ref="media" id="media" @change="handleFileUploads($event)" multiple>
                    </div>
                </div>
                <div class="table-responsive position-relative">
                    <table ng-if="!vm.permissions.notices" st-table="vm.results" st-pipe="vm.loadResults" class="tw-table table text-nowrap">
                        <thead>
                            <tr>
								<th></th>
                                <th>File</th>
                                <th>Name</th>
                                <th st-sort="date">
                                    <span class="cursor-pointer d-flex align-items-center" @click="setOrder('date')">
                                        <span class="me-3">Date</span>
                                        <span v-if="orderBy != 'date'" class="d-inline-flex align-items-center justify-content-center">
                                            <span class="tw-toggle-angle-up me-2">
                                                <span class="d-block"></span>
                                            </span>
                                            <span class="tw-toggle-angle-down">
                                                <span class="d-block"></span>
                                            </span>
                                        </span>
                                        <span v-if="orderBy == 'date' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
                                            <span class="tw-toggle-angle-up">
                                                <span class="d-block"></span>
                                            </span>
                                        </span>
                                        <span v-if="orderBy == 'date' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
                                            <span class="tw-toggle-angle-down">
                                                <span class="d-block"></span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th>Type</th>
                            </tr>
                        </thead>

                        <tbody class="position-relative">
							<div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
								<i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
							</div>
                        <!-- <tbody v-if="posts && posts.length"> -->
                            <tr v-for="(media, index) in mediaLibrary" :class="[(media.type == 'tw_alerts' && 'red'), (media.selected && 'tw-media-lib-selected')]" :key="index" class="cursor-pointer">
								<td class="tw-checkbox"><input :id="'media' + media.id" type="checkbox" @click="countSelected(index, $event)" value="1" :checked="media.checked"/><label :for="'media' + media.id"></label></td>
                                <td>
                                    <img v-if="media.media_type == 'image'" :src="(media.media_details.sizes && Object.keys(media.media_details.sizes).length) ? media.media_details.sizes.thumbnail.source_url : media.source_url" height="53" width="55">
                                    <span v-else  class="d-flex align-items-center">
                                        <span v-html="defaultImage"></span>
                                    </span>
                                </td>
                                <td><a class="text-decoration-none tw-red-color" :href="media.source_url" target="_blank"><span class="tw-media-library-filename" v-html="sliceString((media.name ?? media.filename), 70)" data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top" :title="media.filename"></span></a> <span :ref="`media-${media.id}`" class="tw-grey-color ms-2" data-bs-toggle="tooltip" data-bs-placement="top" :title="media.clicked ? 'Copied!' : 'Copy link'" @click.prevent="copyLink(index, `media-${media.id}`, media.source_url)"><i class="fas fa-clipboard"></i></span></td>
                                <td>{{ moment(media.post_date || media.date).format('ll') }}</td>
                                <td>{{media.media_type}}</td>

                            </tr>
                        </tbody>

                        <!-- <tbody v-if="!requestResolved && (!posts || !posts.length)">
                            <tr>
                                <td colspan="6" class="text-center">Loading <i class="fa fa-spinner fa-spin"></i></td>
                            </tr>
                        </tbody> -->

                        <tbody v-if="requestResolved && (!mediaLibrary || !mediaLibrary.length)">
                            <tr>
                                <td colspan="6" class="text-center">Nothing to show, no results found.</td>
                            </tr>
                        </tbody>

                        <tfoot v-if="mediaLibrary.length">


                            <tr>
                                <td colspan="3">
                                    Rows per page
                                    <select v-model="perPage" class="border">
                                        <option :value="5">5</option>
                                        <option :value="10">10</option>
                                        <option :value="20">20</option>
                                    </select>
                                </td>
                                <td colspan="4" class="tw-table-pagination text-end">

                                    <Pagination
                                        :totalItems="numberOfMedia"
                                        :currentPage="page"
                                        :perPage="perPage"
                                        @pageChanged="setPage"
                                        :goButton="false"
                                        styled="centered"
                                        borderActiveColor=""
                                        borderTextActiveColor="text-white"
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
			</div>
		</div>
	</section>
</template>

<script>
	import wordpressMixin from '@/mixins/wordpressMixin';
    import cms6Mixin from '@/mixins/cms6Mixin';
	import Pagination from '@/components/pagination/Pagination';
    import helperMixin from '@/mixins/helperMixin';
    import { Tooltip } from "bootstrap";

    let site = localStorage.getObject('site');

    let mixins;

    if (site.type == 'wordpress') {
        mixins = [wordpressMixin, helperMixin];
    } else if(site.type == 'cms6') {
        mixins = [cms6Mixin, helperMixin];
    }

	export default {
		name: 'MediaLibrary',
		components: {
			Pagination
		},
		data: () => ({
			mediaLibrary: [],
            requestResolved: false,
            type: false,
            types: [{slug: 'image', label:'Image'}, {slug:'application', label:'File'}],
            perPage: 10,
            page: 1,
            numberOfMedia: 0,
            numberOfPages: 0,
            querySearch: '',
            order: 'desc',
            orderBy: 'date',
            defaultImage: '<div class="tw-defaul-featured-img bg-white border"><i class="fas fa-file"></i></div>',
            selected: 0,
            requestingFile: false,
            allowMulti: false,
            onlyImageType: false,
            copiedIndex: null,
            copiedRef: null,
		}),
		mounted() {
			this.setMediaLibrary();
		},
        updated() {
            for (const media of this.mediaLibrary) {
                if (!Tooltip.getInstance(this.$refs[`media-${media.id}`][0])) {

                    new Tooltip(this.$refs[`media-${media.id}`][0])
                }
            }

                // console.log(this.copiedIndex)
            if (this.copiedIndex !== null) {


                let tooltip = Tooltip.getInstance(this.$refs[this.copiedRef][0]);

                tooltip.dispose();
                tooltip = Tooltip.getOrCreateInstance(this.$refs[this.copiedRef][0]);
                tooltip.show();


                if (this.copiedRef) {

                    setTimeout(() => {
                        this.mediaLibrary[this.copiedIndex].clicked = false;
                        tooltip.dispose()

                        this.copiedRef = null;
                        this.copiedIndex = null;
                    }, 2000);
                }
            }

            let tooltopElements = document.querySelectorAll('.tw-media-library-filename');

            tooltopElements.forEach(element => {

                let tooltip = Tooltip.getInstance(element);

                if (tooltip) {
                    tooltip.dispose();
                }

                if (!Tooltip.getInstance(element)) {
                    new Tooltip(element)
                }
            });
        },
		watch: {
			perPage: function () {
				// this.pages = [];
				this.setMediaLibrary();
			},
			page: function () {
				// this.pages = [];
				this.setMediaLibrary();
			},
			orderBy: function () {
				// this.pages = [];
				this.setMediaLibrary();
			},
			order: function () {
				// this.pages = [];
				this.setMediaLibrary();
			},
			querySearch: function () {
				// this.pages = [];
                if (this.page === 1) {
                    this.setMediaLibrary();
                } else {
                    this.page = 1;
                }
			},
            type: function () {
                // this.pages = [];
                if (this.page === 1) {
                    this.setMediaLibrary();
                } else {
                    this.page = 1;
                }
            }
		},
		methods: {
			async setMediaLibrary() {
                this.requestResolved = false;

                this.$el.querySelectorAll("td.tw-checkbox input").forEach( function(checkbox){
					checkbox.checked = false;
				});
				this.selected = 0;


                let res = await this.getMediaLibrary(this.page, this.perPage, this.order, this.orderBy, this.querySearch, this.onlyImageType, this.type);

                if (res && res.status === 200) {
                    this.requestResolved = true;
                    this.mediaLibrary = res.data || [];
                    this.numberOfShowedMedia = this.mediaLibrary.length;
                    this.numberOfMedia = parseInt( res.headers['x-wp-total'] );
                    this.numberOfPages = parseInt( res.headers['x-wp-totalpages'] );

                    let self = this;

                    this.mediaLibrary.forEach(function(media,index){

                        self.mediaLibrary[index].filename = media.source_url.split('/').pop();
                    })
                }

            },
            async handleFileUploads (event) {
                // this.mediaLibrary = [];
                this.requestResolved = false;
                let files = Array.from(event.target.files);

                // console.log(Array.from(files))

                let self = this;

                await Promise.all(files.map( async function(file){

                    let res = await self.uploadFile(file);

                    if (res.data && res.data.type && res.data.type == 'error') {

                        let text = res.data.errors.file.join('<br><br>');

                        self.$swal({
                            toast: true,
                            showConfirmButton: true,
                            timer: 10000,
                            title: 'Invalid file!',
                            html: text,
                            position: "top-end",
                            icon: "error",
                            closeModal: true,
                            timerProgressBar: true,
                        });
                    }

                }));
                this.setMediaLibrary();
                this.$refs.media.value = '';
            },
            countSelected(index) {

                this.mediaLibrary[index].selected = !this.mediaLibrary[index].selected;

                this.selected = this.mediaLibrary.filter(function(media) {return media.selected}).length;
            },
            setPage(n) {
                this.page = n;
            },
            setOrder(orderBy) {
                if (this.orderBy != orderBy) {
                    this.order = 'desc';
                } else if (this.orderBy == orderBy && this.order == 'desc') {
                    this.order = 'asc';
                } else if (this.orderBy == orderBy && this.order == 'asc') {
                    this.order = 'desc';
                }
                this.orderBy = orderBy;
            },
            triggerFileInputClick() {
				this.$refs.media.click();
            },
            async bulkDelete () {


				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete all selected files?",
                    confirmButtonText: 'Yes, delete!'
                }).then(async (result) => {

					if (result.isConfirmed) {
						this.requestResolved = false;

						let self = this;
						let mediaLibrary = this.mediaLibrary;

						await Promise.all(mediaLibrary.map( async function(media){

							if (media.selected) {
								await self.deleteMedia(media.id);
							}

						}));

						this.setMediaLibrary();

						this.$swal(
							'Deleted!',
							"Files have been deleted!",
							'success',
						);

					}
                });
			},
            // copyLink() {
            copyLink(index, ref, link) {
                navigator.clipboard.writeText(link);

                this.mediaLibrary[index].clicked = true;
                this.copiedRef = ref;
                this.copiedIndex = index;
            }
		},
		mixins: mixins
    }
</script>
<style scoped>
    select.form-select {
        background-image: none;
        padding: 0 10px 0 95px;
    }
    .tw-table-header #tw-media-lib-types-wrapper {
        font-size: 18px;
    }
    .tw-table-header #tw-media-lib-types-wrapper span {
        border-radius: 10px;
    }
    .tw-table-header #tw-media-lib-types {
        border-radius: 10px !important;
        background-color: transparent;
        position: relative;
    }
</style>
