<!-- Modal -->
<template>
    <div class="modal fade" id="createPostModal" ref="createPostModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog" :class="mediaLibOpened ? 'modal-xl' : 'modal-lg'">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="d-flex">
                        <span class="tw-create-modal-icon d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                            <i class="fas fa-plus"></i>
                        </span>
                        <span class="d-inline-flex">
                            <div v-if="!editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Create News or Notice</h5>
                                <p class="col-12 mb-0">Create a news or notice for your site</p>
                            </div>
                            <div v-if="editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Edit "{{ sliceString( title ) }}" news / notice</h5>
                                <p class="col-12 mb-0">Edit the contents of this news or notice</p>
                            </div>
                        </span>
                    </div>
                </div>

                <p class="text-center mt-3" v-show="editing && !post.title">Loading news or notice details <i class="fa fa-spinner fa-spin"></i></p>

                <form v-show="!editing || (editing && post && post.title)" @submit.prevent.stop="storeNewPost">
                    <div class="modal-body" id="modal-body">
                        <div v-show="sendError" class="alert alert-danger" role="alert">Error: Post is not saved.</div>
                        <div class="row">
                            <div class="col">

                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group mb-4">
                                            <label for="post-title" class="mb-2">Title</label>
                                            <input type="text" class="form-control mb-4" v-model="title" required id="post-title" placeholder="Title of the news/notice">

                                            <div v-if="siteType == 'cms6' && editing && post && post.slug" class="mt-1 mb-4">
                                                <small>
                                                    <a class="text-decoration-none" :href="siteUrl + '/' + post.slug_prefix + post.slug" target="_blank">{{ siteUrl + '/' + post.slug_prefix }}<span v-if="!slugEditing">{{ post.slug }}</span> </a>
                                                </small>

                                                <small v-if="slugEditing"><input class="tw-slug-edit-input" v-model="post.slug" type="text"></small>
                                                <span v-if="siteType == 'cms6' && userHasCms6Permission('edit_news_notice_slug')">
                                                    <span v-if="!slugEditing" class="btn btn-sm btn-info text-white py-0 ms-2" @click="slugEditing = !slugEditing"><small>Edit</small></span>
                                                    <span v-else >
                                                        <span class="btn btn-sm btn-info text-white py-0 mx-2" @click="saveSlug"><small v-if="!slugSaving">Ok</small><small v-else><i class="fas fa-spinner fa-spin"></i></small></span>
                                                        <span><small><a href="#" @click="slugEditing = !slugEditing">Cancel</a></small></span>
                                                    </span>
                                                </span>
                                            </div>

                                            <label for="post-category" class="mb-2">Category</label>
                                            <select class="form-control mb-4" required v-model="category" id="post-category">
                                                <option v-for="category in categories" :value="category.id" v-html="category.name" :key="category.id"></option>
                                            </select>

                                            <label for="post-category" class="mb-2">Status</label>
                                            <select class="form-control mb-4 text-capitalize" required v-model="status" id="post-status">
                                                <option v-for="status in statuses" :value="status" v-html="status" :key="status"></option>
                                            </select>

                                        </div>
                                    </div>

                                    <div class="col-xl-6 d-flex flex-column">
                                        <label class="mb-sm-3">Featured Image</label>
                                        <div class="row flex-grow-1 align-items-center mb-sm-3">
                                            <div class="col-md-6 mb-3 d-flex justify-content-center">
                                                <div class="position-relative">
                                                    <label for="featured_media" class="d-flex tw-modal-featured-img cursor-pointer align-items-center justify-content-center">
                                                        <img v-if="featuredMedia" :src="( featuredMedia && featuredMedia.media_details && featuredMedia.media_details.sizes && Object.keys(featuredMedia.media_details.sizes).length ) ? featuredMedia.media_details.sizes.thumbnail.source_url : featuredMedia.source_url || defaultImage" :alt="post.post_title || post.title" height="175" width="188">
                                                        <span v-else class="d-flex align-items-center justify-content-center">
                                                            <span v-html="defaultImage"></span>
                                                        </span>
                                                    </label>

                                                    <div v-if="imageUploads" id="tw-modal-featured-img-loading-overlay" class="position-absolute opacity-25 bg-dark top-0 start-0 end-0 bottom-0 opacity-75 tw-light-grey-bg-color d-flex align-items-center justify-content-center">
                                                        <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                                                    </div>
                                                    <div v-if="featuredMedia" id="tw-modal-featured-img-overlay" class="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center cursor-pointer" @mouseover="hoverOverlay = true" @mouseleave="hoverOverlay = false" :class="{'tw-modal-featured-img-overlay-hovered': hoverOverlay}">
                                                        <div class="d-flex">
                                                            <label for="featured_media" class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 me-1 cursor-pointer">
                                                                <i class="fas fa-image fa-2x text-white"></i>
                                                            </label>
                                                            <span class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 ms-1 cursor-pointer" @click="post.featured_media = null; featuredMedia = null">
                                                                <i class="fas fa-trash fa-2x text-white"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input ref="featured_media" type="file" class="d-none" id="featured_media" @change="handleFileUpload($event)" @click="resetImageUploader" accept="image/png, image/jpg, image/jpeg">
                                            </div>
                                            <div class="col-md-6 mb-3 d-none d-md-flex justify-content-center">
                                                <div class="tw-modal-media-lib tw-light-grey-bg-color d-flex align-items-center justify-content-center cursor-pointer" @click="mediaLib($event, chooseFeaturedImg)">
                                                    <div class="tw-modal-media-lib-btn tw-grey-bg-color d-flex align-items-center text-white">
                                                        <i class="fas fa-plus"></i> <span class="ms-2">Media Library</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="row mb-2">
                                        <label class="col-md-6 d-flex align-items-center" for="post-content">Description</label>
                                    </div>
                                    <editor
                                    api-key=""
                                    v-model="postContent"
                                    theme="advanced"
                                    theme_advanced_toolbar_location="bottom"
                                    :init="tinymcInit"
                                    @execCommand="tinyMCEMedLib"
                                    />
                                </div>

                                <div class="form-group">
                                    <label v-if="!editing" class="mt-3 mb-2">Set post date here <small class="fw-normal"><i>(Optional)</i></small></label>
                                    <label v-else class="mt-3 mb-2">Publish Date</label>
                                    <!-- <label class="mt-3 mb-2">Schedule Post</label> -->
                                    <div class="row">
                                        <div class="col-md-4 mb-3">
                                            <label id="tw-create-modal-schedule-from" class="tw-create-modal-schedule p-2 position-relative w-100 cursor-pointer">
                                                <div>
                                                    <small>From</small>
                                                </div>
                                                <div class="position-relative">
                                                    <i class="fas fa-calendar"></i>
                                                    <Datepicker class="tw-create-post-modal-date-from position-absolute" menuClassName="tw-create-post-modal-date-from-menu" teleport="#tw-create-modal-schedule-from" inputClassName="border-0 bg-transparent" :format="format" altPosition hideInputIcon v-model="from" placeholder="" weekStart="0" />
                                                </div>
                                            </label>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <label id="tw-create-modal-schedule-to" class="tw-create-modal-schedule p-2 position-relative w-100 cursor-pointer">
                                                <div>
                                                    <small>To</small>
                                                </div>
                                                <div class="position-relative">
                                                    <i class="fas fa-calendar"></i>
                                                    <Datepicker class="tw-create-post-modal-date-to position-absolute" menuClassName="tw-create-post-modal-date-to-menu" teleport="#tw-create-modal-schedule-to" inputClassName="border-0 bg-transparent" :format="format" altPosition hideInputIcon v-model="to" placeholder="" weekStart="0" />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <small v-if="!editing"><i>To schedule a post please select a date in <b>From</b> for the future.</i></small>
                                </div>
                            </div>
                            <MediaLibraryInModal ref="mediaLibrary" :mediaLibOpened="mediaLibOpened" @mediaLibClose="mediaLibOpened = false" @setMedia="handleSetFeaturedImage" @mediaInsert="handleMediaInsert" />
                            <!-- <div class="anime d-flex" :class="mediaLibOpened ? 'col-4 collapse show' : 'collapsed'">
                                <div class="tw-create-modal-media-lib-wrapper d-flex w-100" :class="!mediaLibOpened && 'd-none'">
                                    <div class="row flex-grow-1 w-100">
                                        <div class="col mx-4 tw-light-grey-bg-color p-3">
                                            <label class="mb-3">Media Library</label>
                                            <p>A complete list of the files located on your site</p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>


                    <div class="modal-footer"  :class="mediaLibOpened ? 'col-8' : 'col-12'">
                        <div v-if="supportsFb" class="tw-checkbox col d-flex align-items-center">
                            <input id="tw-fb-auto" type="checkbox" v-model="fbAuto">
                            <label class="fw-bold" for="tw-fb-auto">Enable auto publishing to my facebook account</label>
                        </div>
                        <button class="btn tw-red-bg-color text-white">
                            Save
                            <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                        </button>
                        <button class="btn tw-dark-grey-bg-color text-white" type="button" @click="modal.hide()">Cancel</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <!-- <MediaModal ref="MediaModal" @mediaInsert="handleMediaInsert" @setMedia="handleSetFeaturedImage" /> -->
</template>
<script>
    import { Modal } from 'bootstrap';
    import MediaLibraryInModal from '@/components/media-library/MediaLibraryInModal';
    // import '@vueup/vue-quill/dist/vue-quill.bubble.css'
    import editor from '@tinymce/tinymce-vue'
    import tinymceConfig from '@/tinymce/config';
    import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
    import helperMixin from '@/mixins/helperMixin';
    import siteMixin from '@/mixins/siteMixin';
    import wordpressMixin from '@/mixins/wordpressMixin';
    import cms6Mixin from '@/mixins/cms6Mixin';

    let site = localStorage.getObject('site');

    let mixins;

    if (site.type == 'wordpress') {
        mixins = [wordpressMixin,siteMixin,helperMixin];
    } else if(site.type == 'cms6') {
        mixins = [cms6Mixin,siteMixin,helperMixin];
    }
    export default {
        name: 'CreatePostModal',
        components: {
            MediaLibraryInModal,
            editor,
        },
        emits: ["modalHidden", "postSaved", "slugUpdated"],
        data: () => ({
            modal: null,
            toast: null,
            title: '',
            postContent: '',
            defaultImage: '<div class="tw-defaul-featured-img tw-light-grey-bg-color d-flex align-items-center"><i class="fas fa-image me-2"></i> <span>Upload Image</span></div>',
            featuredMedia: null,
            file: null,
            categories: null,
            category: null,
            spinner: false,
            post: {},
            status: 'publish',
            statuses: ['publish', 'draft'],
            chooseFeaturedImg: true,
            format: 'LLL dd, Y, hh:mm a',
            mediaLibOpened: false,
            from: null,
            to: null,
            fbAuto: false,
            imageUploads: false,
            hoverOverlay: false,
            sendError: false,
            site_type: null,

            xyz_smap_message: "New post added at {BLOG_TITLE} - {POST_TITLE}",

            // New post - FB
            // xyz_smap_post: 0,
            // xyz_smap_post_permission: 1,
            // xyz_smap_po_method: 1,
            // xyz_smap_fb_info: 0,
            // xyz_smap_message: "New post added at {BLOG_TITLE} - {POST_TITLE}",

            // New post - No FB
            // xyz_smap_post: 0
            // xyz_smap_post_permission: 0
            // xyz_smap_po_method: 1
            // xyz_smap_fb_info: 0
            // xyz_smap_message: New post added at {BLOG_TITLE} - {POST_TITLE}

            // Update post -FB
            // xyz_smap_post: 1
            // xyz_smap_post_permission: 1
            // xyz_smap_po_method: 1
            // xyz_smap_fb_info: 0
            // xyz_smap_message: New post added at {BLOG_TITLE} - {POST_TITLE}

            // Update post - No FB
            // xyz_smap_post: 1
            // xyz_smap_post_permission: 0
            // xyz_smap_po_method: 1
            // xyz_smap_fb_info: 0
            // xyz_smap_message: New post added at {BLOG_TITLE} - {POST_TITLE}
            slugEditing: false,
            slugSaving: false,
        }),
        mounted() {
            this.site_type = this.getSiteType();
            this.modal = new Modal(this.$refs.createPostModal)

            if (this.site_type == 'cms6') {
                this.statuses.push('future');
            }
            // this.showToast()

            let createPostModal = document.getElementById('createPostModal')

            // Prevent Bootstrap dialog from blocking focusin
            document.addEventListener('focusin', (e) => {
              if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
              }
            });

            let self = this;
            //hide.bs.modal
            createPostModal.addEventListener('hide.bs.modal', function () {
                let el = document.querySelector('button[aria-label="More..."][aria-expanded="true"]');

                if (el) {
                    el.click();
                }
            });
            createPostModal.addEventListener('hidden.bs.modal', function () {
                getTinymce().activeEditor.ui.hide();
                self.$emit('modalHidden');
                self.post = {};
                self.title = '';
                self.postContent = '';
                self.featuredMedia = null;
                self.file = null;
                self.category = null;
                self.sendError = false;
                self.mediaLibOpened = false;
                self.from = null;
                self.to = null;
                self.fbAuto = false;
                // self.$refs.editor.setHTML('');
            });
        },
        watch: {
            editingPostId () {

                if (this.editingPostId) {
                    this.setPost();
                }
            },
        },
        methods: {
            showModal: function(value) {
                if (value) {
                    this.modal.show();
                    this.setCategories();
                }
            },
            async setPost() {
                let res;
                if (this.site_type == 'wordpress') {
                    res = await this.getPost(this.$props.editingPostId);
                } else if(this.site_type == 'cms6') {
                    res = await this.getNewsNotice(this.$props.editingPostId);
                }
                this.post = await res.data;

                this.title = this.post.title.rendered || this.post.title;
                this.postContent = (typeof this.post.content.rendered !== 'undefined') ? this.post.content.rendered : this.post.content;
                // this.$refs.editor.setHTML(this.postContent);
                // this.$refs.editor.pasteHTML(this.postContent);
                this.category = this.post.categories[0].id || this.post.categories[0];
                this.status = this.post.status;
                this.from = this.post.date;
                this.to = this.post.expiry_date;
                let self = this;
                if (this.post._embedded && this.post._embedded['wp:featuredmedia']) {
                    this.featuredMedia = this.post._embedded['wp:featuredmedia'].find(function(media) {
                                            return media.id == self.post.featured_media;
                                        });
                } else if(this.post.featured_media && this.post.featured_media.media_details && this.post.featured_media.media_details.sizes && Object.keys(this.post.featured_media.media_details.sizes).length) {
                    this.featuredMedia = this.post.featured_media;
                }
            },
            async setCategories() {
                let res;
                if (this.site_type == 'wordpress') {
                    res = await this.getCategories();
                } else if(this.site_type == 'cms6') {
                    res = await this.getNewsNoticesCategories();
                }
                this.categories = await res.data;
            },
            async handleFileUpload(event) {

                if (event.target.files[0]) {

                    this.imageUploads = true;
                    this.file = event.target.files[0];

                    let res = await this.uploadFile(this.file, true);

                    if (res.data && res.data.type && res.data.type == 'error') {

                        let text = res.data.errors.file.join('<br><br>');

                        this.$swal({
                            toast: true,
                            showConfirmButton: true,
                            timer: 10000,
                            title: 'Invalid file!',
                            html: text,
                            position: "top-end",
                            icon: "error",
                            closeModal: true,
                            timerProgressBar: true,
                        });
                    } else {

                        this.featuredMedia = await res.data;
                    }


                    this.imageUploads = false;

                    if (this.mediaLibOpened) {
                        this.$refs.mediaLibrary.setMediaLibrary();
                    }
                }
            },
            async storeNewPost() {

                this.spinner = true;
                let categories = [];
                categories.push(this.category);

                let mediaId = null;
                let res;
                if (this.featuredMedia) { mediaId = this.featuredMedia.id }

                this.post.title = this.title;
                this.post.content = this.postContent;
                this.post.categories = categories;
                this.post.status = this.status;
                this.post.featured_media = mediaId;
                this.post.date = this.from;
                this.post.expiry_date = this.to ?? 0;
                this.post.xyz_smap = this.fbAuto ? 0 : 1; // xyz_smap for FB publishing, if 0 it means that post is not FB published yet
                this.post.status_draft = ( this.post.status === 'draft' ) ? 1 : 0;
                this.post.status = 'draft';
                this.post.comment_status = 'closed';
                this.post.ping_status = 'closed';
                if (this.site_type == 'wordpress') {
                    this.post.rest = 1;
                }

                if (this.post.meta && (this.post.meta['tw-notifications-sent'] === '')) {
                    delete(this.post.meta['tw-notifications-sent']);
                }

                if (this.fbAuto) {
                    // this.post.xyz_smap_post = this.editingPostId ? 1 : 0;
                    // this.post.xyz_smap_post_permission = 1;
                    // this.post.xyz_smap_po_method = 1;
                    // this.post.xyz_smap_fb_info = 0;
                    // this.post.xyz_smap_message = this.xyz_smap_message;
                }

                if (this.editingPostId) {
                    if (this.site_type == 'wordpress') {
                        res = await this.updatePost(this.post)
                    } else if(this.site_type == 'cms6') {
                        res = await this.updateNewsNotice(this.post);
                    }
                } else {
                    if (this.site_type == 'wordpress') {
                        res = await this.savePost(this.post);
                    } else if(this.site_type == 'cms6') {
                        res = await this.saveNewsNotice(this.post);
                    }
                }

                this.spinner = false;

                if (res.status === 201 || res.status === 200) {
                    let action = 'saved';
                    if (!this.from && !this.editingPostId && !this.post.status_draft) {
                        action = 'published';
                    }
                    this.modal.hide();
                    this.$emit('postSaved', action);
                } else {
                    this.sendError = true;
                }
            },
            mediaLib(event, chooseFeaturedImg = false) {
                if (this.mediaLibOpened) {
                    this.mediaLibOpened = false;
                } else {
                    let allowMulti = !chooseFeaturedImg;
                    this.$refs.mediaLibrary.mediaLibOpen(true, chooseFeaturedImg, allowMulti, chooseFeaturedImg);
                    this.mediaLibOpened = true;
                }
            },
            tinyMCEMedLib(event) {
                // console.log(event)
                if (event.command === 'openMediaLib') {
                    this.mediaLib(false)
                }
            },
            handleMediaInsert(content) {

                getTinymce().activeEditor.insertContent(content);
            },
            handleSetFeaturedImage(image) {
                this.featuredMedia = image;
            },
            resetImageUploader() {
                this.$refs.featured_media.value = '';
            },
            async saveSlug() {
                this.slugSaving = true;
                let res = await this.newsNoticeSlugSave(this.post.id, this.post.slug);

                if (res.status === 200) {
                    this.post.slug = res.data;
                    this.$emit("slugUpdated");
                }
                this.slugEditing = false;
                this.slugSaving = false;
            }
        },
        computed: {
            tinymcInit() {
                return tinymceConfig.config;
            },
            supportsFb () {
                return this.supportsFBPublish();
            },
            siteUrl() {
                return this.getSiteUrl();
            },
        },
        props: {
            editing: Boolean,
            editingPostId: Number,
            // posts: Array,
            // columns: Array,
            // title: String,
            // description: String,
            // contentWrapperClasses: String,
            // withFilter: {
            //     type: Boolean,
            //     default: false
            // }
        },
        mixins: mixins
    }
</script>
<style scoped>
    #tw-modal-featured-img-loading-overlay {
        border-radius: 10px;

    }
    #tw-modal-featured-img-overlay {
        transition: .3s;
        background: rgba(255, 255, 255, 0);
    }
    #tw-modal-featured-img-overlay * {
        opacity: 0;
        transition: .3s;
    }
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered {
        background: rgba(255, 255, 255, .25);
    }
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered * {
        opacity: 1;
    }
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > span:hover,
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > label:hover {
        transform: scale(1.1);
    }
    #tw-modal-featured-img-overlay > div > span {
        width: 50px;
        height: 50px;
    }
    .tw-create-post-modal-date-from,
    .tw-create-post-modal-date-to {
        top: -10px;
        left: 10px;
    }
    .modal-dialog {
        /*min-width: 80%;*/
    }
    .collapsed {
      width: 0;
    }
    .anime {
      /*flex-shrink: 1;*/
      transition: all 300ms ease;
    }
    .tw-create-modal-media-lib-wrapper {
        border-left: 1px solid #E0E0E0;
    }
    .modal-xl {
        max-width: 90%;
    }
    .tw-checkbox label {
        margin-left: 35px;
    }
    .tw-checkbox label::before {
        top: calc(50% - 12px);
        left: -35px;
    }
    .tw-checkbox label::after {
        top: calc(50% - 6px);
        left: -29px;
    }
    @media only screen and (min-width: 992px) {
        .modal-lg {
            max-width: 900px;
        }
    }
    @media only screen and (max-width: 991.99px) {
        .modal-dialog {
            /*min-width: 90%;*/
        }
    }
</style>
