<template>
	<section id="tw-view-billing-info" class="flex-grow-1 d-flex flex-column">
		<div class="bg-white tw-account-settings-section flex-grow-1">
			<div class="p-3">
				<h3 class="fw-bold mb-4">Billing Info</h3>
				<div id="tw-outstanding-invoice" class="p-2">
					<h4 class="tw-font-size-18 tw-red-color fw-bold">Outstanding invoice</h4>
					<div class="row gy-2">

						<div v-if="outstandingInvoices.length">
							
							<div v-for="(invoice, index) in outstandingInvoices" class="col-12" :key="index">
								
								<div class="row">
									<div class="col-lg-9 tw-red-color fw-bold d-flex flex-column">
										<div class="row tw-outstanding-invoice flex-grow-1">
											<div class="col-12 d-flex flex-column">
												<div class="py-2 d-flex flex-column flex-grow-1 justify-content-centers">
													<div class="d-flex justify-content-around align-items-center flex-grow-1">
														<div>{{ description }}</div>
														<div>${{ invoice.amount }}</div>
														<div>{{ invoice.due_date }}</div>
														<div>{{ invoice.payment_status }}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-3 mt-2 mt-lg-0">
										<button class="tw-red-bg-color text-white d-flex align-items-center px-3 justify-content-center w-100 tw-pay-bill-btn" @click="pay">
											Pay Now
										</button>
									</div>
								</div>

							</div>
						</div>
						<div v-else>
							<small>There is no outstanding invoices.</small>
						</div>
						
					</div>
				</div>
				<div class="mt-4">
					<form class="row align-items-center" @submit.prevent="askForInvoice">
						<div class="col-md-9">
							<label for="emails" class="form-label tw-font-size-18 fw-bold">Contact Email</label>
							<input type="text" class="form-control" v-model="emails" id="emails" placeholder="Where should invoices be sent?" required>
							<div id="emailHelp" class="form-text">add several emails seperated by a ","</div>
						</div>
						<div class="col-md-3 text-end">
							<button class="tw-red-bg-color text-white btn mt-1">Submit</button>
						</div>
					</form>
				</div>
				<!-- <div class="mt-4">
					<h4 class="tw-font-size-18 fw-bold">Card Details</h4>
					<div class="row">
						<div class="col-md-6">
							<div>visa</div>
						</div>
						<div class="col-md-6">
							<div>add new</div>
						</div>
					</div>
				</div> -->
				<div class="mt-4">
					<h4 class="tw-font-size-18 fw-bold">Check upload</h4>
					<!-- link to https://protectedstorage.townweb.com/ -->
					<a class="text-decoration-none tw-default-text-color" href="https://protectedstorage.townweb.com/" target="_blank">
						<div id="tw-check-photo-upload" class="tw-light-grey-bg-color d-flex align-items-center justify-content-center cursor-pointer">
							<i class="fas fa-camera"></i><span class="ms-2">Upload a photo of a check</span>
						</div>
					</a>
				</div>
				<div class="mt-4">
					<h4 class="tw-font-size-18 fw-bold">Billing History</h4>
					<div class="table-responsive">
						<table class="table tw-default-text-color">
							<thead>
								<tr>
									<th>Invoice</th>
									<th>Amount</th>
									<th>Date</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(invoice, index) in invoices" :key="index">
									<td>{{ description }}</td>
									<td>{{ invoice.amount }}</td>
									<td>{{ moment(invoice.paid_date).format('ll') }}</td>
									<td><span class="tw-bill-status m-auto p-1 text-capitalize d-flex align-items-center justify-content-center" :class="(invoice.payment_status == 'paid') && 'tw-paid'">{{ invoice.payment_status }}</span></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import accountSettingsMixin from '@/mixins/accountSettingsMixin';
	export default {
		name: 'BillingInfo',
		components: {

		},
		data: () => ({
			invoices: [],
			description: 'TW Basic Plan',
			price: '$100',
			date: 'December 1, 2022',
			status: 'Outstanding',
			emails: '',
			billingHistory: [],
			// lastUnpaidInvoice: null
		}),
		mounted() {
			this.setInvoices();
			this.setBillingHistory();
		},
		methods: {
			async setInvoices() {
				this.invoices = await this.getInvoices();
			},
			askForInvoice() {
				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want the latest invoice to be sent to given emails?",
                    confirmButtonText: 'Yes!',
                    showLoaderOnConfirm: true,
					inputValidator: (value) => {
						console.log(value)
						return !value && 'You need to write something!'
					},
					preConfirm: async () => {
						if (!this.emails.length) {
							this.$swal.showValidationMessage(
								'At least one email address need to be entered!'
							)
							return;
						}
						if (!this.lastUnpaidInvoiceId) {
							this.$swal.showValidationMessage(
								'You don\'t have unpaid invoices!'
							)
							return;
						}
						try {

							return await this.invoiceAskFor(this.emails, this.lastUnpaidInvoiceId);

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then( (result) => {
					if (result.isConfirmed) {


						this.$swal(
							'Success!',
							"Invoices sent!",
							'success',
						)

					}
                });
				
			},
			setBillingHistory() {
				this.billingHistory = [
										{plan: 'TW Basic Plan', amount: '$100', date: 'December 1, 2021', status: 'paid'},
										{plan: 'TW Basic Plan', amount: '$100', date: 'December 1, 2021', status: 'paid'},
										{plan: 'TW Basic Plan', amount: '$100', date: 'December 1, 2021', status: 'paid'},
									]
			},
			pay() {
				this.$swal(
					'Not available!',
					"Online payment will be available soon!",
					'info',
				)
			}
		},
		computed: {
			outstandingInvoices() {
				
				let outstandingInvoices = this.invoices.filter(function(invoice) {return invoice.payment_status !== 'paid'});
				// let outstandingInvoices = this.invoices.filter(function(invoice) {return invoice.payment_status !== 'paid'});

				return outstandingInvoices;
			},
			lastUnpaidInvoiceId() {
				if (this.outstandingInvoices.length) {
					let latest = this.outstandingInvoices.reduce(function(r,a){
						return this.moment(r.created_at) > this.moment(a.created_at) ? r : a;
					});
					return latest.invoice_id;
				}
				return null;
			}
		},
		mixins: [accountSettingsMixin]
    }
</script>
<style scoped>
	#tw-outstanding-invoice {
		border: 1px solid var(--tw-red);
		border-radius: 10px;
	}
	.tw-outstanding-invoice > div > div {
		font-size: 14px;
		border: 1px solid var(--tw-red);
		border-radius: 6px;
	}
	button {
		height: 41px;
		font-size: 14px;
		border-radius: 4px;
		border: none;
	}
	input {
		height: 37px;
		border: 1px solid rgba(78, 96, 120, 0.2);
		border-radius: 21.5px;
	}
	div.form-text {
		color: #969696;
	}
	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #CCD1D8;
		opacity: 1; /* Firefox */
	}
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #CCD1D8;
	}
	::-ms-input-placeholder { /* Microsoft Edge */
		color: #CCD1D8;
	}
	#tw-check-photo-upload {
		height: 95px;
		border-radius: 10px;
	}
	table {
		font-size: 14px;
		text-align: center;
	}
	tbody {
		border-top: 1px solid #eaeaea;
	}
	th {
		font-weight: 300;
		border: none;
	}
	td {
		font-weight: 700;
		border: none;
	}
	table tbody { display:block; max-height:300px; overflow-y:auto; }
	table thead, table tbody tr { display:table; width:100%; table-layout:fixed; }
	.tw-bill-status {
		width: 77px;
		height: 22px;
	}
	.tw-paid {
		background-color: #E3FFED;
		color: #0FCB4F;
	}
	.tw-not-paid {
		background-color: #E3FFED;
		color: #0FCB4F;
	}
	@media only screen and (max-width: 991.99px) {
		.tw-pay-bill-btn:hover {
			transform: scale(1.01);
		}
	}
</style>