<template>
	<div class="row mt-5">
		<div class="col-12">
			<div class="border-top mb-5"></div>
			<div id="tw-calendar-header" class="row mb-4">
				<div id="tw-month-selected-title" class="col-6 px-4">
					<span class="fw-bold">{{ monthSelectedTitle }}</span>
					<span class="ms-2">{{ yearSelected }}</span>
				</div>
				<div class="col-6 text-end">
					<button class="btn" @click="prev">
						<span class="tw-prev-next tw-prev d-block"></span>
					</button>
					<button class="btn" @click="next">
						<span class="tw-prev-next tw-next d-block"></span>
					</button>
				</div>
			</div>
			<div class="row">
				<div id="tw-calendar-body" class="col-12">
					<table class="w-100">
						<thead class="text-center opacity-50">
							<tr>
								<th class="fw-normal">Sun</th>
								<th class="fw-normal">Mon</th>
								<th class="fw-normal">Tue</th>
								<th class="fw-normal">Wed</th>
								<th class="fw-normal">Thu</th>
								<th class="fw-normal">Fri</th>
								<th class="fw-normal">Sat</th>
							</tr>
						</thead>
						<tbody class="position-relative">
							<div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
								<i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
							</div>
							<tr v-for="(row, index) in calendarRows" :key="index">
								<td v-for="(cell, index) in row" :key="index" class="text-center text-nowrap">
									<span class="tw-calendar-day" :class="(moment().format('MMMM') == moment(cell).format('MMMM')) && 'fw-bold' ">{{ moment(cell).format('D') }}</span>
									<div v-if="cell.events && cell.events.length">
										<div class="tw-calendar-event d-flex justify-content-center align-items-center my-1 position-relative cursor-pointer" v-for="(event, index) in cell.events" :key="index">
										<!-- <div class="tw-calendar-event d-flex justify-content-center align-items-center my-1 position-relative cursor-pointer" v-for="(event, index) in cell.events.slice(0,2)" :key="index"> -->
											<span class="tw-checkbox position-absolute">
												<input :id="'event' + event.id" type="checkbox" @click="emitCountSelected(event.oldIndex, $event)" value="1" :checked="event.checked"/><label :for="'event' + event.id"></label>
											</span>
											<span @click="emitOpenCreateEventModal(event)">{{ !hasHeyGovEvents ? sliceString(event.title, 15) : sliceString(event.name, 15) }}</span>
										</div>
										<!-- <div v-if="cell.events.length > 2">
											<a href="#" @click.stop.prevent="openMoreEventsModal( moment(cell) )">{{ cell.events.length - 2 }} more</a>
										</div> -->
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import userMixin from '@/mixins/userMixin';
	import helperMixin from '@/mixins/helperMixin';
	import heyGovMixin from '@/mixins/heyGovMixin'
	export default {
		name: 'Calendar',
		components: {

		},
		emits: ["monthChanged", "countSelected", "openCreateEventModal"],
		data: () => ({
			// permissions: null,
			// monthTitle:
		}),
        mounted() {
			this.heyGovJurisdiction();
			// console.log(this.month)
			// this.permissions = this.getCurrentUserPermissions();
				// this.$emit('monthChanged');

				// console.log(this.calendarRows)
        },
        methods: {
			prev () {
				// console.log(this.moment(this.month).subtract(1,'months').endOf('month').format('MMMM'))
				let newMonth = this.moment(this.month).subtract(1,'months').endOf('month');
				this.$emit('monthChanged', newMonth);
			},
			next () {
				// console.log(this.moment(this.month).add(1,'months').endOf('month').format('MMMM'))
				let newMonth = this.moment(this.month).add(1,'months').endOf('month');
				this.$emit('monthChanged', newMonth);
				// console.log(res)
			},
			enumerateDaysBetweenDates (startDate, endDate){
				let date = [];
				while(startDate < endDate) {

					// let events =  this.events.filter(event => this.moment(event.date).format('YYYY-MM-DD') == startDate.format('YYYY-MM-DD'));

					this.events.forEach((event, index) => {
						event.oldIndex = index;
						this[index] = event;
					}, this.events)

					let toPush = startDate.clone()
					if(!this.hasHeyGovEvents){
						toPush.events = this.events.filter(event => this.moment(event.start_date).format('YYYY-MM-DD') == startDate.format('YYYY-MM-DD'));
					} else {
						toPush.events = this.events.filter(event => this.moment.utc(event.starts_at).tz(this.timezone).format('YYYY-MM-DD') == startDate.format('YYYY-MM-DD') && event.status !== 'deleted');

					}

					date.push(toPush);
					startDate = startDate.add(1, 'days');
				}
				return date;
			},
			emitCountSelected (index, event) {
				this.$emit('countSelected', index, event);
			},
			emitOpenCreateEventModal(event) {
				this.$emit('openCreateEventModal', event);
			},
			openMoreEventsModal (date) {
				let events
				if(!this.hasHeyGovEvents) {
					events = this.events.filter(event => this.moment(event.start_date).format('YYYY-MM-DD') == date.format('YYYY-MM-DD'))
				} else {
					events = this.events.filter(event => this.moment(event.starts_at_local).format('YYYY-MM-DD') == date.format('YYYY-MM-DD'))
				}

				this.$refs.MoreEventsModal.showModal(true, events);

			}
        },
        computed: {
			monthSelectedTitle () {
				return this.moment(this.month).format('MMMM')
			},
			yearSelected () {
				return this.moment(this.month).format('Y')
			},
			calendarRows () {
				let start = this.moment(this.month).startOf('month').startOf('week');
				let end = this.moment(this.month).endOf('month').endOf('week');

				let calendarDays = this.enumerateDaysBetweenDates( start, end );

				let rows = [];

				while (calendarDays.length > 0) {
					rows.push(calendarDays.splice(0, 7));
				}

				return rows;
			},
            timezone () {
                return this.jurisdiction.timezone;
            }
        },
        props: {
            requestResolved: Boolean,
            // editingEventId: Number,
            month: Object,
            events: Array,
			jurisdiction: Object,
            // columns: Array,
            // title: String,
            // description: String,
            // contentWrapperClasses: String,
            // withFilter: {
            //     type: Boolean,
            //     default: false
            // }
        },
        mixins: [userMixin, helperMixin, heyGovMixin],
	}
</script>
<style scoped>
	th.fw-normal {
		/*width: 14.28%;*/
	}
	#tw-month-selected-title {
		font-size: 28px;
	}
	#tw-calendar-header button {
		font-size: 14px;
	}
	#tw-calendar-header button:focus {
		box-shadow: none;
	}
	#tw-calendar-header button span.tw-prev-next {
		transform: rotate(45deg);
		width: 8px;
		height: 8px;
	}
	#tw-calendar-header button span.tw-prev {
		border-bottom: 1px solid black;
		border-left: 1px solid black;
	}
	#tw-calendar-header button span.tw-next {
		border-top: 1px solid black;
		border-right: 1px solid black;
	}
	#tw-calendar-body {
		overflow-x: auto;
	}
	#tw-calendar-body table {
		table-layout: fixed;
		min-width: 1400px;
	}
	#tw-calendar-body td {
		height: 120px;
		vertical-align: top;
		padding: 3px;
		/*overflow: hidden;*/
	}
	#tw-calendar-body .tw-calendar-day {
		font-size: 18px;
	}
	#tw-calendar-body .tw-calendar-event {
		height: 35px;
		border-radius: 4px;
		background: #FFD0D0;
		font-size: 18px;
		transition: .3;
		/*padding-left: 35px;*/
	}
	#tw-calendar-body .tw-calendar-event:hover {
		transform: scale(1.02);
	}
	#tw-calendar-body span.tw-checkbox {
		left: 5px;
	}
	#tw-calendar-body span.tw-checkbox label::before {
		background: #fff;
	}
</style>
