<!-- Modal -->
<template>
    <div class="modal fade" id="ContactInfoModal" ref="ContactInfoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="d-flex">
                        <span class="tw-create-modal-icon d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                            <i class="fas fa-plus"></i>
                        </span>
                        <span class="d-inline-flex">
                            <div v-if="!editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Create Contact</h5>
                                <p class="col-12 mb-0">You are going to add new contact</p>
                            </div>
                            <div v-if="editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Edit Contact</h5>
                                <p class="col-12 mb-0">Edit the contact information</p>
                            </div>
                        </span>
                    </div>
                </div>

                <form @submit.prevent="saveContact">
                    <div class="modal-body" id="modal-body">
                        <div v-if="contact" class="row">
                            <div class="col-md-6 mb-4">
                                <label for="first-name" class="form-label fw-bold">First Name</label>
                                <input type="text" class="form-control" v-model="contact.first_name" id="first-name" placeholder="First name" required>
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="last-name" class="form-label fw-bold">Last Name</label>
                                <input type="text" class="form-control" v-model="contact.last_name" id="last-name" placeholder="Last name" required>
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="address" class="form-label fw-bold">Address</label>
                                <input type="text" class="form-control" v-model="contact.address" id="address" placeholder="Address">
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="contact-email" class="form-label fw-bold">Email</label>
                                <input type="text" class="form-control" v-model="contact.email" id="contact-email" placeholder="Email" required>
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="contact-phone" class="form-label fw-bold">Phone</label>
                                <input type="text" class="form-control" v-model="contact.phone" id="contact-phone" placeholder="Phone">
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="title" class="form-label fw-bold">Title</label>
                                <input type="text" class="form-control" v-model="contact.title" id="title" placeholder="Title">
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="email-secondary" class="form-label fw-bold">Email secondary</label>
                                <input type="text" class="form-control" v-model="contact.email_secondary" id="email-secondary" placeholder="Email secondary">
                            </div>
                            <div class="col-md-6 mb-4">
                                <label for="phone-secondary" class="form-label fw-bold">Phone secondary</label>
                                <input type="text" class="form-control" v-model="contact.phone_secondary" id="phone-secondary" placeholder="Phone secondary">
                            </div>
                            <div class="col-12">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="main" v-model="contact.main" :checked="contact.main">
                                    <label class="form-check-label" for="main">Main</label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group mb-3">
                            <input type="text" class="form-control" v-model="subject" required id="ticket-subject" placeholder="Subject">
                        </div>

                        <div class="form-group">
                            <textarea rows="10" id="ticket-message" class="form-control" v-model="message" required placeholder="Describe your problem or request"></textarea>
                        </div> -->
                    </div>

                    <div class="modal-footer">
                        <button class="btn tw-red-bg-color text-white" type="submit">
                            Confirm
                            <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                        </button>
                        <button class="btn tw-dark-grey-bg-color text-white" type="button" @click="modal.hide()">Cancel</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import { Modal } from 'bootstrap';
    export default {
        name: 'ContactInfoModal',
        data: () => ({
            modal: null,
            spinner: false,
            main: true,
            contact: {},
        }),
        emits: ["contactSaved", "modalHidden"],
        mounted() {
            this.modal = new Modal(this.$refs.ContactInfoModal);

            let ContactInfoModal = document.getElementById('ContactInfoModal')
            let self = this;

            ContactInfoModal.addEventListener('hidden.bs.modal', function () {
                self.$emit('modalHidden');
                self.contact = {};
            });

        },
        watch: {
            contactToEdit () {

                if (this.contactToEdit) {
                    this.contact = this.contactToEdit;
                }
            },
        },
        methods: {
            showModal: function(value) {
                if (value) {
                    this.modal.show();
                }
            },
            saveContact() {
                this.modal.hide();
                this.$emit('contactSaved', this.contact, this.index);
            }
        },
        props: {
            editing: Boolean,
            contactToEdit: Object,
            index: Number,
        },
    }
</script>
<style scoped>
    input, textarea {
        border-radius: 21.5px;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #CCD1D8 !important;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: #CCD1D8 !important;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: #CCD1D8 !important;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: #CCD1D8 !important;
    }
</style>