<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="sk-element-wrapper cursor-pointer" :class="element.id + '-wrapper'" @dblclick.stop="onClick()">
        <template v-if="element.props && element.props.value && element.props.value !== '' && element.props.value !== 'null'">
            <h1 :style="styleObject" v-if="element.props.tag == 'h1'" :class="element.props.class">{{ element.props.value }}</h1>
            <h2 :style="styleObject" v-if="element.props.tag == 'h2'" :class="element.props.class">{{ element.props.value }}</h2>
            <h3 :style="styleObject" v-if="element.props.tag == 'h3'" :class="element.props.class">{{ element.props.value }}</h3>
            <h4 :style="styleObject" v-if="element.props.tag == 'h4'" :class="element.props.class">{{ element.props.value }}</h4>
            <h5 :style="styleObject" v-if="element.props.tag == 'h5'" :class="element.props.class">{{ element.props.value }}</h5>
            <h6 :style="styleObject" v-if="element.props.tag == 'h6'" :class="element.props.class">{{ element.props.value }}</h6>
        </template>
        <template v-else>
            <div class="sk-not-selected-element">Add heading content</div>
        </template>
    </div>
</template>
<script>
export default {
    name: "Heading",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
    },
    computed: {
        styleObject() {
            return {
                textAlign: this.element.style.textAlign,
                backgroundColor: this.element.style.backgroundColor,
                color: this.element.style.color,
                border: this.element.style.borderNumber ? `${this.element.style.borderNumber}px solid ${this.element.style.borderColor}` : "",
                padding: this.element.style.padding,
                margin: this.element.style.margin,
                borderRadius: this.element.style.borderRadius,
            };
        },
    },
    emits: ["onClick"],
};
</script>
