<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="sk-element-wrapper" :style="styleWrapper" :class="element.id + '-wrapper'" v-if="element.props" @dblclick.stop="onClick()">
        <template v-if="element.props.image && element.props.image !== '' && element.props.image !== 'null'">
            <img :src="element.props.image" :style="styleObject" :class="element.class + ' ' + element.props.class" />
        </template>
        <template v-else>
            <div class="sk-not-selected-element">Add Photo</div>
        </template>
    </div>
</template>
<script>
export default {
    name: "Photo",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
    },
    computed: {
        styleObject() {
            return {
                width: this.element.style.width && this.element.style.width != "0%" && this.element.style.width != "0px" && this.element.style.width != "0rem" ? this.element.style.width : "auto",
                height: this.element.style.height,
                backgroundColor: this.element.style.backgroundColor,
                color: this.element.style.color,
                padding: this.element.style.padding,
                margin: this.element.style.margin,
                borderRadius: this.element.style.borderRadius,
            };
        },
        styleWrapper() {
            return {
                textAlign: this.element.style.textAlign,
            };
        },
    },
    emits: ["onClick"],
};
</script>
