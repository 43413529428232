<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="sk-element-wrapper" :style="styleWrapper" :class="element.id + '-wrapper'" v-if="element.props" @dblclick.stop="onClick()">
        <div :style="styleObject" class="sk-element-separator d-inline-block" :class="element.props.class"></div>
    </div>
</template>

<script>
export default {
    name: "Separator",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
    },
    computed: {
        styleObject() {
            return {
                borderTop: `${this.element.style.height} ${this.element.style.style} ${this.element.style.color}`,
                width: this.element.style.width,
            };
        },
        styleWrapper() {
            return {
                textAlign: this.element.style.textAlign,
                padding: this.element.style.padding,
                margin: this.element.style.margin,
            };
        },
    },
    emits: ["onClick"],
};
</script>
