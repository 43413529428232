<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="sk-element-wrapper" :class="element.id + '-wrapper ' + element.props.class" v-if="element.props" @click.stop="onClick()">
        <div class="gallery-items row" :style="styleObject">
            <template v-for="(item, index) in element.props.items" :key="index">
                <div
                    v-if="!element.props.masonry"
                    class="gallery-item mb-3"
                    :class="{
                        'col-3': element.props.itemsPerRow === '4',
                        'col-6': element.props.itemsPerRow === '2',
                        'col-4': element.props.itemsPerRow === '3',
                        'col-2': element.props.itemsPerRow === '6',
                    }"
                >
                    <a :href="item.image" data-toggle="lightbox" :data-gallery="element.id" class="">
                        <img :style="imgStyle" v-if="item.image && item.image !== '' && item.image !== 'null'" :src="item.image" class="" />
                        <div v-else class="no-image">Select Image</div>
                    </a>
                    <p v-if="element.props.showImageTitle" class="image-title">{{ item.title }}</p>
                </div>
            </template>

            <div v-if="element.props.masonry" class="masonry" :style="masonryStyle">
                <template v-for="(item, index) in element.props.items" :key="index">
                    <div class="brick">
                        <img :style="imgMasonryStyle" v-if="item.image && item.image !== '' && item.image !== 'null'" :src="item.image" />
                        <div v-else class="no-image">Select Image</div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Gallery",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
        checkInputValue(value) {
            if (value && value != "0px" && value != "0%" && value != "0rem") {
                return true;
            } else {
                return false;
            }
        },

        checkTwoValuesReturn(first, second) {
            if (this.checkInputValue(first)) {
                return first;
            } else if (this.checkInputValue(second)) {
                return second;
            } else {
                return "auto";
            }
        },
    },
    computed: {
        styleObject() {
            return {
                padding: this.element.style.padding,
            };
        },
        imgStyle() {
            return {
                borderRadius: this.element.style.borderRadius,
                width: this.checkInputValue(this.element.style.fitWidth) ? this.element.style.fitWidth : "auto",
                height: this.checkInputValue(this.element.style.fitHeight) ? this.element.style.fitHeight : "auto",
                objectFit: "cover",
            };
        },
        imgMasonryStyle() {
            return {
                borderRadius: this.element.style.borderRadius,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                columnCount: this.element.props.itemsPerRow,
            };
        },
        masonryStyle() {
            return {
                columnCount: this.element.props.itemsPerRow,
            };
        },
    },
    emits: ["onClick"],
};
</script>
<style scoped>
.masonry {
    column-count: 2;
    column-gap: 5px;
    @media (min-width: 768px) {
        column-count: 2;
    }
}

.masonry .brick {
    box-sizing: border-box;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    counter-increment: brick-counter;
    margin-bottom: 5px;
}

.masonry img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    background-color: gray;
    color: white;
    text-decoration: none;
}

.image-title {
    text-align: center;
    font-size: 13px;
    margin-top: 0.5rem;
}
</style>
