<!-- Modal -->
<template>
    <div class="modal fade" id="createPageModal" ref="createPageModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog" :class="mediaLibOpened ? 'modal-xl' : 'modal-lg'">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="d-flex">
                        <span class="tw-create-modal-icon d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                            <i class="fas fa-plus"></i>
                        </span>
                        <span class="d-inline-flex">
                            <div v-if="!editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Create Page</h5>
                                <p class="col-12 mb-0">Create a page for your site</p>
                            </div>
                            <div v-if="editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Edit "{{ sliceString(title) }}" page</h5>
                                <p class="col-12 mb-0">Edit the contents of this page</p>
                            </div>
                        </span>
                    </div>
                </div>

                <p class="text-center mt-3" v-show="editing && !page.title">Loading page details <i class="fa fa-spinner fa-spin"></i></p>

                <form v-show="!editing || (editing && page && page.title)" @submit.prevent.stop="storeNewPage">
                    <div class="modal-body" id="modal-body">
                        <div v-show="sendError" class="alert alert-danger" role="alert">Error: Page is not saved.</div>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group mb-4">
                                            <label for="page-title" class="mb-2">Title</label>
                                            <input type="text" class="form-control" :class="!(editing && page && page.slug) && 'mb-4'" v-model="title" required id="page-title" placeholder="Title of the page" />
                                            <div v-if="editing && page && page.slug" class="mt-1 mb-4">

                                                <!-- if page is department page and has department slug prefix -->
                                                <small v-if="page.slug_prefix">
                                                    <a class="text-decoration-none" :href="siteUrl + '/' + page.slug_prefix + page.slug" target="_blank">{{ siteUrl + '/' + page.slug_prefix }}<span v-if="!slugEditing">{{ page.slug }}</span> </a>

                                                </small>
                                                <!-- if page is not department page -->
                                                <small v-else>
                                                    <a class="text-decoration-none" :href="siteUrl + '/' + page.slug" target="_blank">{{ siteUrl + '/' }}<span v-if="!slugEditing">{{ page.slug }}</span> </a>
                                                </small>

                                                <small v-if="slugEditing"><input class="tw-slug-edit-input" v-model="page.slug" type="text"></small>
                                                <span v-if="siteType == 'cms6' && userHasCms6Permission('edit_page_slugs')">
                                                    <span v-if="!slugEditing" class="btn btn-sm btn-info text-white py-0 ms-2" @click="slugEditing = !slugEditing"><small>Edit</small></span>
                                                    <span v-else >
                                                        <span class="btn btn-sm btn-info text-white py-0 mx-2" @click="saveSlug"><small v-if="!slugSaving">Ok</small><small v-else><i class="fas fa-spinner fa-spin"></i></small></span>
                                                        <span><small><a href="#" @click="slugEditing = !slugEditing">Cancel</a></small></span>
                                                    </span>
                                                </span>
                                            </div>

                                            <label for="page-status" class="mb-2">Status</label>
                                            <select class="form-control mb-4 text-capitalize" required v-model="status" id="page-status">
                                                <option v-for="status in statuses" :value="status" v-html="status" :key="status"></option>
                                            </select>

                                            <div v-if="siteType == 'cms6'">
                                                <label for="page-template" class="mb-2">Template</label>
                                                <select class="form-control mb-4 text-capitalize" required v-model="template" id="page-template">
                                                    <option v-for="template in templates" :value="template" v-html="template" :key="template"></option>
                                                </select>
                                            </div>

                                            <!-- editors -->
                                            <div v-if="siteType == 'cms6' && userHasCms6Permission('add_user_to_post')">
                                                <label for="editors" class="mb-2">Editors</label>
                                                <Multiselect
                                                    v-model="editors"
                                                    mode="tags"
                                                    class="tw-editor-select"
                                                    :close-on-select="false"
                                                    :searchable="false"
                                                    :create-option="false"
                                                    :hideSelected="false"
                                                    :options="clientUsers"
                                                    placeholder="-- Choose Editors --"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 d-flex flex-column">
                                        <label class="mb-sm-3">Featured Image</label>
                                        <div class="row flex-grow-1 align-items-center mb-sm-3">
                                            <div class="col-md-6 mb-3 d-flex justify-content-center">
                                                <div class="position-relative">
                                                    <label for="featured_media" class="d-flex tw-modal-featured-img cursor-pointer align-items-center justify-content-center">
                                                        <img
                                                            v-if="featuredMedia"
                                                            :src="
                                                                featuredMedia && featuredMedia.media_details.sizes && Object.keys(featuredMedia.media_details.sizes).length
                                                                    ? featuredMedia.media_details.sizes.thumbnail.source_url
                                                                    : featuredMedia.source_url || defaultImage
                                                            "
                                                            :alt="page.post_title || page.title"
                                                            height="175"
                                                            width="188"
                                                        />
                                                        <span v-else class="d-flex align-items-center justify-content-center">
                                                            <span v-html="defaultImage"></span>
                                                        </span>
                                                    </label>

                                                    <div
                                                        v-if="imageUploads"
                                                        id="tw-modal-featured-img-loading-overlay"
                                                        class="position-absolute opacity-25 bg-dark top-0 start-0 end-0 bottom-0 opacity-75 tw-light-grey-bg-color d-flex align-items-center justify-content-center"
                                                    >
                                                        <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                                                    </div>
                                                    <div
                                                        v-if="featuredMedia"
                                                        id="tw-modal-featured-img-overlay"
                                                        class="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center cursor-pointer"
                                                        @mouseover="hoverOverlay = true"
                                                        @mouseleave="hoverOverlay = false"
                                                        :class="{ 'tw-modal-featured-img-overlay-hovered': hoverOverlay }"
                                                    >
                                                        <div class="d-flex">
                                                            <label
                                                                for="featured_media"
                                                                class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 me-1 cursor-pointer"
                                                            >
                                                                <i class="fas fa-image fa-2x text-white"></i>
                                                            </label>
                                                            <span
                                                                class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 ms-1 cursor-pointer"
                                                                @click="
                                                                    page.featured_media = null;
                                                                    featuredMedia = null;
                                                                "
                                                            >
                                                                <i class="fas fa-trash fa-2x text-white"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    ref="featured_media"
                                                    type="file"
                                                    class="d-none"
                                                    id="featured_media"
                                                    @change="handleFileUpload($event)"
                                                    @click="resetImageUploader"
                                                    accept="image/png, image/jpg, image/jpeg"
                                                />
                                            </div>
                                            <div class="col-md-6 mb-3 d-none d-md-flex justify-content-center">
                                                <div
                                                    class="tw-modal-media-lib tw-light-grey-bg-color d-flex align-items-center justify-content-center cursor-pointer"
                                                    @click="mediaLib($event, chooseFeaturedImg)"
                                                >
                                                    <div class="tw-modal-media-lib-btn tw-grey-bg-color d-flex align-items-center text-white">
                                                        <i class="fas fa-plus"></i> <span class="ms-2">Media Library</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!page.pb_json && false" class="form-group">
                                    <div class="row mb-2">
                                        <label class="col-md-6 d-flex align-items-center" for="post-content">Description</label>
                                    </div>
                                    <editor
                                        api-key=""
                                        v-model="pageContent"
                                        theme="advanced"
                                        theme_advanced_toolbar_location="bottom"
                                        :init="tinymcInit"
                                        @execCommand="tinyMCEMedLib"
                                    />
                                </div>
                                <div v-if="false">
                                <!-- <div v-else> -->
                                    <div class="row mb-2">
                                        <label class="col-md-6 d-flex align-items-center" for="post-content">Description</label>
                                    </div>
                                    <div class="d-flex flex-column align-items-center justify-content-center" style="height: 500px; border: 2px dashed #eaeaea">
                                        <h3>Page Builder is active on this page.</h3>
                                        <button class="btn tw-grey-bg-color text-white" @click="page.pb_json = null">Switch to editor</button>
                                    </div>
                                </div>
                                <div class="form-group my-4">
                                    <div v-if="siteType == 'cms6' && departments.length">
                                        <label for="page-template" class="mb-2">For department</label>
                                        <select class="form-control mb-4 text-capitalize" v-model="departmentId" id="page-template">
                                            <option :value="null">-- Select department --</option>
                                            <option v-for="department in departments" :value="department.id" v-html="department.title" :key="department.id"></option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <MediaLibraryInModal
                                ref="mediaLibrary"
                                :mediaLibOpened="mediaLibOpened"
                                @mediaLibClose="mediaLibOpened = false"
                                @setMedia="handleSetFeaturedImage"
                                @mediaInsert="handleMediaInsert"
                            />
                            <!-- <div class="anime d-flex" :class="mediaLibOpened ? 'col-4 collapse show' : 'collapsed'">
                                <div class="tw-create-modal-media-lib-wrapper d-flex w-100" :class="!mediaLibOpened && 'd-none'">
                                    <div class="row flex-grow-1 w-100">
                                        <div class="col mx-4 tw-light-grey-bg-color p-3">
                                            <label class="mb-3">Media Library</label>
                                            <p>A complete list of the files located on your site</p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="modal-footer" :class="mediaLibOpened ? 'col-8' : 'col-12'">
                        <div class="row w-100 mt-3">
                            <div class="col-md-4">
                                <button
                                    v-if="supportsPageBuilder"
                                    class="btn tw-dark-grey-bg-color text-white w-100 tw-green-bg-color"
                                    :class="title === '' ? 'disabled' : ''"
                                    @click.stop.prevent="usePageBuilder"
                                >
                                    Use Page Builder
                                </button>
                            </div>
                            <div class="col-md-8 d-flex align-items-center justify-content-end">
                                <button class="btn tw-red-bg-color text-white me-2">
                                    Save
                                    <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                                </button>
                                <button class="btn tw-dark-grey-bg-color text-white" type="button" @click="modal.hide()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- <MediaModal ref="MediaModal" @mediaInsert="handleMediaInsert" @setMedia="handleSetFeaturedImage" /> -->
</template>
<script>
import { Modal } from "bootstrap";
import MediaLibraryInModal from "@/components/media-library/MediaLibraryInModal";
// import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import editor from "@tinymce/tinymce-vue";
import tinymceConfig from "@/tinymce/config";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import wordpressMixin from "@/mixins/wordpressMixin";
import helperMixin from "@/mixins/helperMixin";
import siteMixin from "@/mixins/siteMixin";
import cms6Mixin from "@/mixins/cms6Mixin";
import Multiselect from "@vueform/multiselect";

let site = localStorage.getObject("site");

let mixins;

if (site.type == "wordpress") {
    mixins = [wordpressMixin, siteMixin, helperMixin];
} else if (site.type == "cms6") {
    mixins = [cms6Mixin, siteMixin, helperMixin];
}
export default {
    name: "CreatePageModal",
    components: {
        MediaLibraryInModal,
        editor,
        Multiselect,
    },
    emits: ["modalHidden", "pageSaved", "slugUpdated"],
    data: () => ({
        modal: null,
        toast: null,
        title: "",
        pageContent: "",
        defaultImage: '<div class="tw-defaul-featured-img tw-light-grey-bg-color d-flex align-items-center"><i class="fas fa-image me-2"></i> <span>Upload Image</span></div>',
        featuredMedia: null,
        file: null,
        spinner: false,
        page: {},
        status: "publish",
        statuses: ["publish", "draft"],
        chooseFeaturedImg: true,
        format: "LLL dd, Y, hh:mm a",
        mediaLibOpened: false,
        imageUploads: false,
        hoverOverlay: false,
        sendError: false,
        pageBuilderUse: false,
        pb_json: null,
        templates: null,
        template: "default",
        departments: [],
        departmentId: null,
        slugEditing: false,
        editingPageId: null,
        slugSaving: false,
        editors: []
    }),
    mounted() {
        this.modal = new Modal(this.$refs.createPageModal);
        // this.showToast()

        let createPageModal = document.getElementById("createPageModal");

        // Prevent Bootstrap dialog from blocking focusin
        document.addEventListener("focusin", (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }
        });

        let self = this;
        //hide.bs.modal
        createPageModal.addEventListener("hide.bs.modal", function () {
            let el = document.querySelector('button[aria-label="More..."][aria-expanded="true"]');

            if (el) {
                el.click();
            }
        });
        createPageModal.addEventListener("hidden.bs.modal", function () {
            if (getTinymce().activeEditor) {
                getTinymce().activeEditor.ui.hide();
            }

            self.$emit("modalHidden");
            self.page = {};
            self.title = "";
            self.pageContent = "";
            self.featuredMedia = null;
            self.file = null;
            self.sendError = false;
            self.mediaLibOpened = false;
            self.pb_json = null;
            self.template = "default";
            self.departmentId = null;
            self.departments = [];
            self.slugEditing = false;

            if (self.pageBuilderUse) {
                self.pageBuilderUse = false;

                if (self.editingPageId) {
                    self.$router.push("/page-builder/" + self.editingPageId);
                } else {
                    self.$router.push("/page-builder");
                }
            }
            // self.$refs.editor.setHTML('');
        });
    },
    // watch: {
    //     editors (val) {
    //         console.log(val)
    //     },
    // },
    methods: {
        showModal: async function (value, pageId) {
            if (value) {
                this.modal.show();
                if (this.siteType == "cms6") {
                    await this.setTemplates();
                    await this.setDepartments();
                }

                if (pageId) {
                    this.editingPageId = pageId;
                    this.setPage();
                } else {
                    this.editingPageId = null;
                }
            }
        },
        async setPage() {
            let res = await this.getPage(this.editingPageId);
            this.page = await res.data;

            this.title = this.page.title.rendered || this.page.title;
            this.pageContent = (this.page.content.rendered || this.page.content.rendered === '') ? this.page.content.rendered : this.page.content;
            this.pb_css = this.page.pb_css;
            this.pb_js = this.page.pb_js;
            this.pb_json = this.page.pb_json;

            this.departmentId = null;

            this.departments.forEach((department) => {
                if (department.id == this.page.department_id) {
                    this.departmentId = this.page.department_id;
                }
            });

            this.editors = this.page.editors;

            // this.departmentId = this.departments.includes(this.page.department_id) ? this.page.department_id : null;
            // console.log(this.pageContent)
            // this.$refs.editor.setHTML(this.pageContent);
            // this.$refs.editor.pasteHTML(this.pageContent);
            this.status = this.page.status;
            this.template = this.page.template;
            let self = this;
            if (this.page._embedded && this.page._embedded["wp:featuredmedia"]) {
                this.featuredMedia = this.page._embedded["wp:featuredmedia"].find(function (media) {
                    return media.id == self.page.featured_media;
                });
            } else if (
                this.page.featured_media &&
                this.page.featured_media.media_details &&
                this.page.featured_media.media_details.sizes &&
                Object.keys(this.page.featured_media.media_details.sizes).length
            ) {
                this.featuredMedia = this.page.featured_media;
            }
        },
        async setTemplates() {
            let res = await this.getTemplates();
            this.templates = await res.data;
        },
        async setDepartments() {
            let res = await this.getDepartmentsSimple();
            // console.log('res')
            this.departments = await res.data;
        },
        async handleFileUpload(event) {
            if (event.target.files[0]) {
                this.imageUploads = true;
                this.file = event.target.files[0];

                let res = await this.uploadFile(this.file, true);

                if (res.data && res.data.type && res.data.type == "error") {
                    let text = res.data.errors.file.join("<br><br>");

                    this.$swal({
                        toast: true,
                        showConfirmButton: true,
                        timer: 10000,
                        title: "Invalid file!",
                        html: text,
                        position: "top-end",
                        icon: "error",
                        closeModal: true,
                        timerProgressBar: true,
                    });
                } else {
                    this.featuredMedia = await res.data;
                }

                this.imageUploads = false;

                if (this.mediaLibOpened) {
                    this.$refs.mediaLibrary.setMediaLibrary();
                }
            }
        },
        async storeNewPage() {
            this.spinner = true;

            let mediaId = null;
            let res;
            if (this.featuredMedia) {
                mediaId = this.featuredMedia.id;
            }

            this.page.title = this.title;
            this.page.content = this.pageContent;
            this.page.status = this.status;
            this.page.featured_media = mediaId;
            this.page.comment_status = "closed";
            this.page.ping_status = "closed";
            if (this.siteType == 'cms6') {
                this.page.template = this.template;
            }

            if (this.siteType == "cms6") {
                this.page.department_id = this.departmentId;
            }

            if (this.editors) {
                this.page.editors = this.editors;
            }

            if (this.editingPageId) {
                res = await this.updatePage(this.page);
            } else {
                res = await this.savePage(this.page);
            }

            this.spinner = false;

            if (res.status === 201 || res.status === 200) {
                let action = "saved";
                // if (!this.from && !this.editingPageId && !this.post.status_draft) {
                //     action = 'published';
                // }
                this.modal.hide();
                this.$emit("pageSaved", action);
            } else {
                this.sendError = true;
            }
        },
        mediaLib(event, chooseFeaturedImg = false) {
            if (this.mediaLibOpened) {
                this.mediaLibOpened = false;
            } else {
                let allowMulti = !chooseFeaturedImg;
                this.$refs.mediaLibrary.mediaLibOpen(true, chooseFeaturedImg, allowMulti, chooseFeaturedImg);
                this.mediaLibOpened = true;
            }
        },
        tinyMCEMedLib(event) {
            // console.log(event)
            if (event.command === "openMediaLib") {
                this.mediaLib(false);
            }
        },
        handleMediaInsert(content) {
            getTinymce().activeEditor.insertContent(content);
        },
        handleSetFeaturedImage(image) {
            this.featuredMedia = image;
        },
        resetImageUploader() {
            this.$refs.featured_media.value = "";
        },
        usePageBuilder() {
            this.pageBuilderUse = true;

            let page = {};

            let mediaId = null;
            if (this.featuredMedia) {
                mediaId = this.featuredMedia.id;
            }

            page.id = this.editingPageId;
            page.title = this.title;
            page.content = this.pageContent;
            page.status = this.status;
            page.featured_media = mediaId;
            page.comment_status = "closed";
            page.ping_status = "closed";
            page.pb_json = this.pb_json;
            page.template = this.template;

            this.$store.commit("setPage", { page });

            this.modal.hide();
        },
        async saveSlug() {
            this.slugSaving = true;
            let res = await this.slugSave(this.page.id, this.page.slug);

            if (res.status === 200) {
                this.page.slug = res.data;
                this.$emit("slugUpdated");
            }
            this.slugEditing = false;
            this.slugSaving = false;
        }
    },
    computed: {
        tinymcInit() {
            return tinymceConfig.config;
        },
        siteType() {
            return this.getSiteType();
        },
        supportsPageBuilder() {
            return this.getSiteType() == "cms6" || this.supportsTWPageBuilder();
        },
        siteUrl() {
            return this.getSiteUrl();
        },
        clientUsers() {
            return this.getClientUsers().map(user => {
                return { value: user.id, label: user.name };
            });
        }
    },
    props: {
        editing: Boolean,
        supportsPagePublish: Boolean,
        // editingPageId: Number,
        // posts: Array,
        // columns: Array,
        // title: String,
        // description: String,
        // contentWrapperClasses: String,
        // withFilter: {
        //     type: Boolean,
        //     default: false
        // }
    },
    mixins: mixins,
};
</script>
<style scoped>
#tw-modal-featured-img-loading-overlay {
    border-radius: 10px;
}
#tw-modal-featured-img-overlay {
    transition: 0.3s;
    background: rgba(255, 255, 255, 0);
}
#tw-modal-featured-img-overlay * {
    opacity: 0;
    transition: 0.3s;
}
#tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered {
    background: rgba(255, 255, 255, 0.25);
}
#tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered * {
    opacity: 1;
}
#tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > span:hover,
#tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > label:hover {
    transform: scale(1.1);
}
#tw-modal-featured-img-overlay > div > span {
    width: 50px;
    height: 50px;
}
.tw-create-post-modal-date-from,
.tw-create-post-modal-date-to {
    top: -10px;
    left: 10px;
}
.collapsed {
    width: 0;
}
.anime {
    /*flex-shrink: 1;*/
    transition: all 300ms ease;
}
.tw-create-modal-media-lib-wrapper {
    border-left: 1px solid #e0e0e0;
}
.modal-xl {
    max-width: 90%;
}
.tw-checkbox label {
    margin-left: 35px;
}
.tw-checkbox label::before {
    top: calc(50% - 12px);
    left: -35px;
}
.tw-checkbox label::after {
    top: calc(50% - 6px);
    left: -29px;
}
@media only screen and (min-width: 992px) {
    .modal-lg {
        max-width: 900px;
    }
}
</style>
