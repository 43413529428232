<template>
    <!-- Displaying elements list -->
    <div v-if="visible === 'Elements'">
        <span class="" v-if="disabled">ℹ️ <small>You need first to add layout</small></span>
        <div v-else class="element-groups" v-for="group in elementGroups" :key="group.type">
            <div class="group-header d-flex">
                <h6 class="me-2">{{ group.name }}</h6>
                <span class="group-help">{{ group.description }}</span>
            </div>

            <draggable ghost-class="ghost-element" class="dragArea elements-row row" :list="group.elements" :group="{ name: 'element', pull: 'clone', put: false }" :sort="false">
                <template v-for="(element, index) in group.elements" :key="index">
                    <div class="col-md-6">
                        <div class="element-item">
                            <div class="d-flex justify-content-center align-items-center">
                                <i v-if="element.icon !== ''" :class="element.icon"></i>
                                <span class="title ms-2">{{ element.label }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
    </div>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";
export default {
    name: "ElementsList",
    props: {
        elementGroups: {
            required: true,
            type: Array,
        },
        visible: {
            required: true,
            type: String,
        },
        disabled: {
            type: Boolean,
        },
    },
    components: {
        draggable: VueDraggableNext,
    },
};
</script>
<style scoped>
.dragArea {
    min-height: 50px;
}

.element-groups {
    background-color: #f5f7f9;
}

.group-header {
    background-color: #e7ebef;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.group-header h6 {
    padding: 0;
    margin: 0;
}

.group-header .group-help {
    font-size: 12px;
}

.elements-row {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}

.element-item {
    padding: 10px;
    margin-bottom: 5px;
    text-align: center;
    cursor: move;
    border-radius: 5px;
    font-size: 1.2rem;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    transition-property: box-shadow;
    transition-duration: 0.15s;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
}
.element-item:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(34, 41, 47, 0.075);
    transition: box-shadow 0.2s ease-in-out;
    border-radius: 0;
    background-color: white;
}

.element-item:hover > .image .part {
    background-color: black;
}

.element-item .image {
    display: flex;
    justify-content: space-between;
    height: 30px;
    width: 100%;
}

.element-item .image .part {
    display: flex;
    height: 30px;
    width: 100%;
    margin: 0 2px;
    background-color: #4e6078;
    border-radius: 2px;
}

.element-item .title {
    font-size: 12px;
    margin-top: 5px;
}
</style>
