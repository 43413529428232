<template>
	<section id="tw-view-contact-info" class="flex-grow-1 d-flex flex-column">
		<div class="bg-white tw-account-settings-section flex-grow-1">
			<div class="p-3">
				<h3 class="fw-bold mb-4">Client Info</h3>
				<form class="row">
					<div class="col-md-6 mb-4">
						<label for="name" class="form-label fw-bold">Name</label>
						<input type="text" class="form-control" v-model="mainContact" id="name" placeholder="Main Contact's name" readonly>
					</div>
					<!-- {{ clerk.contacts[0].first_name + " " + clerk.contacts[0].last_name }} -->
					<!-- <div v-if="mainContact" class="col-12">
						<span>{{ mainContact }}</span>
					</div> -->
					<div class="col-md-6 mb-4">
						<label for="email" class="form-label fw-bold">Email</label>
						<input type="text" class="form-control" v-model="clientInfo.email" id="email" placeholder="Clerk's email">
					</div>
					<div class="col-md-6 mb-4">
						<label for="mailing-address" class="form-label fw-bold">Mailing Address</label>
						<input type="text" class="form-control" v-model="clientInfo.address_mailing" id="mailing-address" placeholder="Clerk's mailing address">
					</div>
					<div class="col-md-6 mb-4">
						<label for="phone" class="form-label fw-bold">Phone Number</label>
						<input type="text" class="form-control" v-model="clientInfo.phone" id="phone" placeholder="Clerk's phone number">
					</div>
					<hr>
					<div>
						<table class="table">
							<thead>
								<tr>
									<th>#</th>
									<th>First Name</th>
									<th>Last Name</th>
									<th>Title</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(contact, index) in clientInfo.contacts" :key="index">
									<tr v-if="!contact.deleted_at">
										<td>{{ index + 1 }}</td>
										<td>
											{{ contact.first_name }}
										</td>
										<td>
											{{ contact.last_name }}
										</td>
										<td>
											{{ contact.title }} <small v-if="contact.main" class="bg-info text-white px-1 rounded">main</small>
										</td>
										<td>
											<span class="me-2" @click="showModal($event, contact, index)">
												<i class="fas fa-pen tw-grey-color cursor-pointer"></i>
											</span>
											<!-- <button class="btn btn-sm tw-red-bg-color text-white me-1" @click.prevent="showModal(contact)">
												<i class="fas fa-edit"></i>
											</button> -->
											<span @click="removeContact(index)">
												<i class="fas fa-minus-circle tw-red-color cursor-pointer"></i>
											</span>
											<!-- <button class="btn btn-sm tw-red-bg-color text-white" @click.prevent="removeContact(index)">
												<i class="fas fa-trash-alt"></i>
											</button> -->
										</td>
									</tr>
								</template>
								<tr>
									<td colspan="5" class="text-end">
										<span class="cursor-pointer" @click="showModal"><i class="fas fa-plus-circle"></i> Add Contact</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-12">
						<button type="button" class="tw-red-bg-color text-white d-flex align-items-center px-3 justify-content-center w-100" @click="save">Update Data</button>
					</div>
					<hr>
					<div class="col-12">
						<label class="form-label fw-bold">Latest Update</label>
						<p>{{ moment(clientInfo.updated_at).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ') }}</p>
					</div>
				</form>
			</div>
		</div>
	</section>
	<ContactInfoModal ref="ContactInfoModal" :contactToEdit="contactToEdit" :editing="editing" @modalHidden="handleModalHidden" :index="index" @contactSaved="handleContactSaved" />
</template>

<script>
	import ContactInfoModal from '@/components/modals/ContactInfoModal'
	import accountSettingsMixin from '@/mixins/accountSettingsMixin';
	import helperMixin from '@/mixins/helperMixin';
	export default {
		name: 'ClientInfo',
		components: {
			ContactInfoModal
		},
		data: () => ({
			clientInfo: {},
			// mainContact: null,
			name: '',
			email: '',
			mailingAddress: '',
			phone: '',
			contactToEdit: null,
			index: null,
			editing: false,
			latestUpdate: 'Wed Nov 27 2019 07:34:40 GMT+0200',
		}),
		mounted() {
			// this.clientInfo = await this.getClientInfo();
			this.setClientInfo();
			// this.setMainContact();

		},
		// watch: {
		// 	contactInfo() {
		// 		console.log('ovde kao menjam')
		// 		this.setMainContact();
		// 	}
		// },
		methods: {
			async setClientInfo() {
				this.clientInfo = await this.getClientInfo();
			},
			showModal(event, contact = null, index = null) {
				if (contact) {
					this.contactToEdit = {...contact};
					this.index = index;
					this.editing = true;
				}

				this.$refs.ContactInfoModal.showModal(true);
			},
			handleModalHidden() {
				this.editing = false;
				this.contactToEdit = null;
			},
			handleContactSaved(contact, index) {
				if (index === null) {
					index = this.clientInfo.contacts.push(contact);
					index = index - 1;
				} else {
					this.clientInfo.contacts[index] = contact;
				}

				// allow only one contact to be main
				if (contact.main) {
					this.clientInfo.contacts.map(function(cont, key) {
						if (key !== index && cont.main) {
							cont.main = 0;
						}
					})
				}
			},
			save() {
				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to save contact info?",
                    confirmButtonText: 'Yes!',
                    showLoaderOnConfirm: true,
					preConfirm: async () => {
						// let title = 'Ticker bar notice ' + this.moment().format("MM-DD-YYYY");
						// let status = 'publish';
						// let categories = [];
						// categories.push(this.tickerCatId);
						// this.post.title = title; 
						// this.post.status = status;
						// this.post.categories = categories;
						// this.post.content = this.content;
						try {

							return await this.updateClientInfo(this.clientInfo);

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then( (result) => {
					if (result.isConfirmed) {


						this.$swal(
							'Success!',
							"Changes saved successfully!",
							'success',
						)
						.then(() => {

							this.setClientInfo();

							// this.currentTicker = null;
							// this.requestResolved = false;

							// this.setCurrentTickerPost();
						});

					}
                });
			},
			removeContact(index) {
				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to remove member from contact list?",
                    confirmButtonText: 'Yes!',
                    showLoaderOnConfirm: true,
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then( (result) => {
					if (result.isConfirmed) {
						this.clientInfo.contacts[index].deleted_at = this.moment().format();

						// console.log(this.clientInfo)
						// this.clientInfo.contacts.splice(index, 1);
					}
                });
			},
		},
		computed: {
			mainContact() {
				let mainContact = null;

				if (this.clientInfo && this.clientInfo.contacts && this.clientInfo.contacts.length) {


					this.clientInfo.contacts.map(function(contact){
						mainContact = contact.main ? contact.first_name + ' ' + contact.last_name : null;
					})
					if (!mainContact) {
						mainContact = this.clientInfo.contacts[0].first_name + ' ' + this.clientInfo.contacts[0].last_name;
					}
				}
				return mainContact;
			}
		},
		mixins: [accountSettingsMixin, helperMixin]
    }
</script>
<style scoped>
	label {
		font-size: 18px;
	}
	input {
		border-radius: 21.5px;
		height: 37px;
	}
	button {
		height: 41px;
		font-size: 20px;
		border-radius: 4px;
		border: none;
	}
	button:hover {
		transform: scale(1.02);
	}
	small {
		font-size: 12px;
		padding: 1px;
	}
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: #CCD1D8 !important;
	}
	::-moz-placeholder { /* Firefox 19+ */
		color: #CCD1D8 !important;
	}
	:-ms-input-placeholder { /* IE 10+ */
		color: #CCD1D8 !important;
	}
	:-moz-placeholder { /* Firefox 18- */
		color: #CCD1D8 !important;
	}
</style>