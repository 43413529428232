<template>
	<section id="tw-view-account-settings" class="tw-content pt-5">
		<div class="container flex-grow-1 d-flex">
			<div class="flex-grow-1 d-flex flex-column">
				<div v-if="!hasHeyGov && requestResolved" class="col-12 mb-4">
					<div class="bg-white tw-account-settings-section p-3">
						<div class="row">
							<div class="col-md-6 order-2 order-md-1">
								<h3 class="fw-bold">Upgrade your Town Website</h3>
								<p>Add the HeyGov integration to your website and enjoy an amazing experience of communicating with your citizens</p>
								<div class="mt-5">
									<a href="https://heygov.com/contact-us" class="text-decoration-none" target="_blank">
										<button class="tw-red-bg-color text-white d-flex align-items-center px-3">
											Add HeyGov
										</button>
									</a>
								</div>
							</div>
							<div class="col-md-6 d-flex justify-content-end align-items-center order-1 order-md-2 mb-4 mb-md-0">
								<a href="https://heygov.com/" target="_blank">
									<img src="@/assets/images/heygov-logo-square.png" alt="HeyGov" width="194">
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 flex-grow-1 d-flex flex-column">
					<div class="row flex-grow-1">
						<div class="col-lg-4 col-xl-3">
							<Nav />	
						</div>
						<div class="col-lg-8 col-xl-9 d-flex flex-column flex-grow-1">
							<router-view name="b" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import Nav from '@/views/account-settings/nav/Nav';
	import accountSettingsMixin from '@/mixins/accountSettingsMixin';
	export default {
		name: 'AccountSettings',
		components: {
			Nav,
		},
		data: () => ({
			hasHeyGov: false,
			requestResolved: false,
		}),
		mounted() {
			this.setHasHeyGov();
			// console.log(this.$route)
			// console.log(this.$route.name)
			// console.log(this.$route.match.some(({ name }) => name === 'departments'))
		},
		methods: {
			async setHasHeyGov() {
				let res = await this.getIsHeyGov();
				this.hasHeyGov = res;
				this.requestResolved = true;
			}
		},
		mixins: [accountSettingsMixin]
    }
</script>
<style scoped>
	h3 {
		font-size: 22px;
	}
	p {
		font-size: 18px;
		font-weight: 300;
	}
	button {
		height: 41px;
		font-size: 20px;
		border-radius: 4px;
		border: none;
	}
</style>