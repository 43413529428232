import siteMixin from '@/mixins/siteMixin';
import userMixin from '@/mixins/userMixin';
export default {
	data: () => ({
        user: null,
        site: null,
        namespace: null,
        headers: null,
        controller: null,
    }),
	methods: {
		prepareForFetch(namespace) {
			this.user = this.getCurrentUser();
			if (!this.user || !this.user.auth) {
				return;
			}
			if (this.controller) {this.controller.abort()}
			this.controller = new AbortController();
			this.namespace = namespace || '/twd-dashboard/v1';
			this.site = this.getSite();

			if (process.env.NODE_ENV == "production") {
				if (this.site && this.site.url) { this.site.url = this.site.url.replace('http://','https://') }
			} 

			this.headers = { Authorization: 'Basic ' + this.user.auth };
		},
		async dashboardInfo(from, to, namespace) {
			this.prepareForFetch(namespace);
			if (!this.site || !this.site.url) {
				return;
			}
			return await this.axios.get(this.site.url + '/wp-json' + this.namespace + '/dashboard', {
									headers: this.headers,
                                    params: {from: from, to: to}})
		},
		/*==========================================
		=            MEETING REPOSITORY            =
		==========================================*/
		async getMeetingRepositories(useParams = false, page = 1, perPage = 10, order = 'desc', orderBy = 'date', querySearch = '', namespace) {
			this.prepareForFetch(namespace);
			if (!this.site || !this.site.url) {
				return;
			}
			let params = {};
			if (useParams) {


				params = {
							_embed: 1,
							page: page,
							per_page: perPage,
							order: order, // 'asc'
							// orderby: orderBy,
							search: querySearch,
							status: 'any',
						};


				if (orderBy == 'date') {

					params = {...params, ...{

											// search: querySearch
											'filter[meta_key]': 'meeting_date',
											'filter[orderby]': 'meta_value'
										}
									}


				} else {
					params = {...params, ...{orderby: orderBy}}
				}
			}

			try{
				return await this.axios.get(this.site.url + '/wp-json/wp/v2/twd_repository', { //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
										signal: this.controller.signal,
										headers: this.headers,
										params: params
									})
			} catch(error) {
				if (this.axios.isCancel(error)) {
					console.log(error)
				} else {
					console.error(error.message)
				}
			}
		},
		async getMeetingRepositoryCategories(allowed_terms = null, namespace) {
			this.prepareForFetch(namespace);
			if (!this.site || !this.site.url) {
				return;
			}
			let params = {
				per_page: 100,
				'hide_empty': 0,
				// include: [13]
			};

			if (allowed_terms) {
				params = {...params, ...{include: allowed_terms}}
			}

			return await this.axios.get(this.site.url + `/wp-json/wp/v2/twd_repository_cat`, { //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
									headers: this.headers,
									params: params
								});
		},
		async getMeetingRepository(id, namespace) {
			this.prepareForFetch(namespace);
			if (!this.site || !this.site.url) {
				return;
			}
			let params = {
				'hide_empty': 0
			};
			return await this.axios.get(this.site.url + `/wp-json/wp/v2/twd_repository/${id}/?_embed=1&context=edit`, { //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
									headers: this.headers,
									params: params
								})
		},
		async saveRepository(repository, namespace) {
			this.prepareForFetch(namespace);
			if (!this.site || !this.site.url) {
				return;
			}
			return await this.axios.post(this.site.url + `/wp-json/wp/v2/twd_repository`, repository, { //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
									headers: this.headers})
		},
		async updateRepository(repository, namespace) {
			this.prepareForFetch(namespace);
			if (!this.site || !this.site.url) {
				return;
			}
			return await this.axios.post(this.site.url + `/wp-json/wp/v2/twd_repository/${repository.id}`, repository, { //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
									headers: this.headers})
		},
		async deleteMeetingRepository(id, namespace) {
			this.prepareForFetch(namespace);
			if (!this.site || !this.site.url) {
				return;
			}
			return await this.axios.delete(this.site.url + `/wp-json/wp/v2/twd_repository/${id}`, { // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
									headers: this.headers})
		},
		async meetingRepositoryNotify(id) {
			return await this.axios.post(this.site.url + `/wp-json/wp/v2/meet-repo-notify`, { repository_id: id }, { //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
									headers: this.headers})
		},
		/*=====  End of MEETING REPOSITORY  ======*/
        /*===================================
        =            FILE UPLOAD            =
        ===================================*/
        async uploadFile(file, type = null, image = false, namespace) {
            var formData = new FormData();
            image, type;
            formData.append('file', file);
            this.prepareForFetch(namespace);
            return await this.axios.post(this.site.url + '/wp-json/wp/v2/media', formData, {
                                    headers: {...this.headers,...{ 'Content-Type': 'multipart/form-data'}}})
        },
        /*=====  End of FILE UPLOAD  ======*/
        
	},
	mixins: [userMixin, siteMixin]
}