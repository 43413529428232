<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="sk-element-wrapper" :class="element.id + '-wrapper'" v-if="element.props" @dblclick.stop="onClick">
        <div class="accordion" :id="`${element.id}`">
            <div class="accordion-item" :style="styleObject" v-for="item in element.props.items" :key="item.id">
                <h2 class="accordion-header" :style="headerAccordionStyle" :id="'heading-' + item.id">
                    <button
                        :id="`button-${item.id}`"
                        class="accordion-button"
                        :class="{ collapsed: !item.collapsed }"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#collapse-' + item.id"
                        aria-expanded="true"
                        :aria-controls="'collapse-' + item.id"
                        @click="item.collapse = !item.collapse"
                    >
                        {{ item.title }}
                        <span class="accordion-icon">
                            <font-awesome-icon :icon="['fas', item.collapse ? getFontIcon(element.props.iconOpen) || 'minus' : getFontIcon(element.props.iconClose) || 'plus']" />
                        </span>
                    </button>
                </h2>
                <div :id="'collapse-' + item.id" class="accordion-collapse collapse" :class="{ show: item.collapse }" :aria-labelledby="'heading-' + item.id" :data-bs-parent="`${element.id}`">
                    <div class="accordion-body">
                        <div v-html="item.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Accordion",
    props: {
        element: {
            required: false,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
        getFontIcon(icon) {
            let fontIcon = "";
            if (icon === "2b") {
                fontIcon = "plus";
            } else if (icon === "f068") {
                fontIcon = "minus";
            } else if (icon === "f062") {
                fontIcon = "arrow-up";
            } else if (icon === "f063") {
                fontIcon = "arrow-down";
            } else if (icon === "f077") {
                fontIcon = "chevron-up";
            } else if (icon === "f078") {
                fontIcon = "chevron-down";
            } else if (icon === "f07c") {
                fontIcon = "folder-open";
            } else if (icon === "f07b") {
                fontIcon = "folder";
            }
            return fontIcon;
        },
    },
    computed: {
        styleObject() {
            return {
                marginBottom: this.element.style.itemSpacing ? this.element.style.itemSpacing : "0px",
            };
        },
        headerAccordionStyle() {
            return {
                color: `${this.element.style.color}!important`,
                backgroundColor: `${this.element.style.backgroundColor} !important`,
                padding: this.element.style.padding,
            };
        },
    },
    emits: ["onClick"],
};
</script>
<style scoped>
.accordion-icon {
    position: absolute;
    right: 20px;
}
.accordion-button::after {
    background-image: none;
}
.accordion-button:not(.collapsed) {
    background-color: unset;
    color: unset;
    box-shadow: none;
}
.accordion-item:not(:first-of-type) {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
