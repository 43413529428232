<template>
    <div id="page-builder">
        <!-- Page builder top nav -->
        <div class="page-navigation">
            <div class="navigation-left d-flex align-items-center">
                <input type="text" v-model="page.title" />
                <span v-if="pageData.id" class="ms-4 text-uppercase text-primary fw-bold"
                    ><small>{{ pageData.status == "publish" ? "published" : pageData.status }}</small></span
                >
            </div>
            <div class="navigation-right d-flex justify-content-between align-items-center justify-content-center">
                <div class="me-3 text-primary d-flex align-items-center justify-content-center flex-column">
                    <small style="font-size: 12px">Hover Actions</small>
                    <div class="form-check form-switch ms-2">
                        <input title="Turn on/off actions on element or layout hover" class="form-check-input cursor-pointer" v-model="hoverActive" type="checkbox" role="switch" />
                    </div>
                </div>
                <div>
                    <button class="btn btn-primary me-2" @click="savePage('draft')" :disabled="savingToDraftsInProgress || publishingInProgress">
                        Save as Draft <span v-if="savingToDraftsInProgress"><i class="fas fa-spinner fa-spin"></i></span>
                    </button>
                    <button class="btn btn-success" @click="savePage('publish')" :disabled="savingToDraftsInProgress || publishingInProgress">
                        Publish <span v-if="publishingInProgress"><i class="fas fa-spinner fa-spin"></i></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="page-wrapper">
            <!-- Page content -->
            <div id="settingsCss" v-if="siteType == 'cms6'" v-html="'<style>' + settingsCss + '</style>'"></div>
            <div id="customizerCss" v-if="siteType == 'cms6'" v-html="'<style>' + customizerCss + '</style>'"></div>
            <div id="themeCss" v-if="siteType == 'cms6'" v-html="'<style>' + themeCss + '</style>'"></div>
            <div id="wpThemeCss" v-if="wpThemeCss" v-html="wpThemeCss"></div>
            <div class="page-content container">
                <div ref="content" class="page-builder-canvas" :class="{ 'd-flex justify-content-center align-items-center flex-column empty': pageLayouts.length < 1 }">
                    <p v-if="pageLayouts.length < 1" class="text-uppercase">Add a layout to get started!</p>
                    <draggable :emptyInsertThreshold="500" @add="addLayout" ghost-class="ghost" class="dragArea row" :list="pageLayouts" :group="{ name: 'blocks' }" handle=".move-column">
                        <template v-for="layout in pageLayouts" :key="layout.id">
                            <component
                                :selectedElement="selectedElement"
                                v-on:addElement="addElement"
                                v-on:selectElement="selectElement($event)"
                                @click="selectLayout(layout)"
                                :is="layout.type"
                                :layout="layout"
                                :hoverActive="hoverActive"
                                v-on:removeElement="removeElement($event)"
                                v-on:removeColumn="removeColumn($event)"
                                v-on:cloneColumn="cloneColumn($event)"
                                v-on:cloneElement="cloneElement($event)"
                            />
                        </template>
                    </draggable>
                </div>

                <button class="btn btn-primary mt-2 d-none" @click="showElementSettings($event)">Element Settings</button>
                <div class="developers mt-5 d-none">
                    <code class="">
                        <pre>{{ pageLayouts }}</pre>
                    </code>
                </div>
            </div>
            <!-- #Page content -->

            <!-- Page revisions -->
            <div v-if="siteType !== 'wordpress'" class="revisions-expand-button-wrapper" :class="{ expanded: revisionsListExpanded }">
                <div class="page-revisions-wrapper expanded-revisions-list">
                    <div class="page-revisions">
                        <h5>Revisions</h5>
                        <div v-for="revision in revisions" class="" :key="revision.id">
                            <div class="revision-item">
                                <div class="causer">
                                    <span class="causer-name">{{ revision.user ? revision.user.name : 'Unknown' }}</span>
                                    <span class="causer-time">{{ revision.time }}</span>
                                </div>
                                <div class="actions">
                                    <button @click="applyRevision(revision.properties)" class="btn text-primary"><i class="fas fa-history"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button @click="expandRevisionsButton()" class="revisions-expand-button">
                    <template v-if="!revisionsListExpanded">
                        <span><i class="fas fa-history"></i></span>
                        Revisions
                    </template>
                    <template v-else>
                        <span>x</span>
                    </template>
                </button>
            </div>
            <!-- #Page revisions -->

            <!-- Page properties -->
            <div class="elements-expand-button-wrapper" :class="{ expanded: elementsListExpanded }">
                <div class="page-elements-wrapper expanded-elements-list">
                    <div class="page-elements">
                        <div class="d-flex justify-content-center mb-2">
                            <a class="me-2 btn btn-outline-secondary btn-sm w-100" :class="{ 'bg-secondary text-white': tabVisible === 'Layouts' }" @click="changePropertiesTab('Layouts')" role="button">Layouts</a>
                            <a class="me-2 btn btn-outline-secondary btn-sm w-100" :class="{ 'bg-secondary text-white': tabVisible === 'Elements' }" @click="changePropertiesTab('Elements')" role="button">Elements</a>
                        </div>
                        <!-- Layouts list - all available layouts -->
                        <LayoutsList :visible="tabVisible" :layouts="layouts" />
                        <!-- Elements list - all available elements -->
                        <ElementsList :disabled="elementsDisabled" :visible="tabVisible" :elementGroups="elements" />
                    </div>
                </div>

                <button @click="expandElementsButton()" class="elements-expand-button">
                    <template v-if="!elementsListExpanded">
                        <span>+</span>
                        ADD
                    </template>
                    <template v-else>
                        <span>x</span>
                    </template>
                </button>
            </div>
            <!-- #Page properties -->
        </div>

        <!-- Element Setting (opens different overlay div with settings) -->
        <ElementSetting v-on:close="closeElementSetting()" :showElementSetting="showElementSetting" :loading="false" :element="editElement" :top="windowTop" />
    </div>
</template>

<script>
/* import { Modal } from "bootstrap"; */
import Column from "@/components/page-builder/elements/default/Column";
import { VueDraggableNext } from "vue-draggable-next";
import ElementsList from "@/components/page-builder/ElementsList";
import LayoutsList from "@/components/page-builder/LayoutsList";
import elementsData from "../page-builder/elements";
import layoutsData from "../page-builder/layouts";
import pageBuilderMixin from "@/mixins/pageBuilderMixin";
import wordpressMixin from "@/mixins/wordpressMixin";
import cms6Mixin from "@/mixins/cms6Mixin";
import siteMixin from "@/mixins/siteMixin";
import { mapGetters } from "vuex";
import ElementSetting from "../../components/page-builder/ElementSetting";
let site = localStorage.getObject("site");

let mixins;

if (site.type == "wordpress") {
    mixins = [wordpressMixin, pageBuilderMixin, siteMixin];
} else if (site.type == "cms6") {
    mixins = [cms6Mixin, pageBuilderMixin, siteMixin];
}
export default {
    name: "PageBuilder",
    components: {
        draggable: VueDraggableNext,
        Column,
        ElementsList,
        LayoutsList,
        ElementSetting,
    },

    data: () => ({
        pageId: null, // From database
        pageHtml: "",
        pageElements: [],
        pageLayouts: [],
        //elements: elementsData,
        layouts: layoutsData,
        settingElement: null,
        tabVisible: "Layouts",
        selectedElement: {},
        loading: "",
        unchangedPageLayouts: [],
        pageData: {},
        savingToDraftsInProgress: false,
        publishingInProgress: false,
        showElementSetting: false,
        editElement: {},
        hoverActive: true,
        mouseX: 100,
        mouseY: 100,
        windowTop: 150,
        elementsListExpanded: false,
        revisionsListExpanded: false,
        revisions: [],
        themeSettings: null,
        settingsCss: null,
        themeCss: null,
        wpThemeCss: null,
        customizerCss: `.page-content {
                        font-family: var(--primary-font) !important;
                    }
                    .page-content h1 {
                        font-size: var(--h1-size, 36px);
                        font-family: var(--hedings-font, --primary-font);
                    }
                    .page-content h2 {
                        font-size: var(--h2-size, 30px);
                        font-family: var(--hedings-font, --primary-font);
                    }
                    .page-content h3 {
                        font-size: var(--h3-size, 24px);
                        font-family: var(--hedings-font, --primary-font);
                    }
                    .page-content h4 {
                        font-size: var(--h4-size, 18px);
                        font-family: var(--hedings-font, --primary-font);
                    }
                    .page-content h5 {
                        font-size: var(--h5-size, 14px);
                        font-family: var(--hedings-font, --primary-font);
                    }
                    .page-content h6 {
                        font-size: var(--h6-size, 12px);
                        font-family: var(--hedings-font, --primary-font);
                    }`,
    }),

    computed: {
        elementsDisabled() {
            if (this.pageLayouts.length > 0) {
                return false;
            } else {
                return true;
            }
        },
        ...mapGetters(["page"]),
        editingPageId() {
            return this.$route.params.id;
        },
        siteType() {
            return this.getSiteType();
        },
        siteUrl() {
            return this.getSiteUrl();
        },
        elements() {
            if (site.type === "wordpress") {
                let filteredElements = elementsData.filter((e) => e.name !== "Expert");
                return filteredElements;
            }
            return elementsData;
        },
    },
    async mounted() {
        window.addEventListener("scroll", this.onScroll);
        if (!Object.keys(this.page).length && this.editingPageId) {
            let res = await this.getPage(this.editingPageId);
            let page = await res.data;

            if ((page.title && page.title.rendered) || (page.title && page.title.rendered === "")) {
                page.title = page.title.rendered;
            }

            this.$store.commit("setPage", { page });
        }

        // get wordpress theme styles
        if (this.siteType === "wordpress") {
            const d = new Date();
            let time = d.getTime();
            this.wpThemeCss = `<link rel="stylesheet" id="wpThemeCss" href="${this.siteUrl}/wp-content/uploads/pb/pb-theme-needed-styles.min.css?${time}" type="text/css" media="all">`;
        }

        this.pageData = this.page;
        if (this.page.pb_json) {
            this.pageLayouts = this.page.pb_json;
        }
        if (this.siteType === "cms6") {
            // Get Settings CSS
            this.themeSettings = await this.getClientThemeSetting();
            this.getCustomizerSettingsCSS();
            // Get theme CSS
            let theme = await this.getClientOptionKey("theme_active");
            this.themeCss = await this.getThemeCss(theme.data, this.page);

            let revisionsData = await this.getRevisions(this.page, "App\\Models\\Page");
            this.revisions = revisionsData.data;
        }
    },

    beforeUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    },

    /* beforeRouteLeave(to, from, next) {
        const answer = window.confirm("Do you really want to leave? you have unsaved changes!");
        if (answer) {
            next();
        } else {
            next(false);
        }
    }, */

    watch: {},

    methods: {
        onScroll() {
            this.windowTop = window.top.scrollY + 150;
        },
        checkRef() {
            this.pageHtml = this.$refs.content.innerHTML;
        },

        // Add layout
        addLayout(evt) {
            this.loading = "loading";
            let index = evt.newIndex;
            let name = this.pageLayouts[index].name;

            if (name === "1 Column") {
                this.pageLayouts[index] = {
                    id: "column1-" + Date.now(),
                    name: "Column",
                    type: "Column",
                    class: "col-12 col-lg-12 col-md-12",
                    props: {},
                    style: {},
                    childs: [],
                };
                this.pageLayouts[index] = this.addPropsAndStylesToColumn(this.pageLayouts[index]);
            }

            if (name === "2 Columns" || name === "3 Columns" || name === "4 Columns" || name === "6 Columns" || name === "With Sidebar") {
                this.insertInLayouts(name, index);
            }
            this.tabVisible = "Layouts";
            this.loading = "";
        },

        addPropsAndStylesToColumn(column) {
            column.props = {
                id: "",
                class: "",
                backgroundImage: "",
            };
            column.style = {
                backgroundSize: "",
                backgroundPosition: "",
                backgroundRepeat: "",
                backgroundColor: "",
                padding: "5px 5px 5px 5px",
                margin: "0px 0px 0px 0px",
                borderRadius: "0px 0px 0px 0px",
            };
            return column;
        },

        addPropsAndStylesToColumnArray(columnArray) {
            columnArray.forEach((column) => {
                column.props = {
                    id: "",
                    class: "",
                    backgroundImage: "",
                };
                column.style = {
                    backgroundSize: "",
                    backgroundPosition: "",
                    backgroundRepeat: "",
                    backgroundColor: "",
                    padding: "5px 5px 5px 5px",
                    margin: "0px 0px 0px 0px",
                    borderRadius: "0px 0px 0px 0px",
                };
            });

            return columnArray;
        },

        // Add element to Layout
        addElement(evt) {
            let layout = this.pageLayouts.find((e) => e.id === evt.layoutId);
            layout.childs[evt.elementIndex] = JSON.parse(JSON.stringify(evt.element));
            this.selectedElement = layout.childs[evt.elementIndex];
            if (this.selectedElement.type == "Accordion") {
                this.selectedElement.props.items[0].id = "accordion-item-" + Date.now();
            }
            this.showElementSetting = true;
            this.editElement = layout.childs[evt.elementIndex];
            this.elementsListExpanded = false;
        },

        insertInLayouts(name, index) {
            let columnArray = [];
            if (name === "2 Columns") {
                this.pageLayouts[index] = {
                    id: "column2-1-" + Date.now(),
                    name: "Column",
                    type: "Column",
                    class: "col-12 col-lg-6 col-md-6",
                    props: {},
                    style: {},
                    childs: [],
                };
                this.pageLayouts[index] = this.addPropsAndStylesToColumn(this.pageLayouts[index]);

                columnArray = [
                    {
                        id: "column2-2-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-6 col-md-6",
                        props: {},
                        style: {},
                        childs: [],
                    },
                ];
                columnArray = this.addPropsAndStylesToColumnArray(columnArray);
            }

            if (name === "3 Columns") {
                this.pageLayouts[index] = {
                    id: "column3-1-" + Date.now(),
                    name: "Column",
                    type: "Column",
                    class: "col-12 col-lg-4 col-md-4",
                    props: {},
                    style: {},
                    childs: [],
                };
                this.pageLayouts[index] = this.addPropsAndStylesToColumn(this.pageLayouts[index]);

                columnArray = [
                    {
                        id: "column3-2-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-4 col-md-4",
                        props: {
                            id: "",
                            class: "",
                            backgroundImage: "",
                        },
                        style: {
                            backgroundColor: "",
                            padding: "5px 5px 5px 5px",
                            margin: "0px 0px 0px 0px",
                            borderRadius: "0px 0px 0px 0px",
                        },
                        childs: [],
                    },
                    {
                        id: "column3-3-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-4 col-md-4",
                        props: {
                            id: "",
                            class: "",
                            backgroundImage: "",
                        },
                        style: {
                            backgroundColor: "",
                            padding: "5px 5px 5px 5px",
                            margin: "0px 0px 0px 0px",
                            borderRadius: "0px 0px 0px 0px",
                        },
                        childs: [],
                    },
                ];
                columnArray = this.addPropsAndStylesToColumnArray(columnArray);
            }

            if (name === "4 Columns") {
                this.pageLayouts[index] = {
                    id: "column4-1-" + Date.now(),
                    name: "Column",
                    type: "Column",
                    class: "col-12 col-lg-3 col-md-3",
                    props: {},
                    style: {},
                    childs: [],
                };
                this.pageLayouts[index] = this.addPropsAndStylesToColumn(this.pageLayouts[index]);

                columnArray = [
                    {
                        id: "column4-2-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-3 col-md-3",
                        props: {},
                        style: {},
                        childs: [],
                    },
                    {
                        id: "column4-3-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-3 col-md-3",
                        props: {},
                        style: {},
                        childs: [],
                    },
                    {
                        id: "column4-4-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-3 col-md-3",
                        props: {},
                        style: {},
                        childs: [],
                    },
                ];
                columnArray = this.addPropsAndStylesToColumnArray(columnArray);
            }

            if (name === "6 Columns") {
                this.pageLayouts[index] = {
                    id: "column6-1-" + Date.now(),
                    name: "Column",
                    type: "Column",
                    class: "col-12 col-lg-2 col-md-2",
                    props: {},
                    style: {},
                    childs: [],
                };
                this.pageLayouts[index] = this.addPropsAndStylesToColumn(this.pageLayouts[index]);

                columnArray = [
                    {
                        id: "column6-2-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-2 col-md-2",
                        props: {},
                        style: {},
                        childs: [],
                    },
                    {
                        id: "column6-3-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-2 col-md-2",
                        props: {},
                        style: {},
                        childs: [],
                    },
                    {
                        id: "column6-4-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-2 col-md-2",
                        props: {},
                        style: {},
                        childs: [],
                    },
                    {
                        id: "column6-5-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-2 col-md-2",
                        props: {},
                        style: {},
                        childs: [],
                    },
                    {
                        id: "column6-6-" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-2 col-md-2",
                        props: {},
                        style: {},
                        childs: [],
                    },
                ];
                columnArray = this.addPropsAndStylesToColumnArray(columnArray);
            }

            if (name === "With Sidebar") {
                this.pageLayouts[index] = {
                    id: "columnSidebar-" + Date.now(),
                    name: "Column",
                    type: "Column",
                    class: "col-12 col-lg-3 col-md-3",
                    props: {},
                    style: {},
                    childs: [],
                };
                this.pageLayouts[index] = this.addPropsAndStylesToColumn(this.pageLayouts[index]);

                columnArray = [
                    {
                        id: "columnContent" + Date.now(),
                        name: "Column",
                        type: "Column",
                        class: "col-12 col-lg-9 col-md-9",
                        props: {},
                        style: {},
                        childs: [],
                    },
                ];
                columnArray = this.addPropsAndStylesToColumnArray(columnArray);
            }

            let beforeArray = this.pageLayouts.slice(0, index);
            let afterArray = this.pageLayouts.slice(index, this.pageLayouts.length);

            this.pageLayouts = beforeArray.concat(columnArray);
            this.pageLayouts = this.pageLayouts.concat(afterArray);
        },

        changeSetting(element) {
            this.settingElement.value = element;
        },

        changeProperties(element) {
            this.selectedElement = element;
        },

        // Change properties tabs
        changePropertiesTab(prop) {
            this.tabVisible = prop;
        },

        // Remove layout element
        removeColumn(id) {
            let result = confirm("Are you sure?");
            if (result == true) {
                this.tabVisible = "Layouts";
                let newElements = this.pageLayouts.filter((e) => e.id !== id);
                this.pageLayouts = newElements;
                this.selectedElement = {};
            }
        },

        // Clone column
        cloneColumn(column) {
            let newColumn = JSON.parse(JSON.stringify(column));
            newColumn.id = newColumn.type + "-" + Date.now() + "-clone";

            if (newColumn.childs.length > 0) {
                newColumn.childs.forEach((element) => {
                    element.id = element.type + "-" + Date.now() + "-clone";
                });
            }
            this.pageLayouts.push(newColumn);
        },

        // Clone element
        cloneElement(obj) {
            let newElement = JSON.parse(JSON.stringify(obj.element));
            newElement.id = newElement.type + "-" + Date.now() + "-clone";
            this.pageLayouts.find((layout) => layout.id === obj.layout.id).childs.push(newElement);
        },

        // Remove element in layout
        removeElement(data) {
            let result = confirm("Are you sure?");
            if (result == true) {
                this.tabVisible = "Layouts";
                let layout = this.pageLayouts.find((l) => l.id === data.layoutId);
                let newElements = layout.childs.filter((e) => e.id !== data.elementId);
                layout.childs = newElements;
                this.selectedElement = {};
            }
        },

        // Select layout properties to change
        selectLayout(element) {
            this.selectedElement = element;
        },

        // Select element properties to change
        selectElement(element) {
            console.log(element.style.customCss);
            if (element.style.customCss == undefined) {
                element.style.customCss = "";
                console.log("UPAO GDE TREBA");
            }
            console.log("==========================");
            console.log(element);
            console.log("==========================");
            this.editElement = element;
            this.showElementSetting = true;
            this.selectedElement = element;
        },

        async savePage(type) {
            if (type == "draft") {
                this.savingToDraftsInProgress = true;
            } else {
                this.publishingInProgress = true;
            }
            let response, title, text, icon;
            // if page title is not set
            if (!this.page.title) {
                title = "Failure!";
                icon = "warning";
                text = "Page title must be set!";
                this.handlePageSaved(title, text, icon);
            } else {
                let status = "publish";
                if (type === "draft") {
                    status = "draft";
                }
                //this.unchangedPageLayouts = JSON.parse(JSON.stringify(this.page.json));
                // Parse page and generate needed data
                let pageData = await this.generateDataContent(this.pageLayouts);
                // Preparing new page data from form builder
                let newPage = {
                    ...this.pageData,
                    status: status,
                    content: pageData.data.content,
                    pb_json: pageData.data.json,
                    pb_css: pageData.data.css,
                };

                if (pageData.data.js !== "") {
                    newPage.pb_js = pageData.data.js;
                }

                if (newPage.featured_media && newPage.featured_media.id) {
                    newPage.featured_media = newPage.featured_media.id;
                }

                // Get return info to show some information about save
                if (this.pageData.id) {
                    response = await this.updatePageFromPageBuilder(newPage);
                } else {
                    response = await this.savePageFromPageBuilder(newPage);
                }

                if (response.status && response.status == 200) {
                    title = "Success!";
                    icon = "success";
                    if (status == "publish") {
                        text = "Page published successfully!";
                    } else {
                        text = "Page saved as draft successfully!";
                    }

                    if (type == "draft") {
                        this.savingToDraftsInProgress = false;
                    } else {
                        this.publishingInProgress = false;
                    }

                    if (!this.editingPageId) {
                        window.history.pushState({}, "", this.$route.path + "/" + response.data.id);
                    }
                } else {
                    title = "Failure!";
                    icon = "danger";
                    text = "Error!";
                }
                this.handlePageSaved(title, text, icon);
                //this.page = response.data;
                if (site.type === "cms6") {
                    this.pageData = response.data;
                    let revisionsData = await this.getRevisions(this.page, "App\\Models\\Page");
                    this.revisions = revisionsData.data;
                } else {
                    this.pageData.id = response.data;
                }
            }
        },

        handlePageSaved(title, text, icon) {
            this.$swal({
                toast: true,
                showConfirmButton: false,
                timer: 3000,
                title: title,
                text: text,
                position: "top-end",
                icon: icon,
            });
        },

        copyPageHtml(link) {
            navigator.clipboard.writeText(link);
        },

        closeElementSetting() {
            this.showElementSetting = false;
            this.editElement = {};
            this.selectedElement = {};
        },
        showElementSettings(event) {
            this.showElementSetting = true;
            var x = event.pageX;
            var y = event.pageY;
            console.log(x);
            console.log(y);
        },

        mouseLocation(event) {
            var x = event.pageX;
            var y = event.pageY;
            console.log(x);
            console.log(y);
        },

        expandElementsButton() {
            this.elementsListExpanded = !this.elementsListExpanded;
        },

        expandRevisionsButton() {
            this.revisionsListExpanded = !this.revisionsListExpanded;
        },

        getCustomizerSettingsCSS() {
            let css = ":root{";
            let importFont = "";

            this.themeSettings.forEach((setting) => {
                let variableName = setting.code.toLowerCase().replace("_", "-");
                if (setting && setting.value && variableName.includes("font") && setting.value !== "") {
                    let fontName = setting.value.replace(" ", "+");
                    importFont += "@import url('https://fonts.googleapis.com/css?family=" + fontName + "&display=swap');\n";
                }
                css += "--" + variableName + ":" + setting.value + ";";
            });

            css += "}";
            css = importFont + css;
            this.settingsCss = css;
        },

        applyRevision(revision) {
            this.pageLayouts = revision.old.pb_json;
            this.revisionsListExpanded = false;
        },
    },
    mixins: mixins,
};
</script>

<style>
.page-elements-wrapper {
    border-radius: 20px;
    position: fixed;
    width: 300px;
    height: 450px;
    bottom: 70px;
    right: 50px;
    background-color: #e7ebef;
    color: #463a3c;
    overflow: auto;
}

.page-elements-wrapper::-webkit-scrollbar {
    display: none;
}

.page-elements {
    width: 100%;
    padding: 20px 10px 10px 10px;
}

.page-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 100%;
    color: white;
    padding: 20px 20px;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 15px;
}

.page-navigation input {
    border-radius: 5px;
    border: none;
    font-size: 20px;
    padding: 5px;
    width: 350px;
    border: 1px dashed #ededed;
}

.page-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.page-content {
    transition: all;
    transition: 0.5s;
    width: 100%;
    padding: 1rem;
    position: relative;
    border-radius: 10px;
    padding-bottom: 100px;
    margin-bottom: 20px;
    border: 2px dashed rgba(0, 0, 0, 0.15);
}

.page-content.extended {
    width: 100%;
}

#page-builder {
    display: block;
    width: 100%;
}

.page-builder-canvas {
    min-height: 600px;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    min-height: calc(100vh - 290px);
}

.page-builder-canvas.empty {
    border: 2px dashed #dddddd;
}

.pb-text-editor .tox-tinymce {
    min-height: 300px !important;
    border-radius: 3px !important;
    margin-bottom: 1rem;
}

.jp-elements-item-editor .tox-tinymce {
    min-height: 200px !important;
    border-radius: 3px !important;
    margin-bottom: 1rem;
}

.layout {
    border: 2px dashed rgba(0, 0, 0, 0.15);
}

.layout.not-empty {
    border: none;
}

.code-editor {
    width: 100% !important;
}
.code-area {
    min-height: 300px;
}

.ghost-element {
    padding: 15px;
    background-color: gray;
    border: 2px solid red;
}

.sk-element-wrapper {
    cursor: pointer;
}

.sk-not-selected-element {
    width: 100%;
    height: 100px;
    background-color: #f8f7f7;
    border-radius: 10px;
    color: #4e6078;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}

.elements-expand-button-wrapper {
    position: fixed;
    bottom: 10px;
    right: 20px;
    text-align: center;
    z-index: 9999;
}
.elements-expand-button {
    height: 5em;
    width: 5em;
    font-size: 14px;
    border-radius: 50%;
    border: 0 none;
    background-color: #4e6078;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: all 200ms ease;
}
.elements-expand-button:hover,
.elements-expand-button:focus,
.elements-expand-button:active {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    outline: 0;
}
.elements-expand-button span {
    display: block;
    font-size: 2em;
    transform: scale(1);
    transition: transform 100ms ease;
}
.elements-expand-button:hover span,
.expanded .elements-expand-button span,
.expanded .elements-expand-button span {
    transform: scale(1.25);
}
.expanded .elements-expand-button {
    transform: scale(0.5);
    color: rgba(255, 255, 255, 0.5);
    background-color: #4e6078;
}
.expanded-elements-list {
    list-style: none;
    padding: 0;
    margin: 0;
    transition: all 200ms ease;
    transform: translate(0, 90px) scale(0.5);
    transform-origin: bottom center;
    opacity: 0;
}
.expanded .expanded-elements-list {
    transform: translate(0px, 20px) scale(1);
    opacity: 1;
}
/** Revisions */

.revisions-expand-button-wrapper {
    position: fixed;
    bottom: 10px;
    left: 20px;
    text-align: center;
    z-index: 9999;
}
.revisions-expand-button {
    height: 6em;
    width: 6em;
    font-size: 12px;
    border-radius: 50%;
    border: 0 none;
    background-color: #4e6078;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: all 200ms ease;
}
.revisions-expand-button:hover,
.revisions-expand-button:focus,
.revisions-expand-button:active {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    outline: 0;
}
.revisions-expand-button span {
    display: block;
    font-size: 1.5em;
    transform: scale(1);
    transition: transform 100ms ease;
}
.revisions-expand-button:hover span,
.expanded .revisions-expand-button span,
.expanded .revisions-expand-button span {
    transform: scale(1.25);
}
.expanded .revisions-expand-button {
    transform: scale(0.5);
    color: rgba(255, 255, 255, 0.5);
    background-color: #4e6078;
}
.expanded-revisions-list {
    width: 220px;
    list-style: none;
    padding: 15px;
    margin: 0;
    transition: all 200ms ease;
    transform: translate(0, 90px) scale(0.5);
    transform-origin: bottom center;
    opacity: 0;
    background-color: white;
    border-radius: 20px;
    border: 1px solid #4e6078;
}
.expanded .expanded-revisions-list {
    transform: translate(0px, 20px) scale(1);
    opacity: 1;
}

.page-revisions-wrapper {
    border-radius: 20px;
    position: fixed;
    width: 220px;
    bottom: 70px;
    left: 50px;
    color: #463a3c;
    overflow: auto;
    max-height: calc(100vh - 200px);
}

button:hover {
    box-shadow: none;
    transform: none;
}

.revision-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.revision-item .causer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.revision-item .causer .causer-name {
    font-size: 12px;
}

.revision-item .causer .causer-time {
    font-size: 10px;
}
</style>
