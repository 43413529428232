import wordpressMixin from "@/mixins/wordpressMixin";
import cms6Mixin from "@/mixins/cms6Mixin";

let site = localStorage.getObject("site");

let mixins;

if (site.type == "wordpress") {
    mixins = [wordpressMixin];
} else if (site.type == "cms6") {
    mixins = [cms6Mixin];
}

export default {
    methods: {
        async getElementFromOption() {
            if (site.type === "cms6") {
                let data = await this.getClientOptionKey("contact_form_html");
                let contact = data.data;
                return contact;
            }
        },

        /**
         * Get Client Theme Setting
         *
         * @returns array
         */
        async getClientThemeSetting() {
            if (site.type === "cms6") {
                let data;
                let theme = await this.getClientOptionKey("theme_active");
                data = await this.getThemeSettings(theme.data);
                let contact = data.data;
                return contact;
            }
        },

        /**
         * Generate Data Content
         * Function send JSON to backend and backend returns array with HTML,CSS and JS code
         * @param {json} page
         * @returns array
         */
        async generateDataContent(page) {
            let parsedData = await this.parsePageContent(page);
            return parsedData;
        },
    },
    mixins: mixins,
};
