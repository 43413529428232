<template>
    <!-- Displaying layouts list -->
    <div class="layouts-list py-2" v-if="visible === 'Layouts'">
        <h6>List of all layouts</h6>
        <draggable ghost-class="ghost" class="dragArea layouts row" :list="layouts" :group="{ name: 'blocks', pull: 'clone', put: false }" :sort="false">
            <template v-for="(layout, index) in layouts" :key="index">
                <div class="col-md-6">
                    <div class="layout-item">
                        <template v-if="layout.name == '1 Column'">
                            <div class="image">
                                <div class="part"></div>
                            </div>
                        </template>
                        <template v-if="layout.name == '2 Columns'">
                            <div class="image">
                                <div class="part"></div>
                                <div class="part"></div>
                            </div>
                        </template>
                        <template v-if="layout.name == '3 Columns'">
                            <div class="image">
                                <div class="part"></div>
                                <div class="part"></div>
                                <div class="part"></div>
                            </div>
                        </template>
                        <template v-if="layout.name == '4 Columns'">
                            <div class="image">
                                <div class="part"></div>
                                <div class="part"></div>
                                <div class="part"></div>
                                <div class="part"></div>
                            </div>
                        </template>
                        <template v-if="layout.name == '6 Columns'">
                            <div class="image">
                                <div class="part"></div>
                                <div class="part"></div>
                                <div class="part"></div>
                                <div class="part"></div>
                                <div class="part"></div>
                                <div class="part"></div>
                            </div>
                        </template>
                        <template v-if="layout.name == 'With Sidebar'">
                            <div class="image">
                                <div class="part"></div>
                                <div class="part" style="flex-basis: 60px"></div>
                            </div>
                        </template>

                        <div class="title">
                            {{ layout.name }}
                        </div>
                    </div>
                </div>
            </template>
        </draggable>
    </div>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";
export default {
    name: "LayoutsList",
    props: {
        layouts: {
            required: true,
            type: Array,
        },
        visible: {
            required: true,
            type: String,
        },
    },
    components: {
        draggable: VueDraggableNext,
    },
};
</script>
<style scoped>
.dragArea {
    min-height: 50px;
    background-clip: red;
}
.ghost {
    padding: 15px;
    width: 100%;
}

.layout-item {
    padding: 10px;
    margin-bottom: 5px;
    text-align: center;
    cursor: move;
    border-radius: 5px;
    font-size: 1.2rem;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    transition-property: box-shadow;
    transition-duration: 0.15s;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
}
.layout-item:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(34, 41, 47, 0.075);
    transition: box-shadow 0.2s ease-in-out;
    border-radius: 0;
    background-color: white;
}

.layout-item:hover > .image .part {
    background-color: black;
}

.layout-item .image {
    display: flex;
    justify-content: space-between;
    height: 30px;
    width: 100%;
}

.layout-item .image .part {
    display: flex;
    height: 30px;
    width: 100%;
    margin: 0 2px;
    background-color: #4e6078;
    border-radius: 2px;
}

.layout-item .title {
    font-size: 12px;
    margin-top: 5px;
}
</style>
