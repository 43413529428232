<template>
	<section id="tw-view-reset-password" class="justify-content-center align-items-center">
        <div class="card">
            <div class="card-header tw-red-bg-color text-white">
                Create new password
            </div>
            <form @submit.prevent="createNewPassword()">
                <div class="card-body">
                    <div v-if="createNewPasswordError" class="alert alert-danger" role="alert" v-html="createNewPasswordError"></div>
                    <div v-if="updatePasswordSuccessMessage" class="alert alert-success" role="alert" v-html="updatePasswordSuccessMessage"></div>
                    <div class="mb-3">
                        <label for="new-password" class="form-label">New password</label>
                        <div class="input-group">
                            <input class="form-control" v-model="newPassword" id="new-password" :type="showNewPassword ? 'text' : 'password'" />
                            <div class="input-group-text">
                                <span class="cursor-pointer" @click="showNewPassword = !showNewPassword">
                                    <span v-show="showNewPassword">
                                        <i class="far fa-eye-slash"></i>
                                    </span>
                                    <span v-show="!showNewPassword">
                                        <i class="far fa-eye"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="confirm-new-password" class="form-label">Confirm new password</label>
                        <div class="input-group">
                            <input class="form-control" v-model="newPasswordConfirmation" id="confirm-new-password" :type="showNewPasswordConfirmation ? 'text' : 'password'" />
                            <div class="input-group-text">
                                <span class="cursor-pointer" @click="showNewPasswordConfirmation = !showNewPasswordConfirmation">
                                    <span v-show="showNewPasswordConfirmation">
                                        <i class="far fa-eye-slash"></i>
                                    </span>
                                    <span v-show="!showNewPasswordConfirmation">
                                        <i class="far fa-eye"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer d-flex bg-white border-0 justify-content-end">
                    <button type="submit" class="btn tw-red-bg-color text-white d-flex">
                        Confirm
                        <span v-if="spinner" class="ms-1"><i class="fas fa-spinner fa-spin"></i></span>
                    </button>
                </div>
            </form>
        </div>
	</section>
</template>

<script>
	export default {
		name: 'ResetPassword',
		components: {

		},
		data: () => ({
            newPassword: '',
            newPasswordConfirmation: '',
            createNewPasswordError: null,
            token: null,
            spinner: false,
            updatePasswordSuccessMessage: '',
            email: null,
            showNewPassword: false,
            showNewPasswordConfirmation: false,
		}),
		mounted() {
            this.token = this.$route.query.token;
            this.email = this.$route.query.email;
		},
		methods: {
            async createNewPassword() {
                this.spinner = true;
                this.createNewPasswordError = null;
                let response = await this.axios.post(process.env.VUE_APP_JUNIPER_API + `reset-password`, {password: this.newPassword, password_confirmation: this.newPasswordConfirmation, token: this.token, email: this.email});

                if (response.data.type && response.data.type == "error") {
                    let errors = "";

                    for (const [key, error] of Object.entries(response.data.errors)) {
                        key;
                        errors += error + "<br>";
                    }

                    this.createNewPasswordError = errors;
                } else if (response.data.type && response.data.type == 'success') {
                    console.log(response.data)
                    this.updatePasswordSuccessMessage = `${response.data.message}<br><small><a href="/"> Go back to login page </a></small>`;
                }
                this.spinner = false;
            }
		},
		mixins: []
    }
</script>

<style scoped>
    .card {
        border-radius: 12px 12px 9px 9px;
        width: 400px;
        max-width: 100%;
    }
    .card .card-header {
        border-radius: 9px 9px 0 0;
        height: 71px;
        padding: 8px;
    }
    .card .card-body input {
        /* border-radius: 21.5px !important; */
    }
    .card .card-footer {
        border-radius: 9px;
    }
    .card .card-footer button.btn {
        border-radius: 21.5px;
        width: 130px;
        font-size: 18px;
        padding: 5px 30px;
    }
</style>
