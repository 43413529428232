<template>
    <div class="d-flex flex-row w-100 mb-2 justify-content-center">
        <div class="d-flex justify-content-start mt-2 me-2 align-items-center flex-column">
            <a v-if="!link" style="font-size: 12px" @click="link = true" title="Link all values" role="button"><i class="fas fa-unlink"></i></a>
            <a v-if="link" style="font-size: 12px" @click="link = false" title="Unlink values" role="button"><i class="fas fa-link"></i></a>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-column">
            <input class="input-part input-part-first" type="number" v-model="top" style="width: 55px" min="0" @change="changeValue(top)" />
            <small class="input-part-info">T</small>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-column">
            <input class="input-part" type="number" v-model="right" style="width: 55px" min="0" @change="changeValue(right)" />
            <small class="input-part-info">R</small>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-column">
            <input class="input-part" type="number" v-model="bottom" style="width: 55px" min="0" @change="changeValue(bottom)" />
            <small class="input-part-info">B</small>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-column">
            <input class="input-part" type="number" v-model="left" style="width: 55px" min="0" @change="changeValue(left)" />
            <small class="input-part-info">L</small>
        </div>
        <div class="d-flex justify-content-start align-items-start flex-column">
            <select class="item-part select-part" v-model="unit" style="width: 58px" @change="changeUnit()">
                <option value="px">px</option>
                <option value="rem">rem</option>
                <option value="%">%</option>
            </select>
        </div>
        <div class="d-flex justify-content-center align-items-top mt-2">
            <a class="text-white ms-1" v-if="top !== 0 || bottom !== 0 || right !== 0 || left !== 0" style="font-size: 12px" @click="unsetValues()" role="button"><i class="fas fa-times-circle"></i></a>
        </div>
    </div>
</template>
<script>
export default {
    name: "FourValues",
    props: ["title", "values"],
    data: () => ({
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        unit: "px",
        link: true,
    }),
    created() {
        if (this.values) {
            const valueArray = this.values.split(" ");
            if (this.values.includes("px")) {
                this.unit = "px";
            } else if (this.values.includes("rem")) {
                this.unit = "rem";
            } else {
                this.unit = "%";
            }
            this.top = valueArray[0].replace(this.unit, "");
            this.right = valueArray[1].replace(this.unit, "");
            this.bottom = valueArray[2].replace(this.unit, "");
            this.left = valueArray[3].replace(this.unit, "");
        } else {
            this.value = "auto";
        }
    },
    methods: {
        changeValue(value) {
            if (this.link) {
                this.top = value;
                this.right = value;
                this.bottom = value;
                this.left = value;
            }
            this.$emit("changeValue", this.val);
        },

        changeUnit() {
            this.$emit("changeValue", this.val);
        },

        unsetValues() {
            this.top = 0;
            this.right = 0;
            this.bottom = 0;
            this.left = 0;
            this.$emit("changeValue", this.val);
        },
    },
    computed: {
        val() {
            return `${this.top}${this.unit} ${this.right}${this.unit} ${this.bottom}${this.unit} ${this.left}${this.unit}`;
        },
    },
    emits: ["changeValue"],
};
</script>

<style scoped>
.input-part {
    background: #fff !important;
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    color: #333 !important;
    display: inline;
    font-size: 13px;
    height: auto;
    line-height: 15px;
    margin: 1px;
    outline: none;
    padding: 3px 6px;
    width: auto;
    box-sizing: border-box;
    height: 31.5px;
}

.input-part-first {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.select-part {
    background: #e6eaed;
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    color: #333 !important;
    display: inline;
    font-size: 13px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    height: 31.5px;
}

.input-part-info {
    font-size: 10px;
    font-weight: bold;
}
</style>
