<template>
    <section id="tw-view-events" class="tw-content col-12">
        <div class="bg-white">
            <div class="p-4">
                <div class="tw-table-header row">
                    <div class="tw-table-search-wrapper col-lg-4 text-end">
                        <input type="search" id="event-search" class="form-control" v-model="querySearch" placeholder="Search events" />
                        <span class="tw-table-header-search-icon"><i class="fas fa-search"></i></span>
                    </div>
                    <div id="tw-events-view-wrapper" class="col mt-3 mt-lg-0">
                        <div class="d-flex">
                            <div>
                                <button class="tw-light-grey-bg-color tw-default-text-color d-flex align-items-center justify-content-center w-100" :class="{ active: eventsView === 'calendar' }" @click.prevent.stop="changeView('calendar')">
                                    <span class="d-flex justify-content-center align-items-center">
                                        <i class="fas fa-calendar"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="ms-2">
                                <button class="tw-light-grey-bg-color tw-default-text-color d-flex align-items-center justify-content-center w-100" :class="{ active: eventsView === 'list' }" @click.prevent.stop="changeView('list')">
                                    <span class="d-flex justify-content-center align-items-center">
                                        <i class="fas fa-list"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="ms-2">
                                <Datepicker ref="filterDate" class="tw-table-header-filter-date" v-model="filterDate" :format="format" :clearable="false" monthPicker placeholder="Filter by Month" />
                            </div>
                        </div>
                    </div>
                    <div v-if="hasHeyGovEvents && (( siteType == 'wordpress' && isStuff) || (siteType == 'cms6' && userHasPermission('create_event_categories')))" class="col d-inline-flex">
                        <router-link :to="{ name: 'EventsStyle' }" class="btn tw-button tw-light-grey-bg-color tw-default-text-color" title="Events style">
                            <span><i class="fas fa-palette"></i></span>
                        </router-link>

                        <router-link :to="{ name: 'EventsList' }" class="ms-2 text-decoration-none tw-btn-event tw-button text-dark p-1 tw-light-grey-bg-color tw-default-text-color" title="Events list">
                            <span class="d-flex justify-content-center align-items-center">
                                <i class="fas fa-th-list"></i>
                            </span>
                        </router-link>
                        <!--Display only if heygov event categories are empty-->
                        <button
                            v-if="!hgCategories.length && userHasPermission('import_event_categories')"
                            class="ms-2 text-decoration-none tw-btn-event tw-button text-dark p-1 tw-white-bg-color tw-default-text-color"
                            data-bs-html="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Import event categories"
                            @click="importEventCategories"
                        >
                            <span class="d-flex justify-content-center align-items-center">
                                <i class="fas fa-file-import"></i>
                            </span>
                        </button>
                        <div v-if="importStarted" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
                            <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                        </div>
                        <!--Display only if heygov events are empty-->
                        <button
                            v-if="hgCategories.length && !events.length && !importedEvents.length"
                            class="ms-2 text-decoration-none tw-btn-event tw-button text-dark p-1 tw-light-grey-bg-color tw-default-text-color"
                            data-bs-html="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Import events"
                            @click="openImportEventsModal"
                        >
                            <span class="d-flex justify-content-center align-items-center">
                                <i class="fas fa-upload"></i>
                            </span>
                        </button>
                    </div>
                    <div class="col text-end mt-3 mt-lg-0">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <button class="tw-default-bg-color text-white d-block d-lg-flex align-items-center ms-auto justify-content-center me-auto me-lg-0 w-100" v-if="selected" @click.prevent.stop="bulkDelete">
                                    <i class="fa fa-trash"></i>
                                    <span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1 ms-1"
                                        ><small>Delete {{ selected }} Events</small></span
                                    >
                                </button>
                            </div>
                            <div class="col-12 col-md-6">
                                <button class="tw-red-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0" @click="openCreateEventModal">
                                    <span class="d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-1">
                                        <i class="fas fa-plus"></i>
                                    </span>
                                    <span class="px-2 d-inline-flex align-items-center justify-content-center flex-grow-1 fs-6">Create Event</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="eventsView == 'list'" class="table-responsive">
                    <table class="tw-table table text-nowrap">
                        <thead>
                            <tr>
                                <th class="tw-table-checkbox-th"></th>
                                <th st-sort="title">
                                    <span class="cursor-pointer d-flex align-items-center" @click="setOrder('title')">
                                        <span class="me-3">Name</span>
                                        <span v-if="orderBy != 'title'" class="d-inline-flex align-items-center justify-content-center">
                                            <span class="tw-toggle-angle-up me-2">
                                                <span class="d-block"></span>
                                            </span>
                                            <span class="tw-toggle-angle-down">
                                                <span class="d-block"></span>
                                            </span>
                                        </span>
                                        <span v-if="orderBy == 'title' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
                                            <span class="tw-toggle-angle-up">
                                                <span class="d-block"></span>
                                            </span>
                                        </span>
                                        <span v-if="orderBy == 'title' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
                                            <span class="tw-toggle-angle-down">
                                                <span class="d-block"></span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th st-sort="start_date">
                                    <span class="cursor-pointer d-flex align-items-center" @click="setOrder('start_date')">
                                        <span class="me-3">Date</span>
                                        <span v-if="orderBy != 'start_date'" class="d-inline-flex align-items-center justify-content-center">
                                            <span class="tw-toggle-angle-up me-2">
                                                <span class="d-block"></span>
                                            </span>
                                            <span class="tw-toggle-angle-down">
                                                <span class="d-block"></span>
                                            </span>
                                        </span>
                                        <span v-if="orderBy == 'start_date' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
                                            <span class="tw-toggle-angle-up">
                                                <span class="d-block"></span>
                                            </span>
                                        </span>
                                        <span v-if="orderBy == 'start_date' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
                                            <span class="tw-toggle-angle-down">
                                                <span class="d-block"></span>
                                            </span>
                                        </span>
                                    </span>
                                </th>
                                <th>Recurring</th>
                                <th>Category</th>
                                <th v-if="hasHeyGov && hasHeyGovEvents">Location</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="position-relative">
                            <div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
                                <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                            </div>
                            <tr v-for="(event, index) in events.filter((e) => e.status !== 'deleted')" :key="index">
                                <td class="tw-checkbox"><input :id="'event' + event.id" type="checkbox" @click="countSelected(index, $event)" value="1" :checked="event.checked" /><label :for="'event' + event.id"></label></td>
                                <td>
                                    <span
                                        v-html="sliceString(!hasHeyGovEvents ? event.title : event.name, 70)"
                                        data-bs-html="true"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :title="!hasHeyGovEvents ? event.title : event.name"
                                    ></span>
                                </td>
                                <!-- Fix for two hour glitch moment(event.starts_at_local).subtract(moment.duration({hours: 2, minutes: 0} -->
                                <td>
                                    {{ moment(!hasHeyGovEvents ? event.start_date : getDate(event.starts_at)).format("ll") }}
                                    <span v-if="!event.all_day">{{ !hasHeyGovEvents ? moment(event.start_date).format("LT") : getTime(event.starts_at) !== "12:00 AM" ? getTime(event.starts_at) : "" }}</span>
                                    <span v-if="!hasHeyGovEvents && !event.all_day">
                                        -
                                        {{ moment(event.end_date).format("LT") }}</span
                                    >
                                    <span v-else-if="hasHeyGovEvents && event.metadata && event.metadata.hide_end_time === false">
                                        {{ getTime(event.ends_at) !== "11:59 PM" ? `- ${getTime(event.ends_at)}` : "" }}
                                    </span>
                                    <span v-else-if="hasHeyGovEvents && !event.metadata && !event.all_day"> {{ `- ${getTime(event.ends_at)}` }}</span>
                                    <span v-else-if="event.all_day"> &middot; all day</span>
                                </td>
                                <td>
                                    <span v-if="event.recurrence && event.recurrence.rules && !hasHeyGovEvents">
                                        <span v-for="(recurrence, index) in event.recurrence.rules" :key="index">
                                            <span v-if="recurrence.custom.interval > 1">Every {{ recurrence.custom.interval }} {{ intervalLabels[recurrence.custom.type] }}</span>
                                            <span v-else><span v-html="recurrence.custom.type"></span></span>
                                            <span v-if="index + 1 < event.recurrence.rules.length">,</span>
                                        </span>
                                    </span>
                                    <span v-else>
                                        {{ event.type === "single" ? "No" : "Yes" }}
                                        <small v-if="event.type === 'recurrence' && event.parent.length" class="badge ms-2 text-muted bg-light">
                                            {{ event.type === "recurrence" && event.parent[0].recurrence ? JSON.parse(event.parent[0].recurrence).frequency : "" }}
                                        </small>
                                        <small v-else-if="event.type === 'recurrent'" class="badge ms-2 text-muted bg-light">{{ event.recurrence.frequency }}</small>
                                    </span>
                                </td>
                                <td>
                                    <span v-if="!hasHeyGovEvents">
                                        <span v-for="(category, index) in event.categories" :key="index">
                                            <span v-html="category.name"></span>
                                            <span v-if="index + 1 < event.categories.length">,</span>
                                        </span>
                                    </span>
                                    <!--HeyGov event categories -->
                                    <span v-else>
                                        <span v-for="(category, index) in event.categories" :key="`event-category-${category}`" class="m-1">
                                            <span v-if="hgCategories"> {{ findCategoryValue(category, "name") }}</span>
                                            <span v-else>No category</span>
                                            <span v-if="index + 1 < event.categories.length">,</span>
                                        </span>
                                    </span>
                                </td>
                                <td v-if="hasHeyGov && hasHeyGovEvents">
                                    <span v-if="event.metadata && event.metadata.hide_location == false">
                                        <span v-if="event.location && event.location.address" :title="event.location.address">{{ sliceString(event.location.address, 20) }}</span>
                                        <span class="cursor-pointer text-muted badge bg-light text-dark">
                                            <router-link :to="`/events/event-location/${event?.uuid ?? event?.id}`" class="text-decoration-none text-muted"
                                                ><small class="me-1">{{ event.location && event.location.address ? "Change Location" : "Set Location" }}</small></router-link
                                            ></span
                                        >
                                    </span>
                                    <span v-else
                                        ><span class="cursor-pointer text-muted badge bg-light text-dark" @click="turnOnEventLocation(event)"><small class="me-1">Turn On Map</small></span></span
                                    >
                                </td>
                                <td class="text-end column-actions">
                                    <div class="d-flex justify-content-end">
                                        <a href="" @click.prevent.stop="removeEvent(event)" class="d-flex align-items-center"
                                            ><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span
                                        ></a>
                                        <a href="" @click.prevent.stop="openCreateEventModal(event)" class="d-flex align-items-center"
                                            ><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i title="Update single event" class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span
                                        ></a>

                                        <!--Display only if event recurrent parent or child
										<div v-if="hasHeyGovEvents">
											<a
												v-if="event.parent[0] || event.type === 'recurrent'"
												href=""
												@click.stop.prevent="openCreateEventModal(event)"
												class="d-flex align-items-center"
												><span class="fa-stack"
													><i
														class="tw-light-red-color fa fa-circle fa-stack-2x"
													></i
													><i
														title="Update all upcoming events"
														class="fas fa-link fa-stack-1x fa-inverse"
													></i></span
											></a>
										</div> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="requestResolved && (!events || !events.length)">
                            <tr>
                                <td colspan="6" class="text-center">Nothing to show, no results found.</td>
                            </tr>
                        </tbody>
                        <tfoot v-if="events.length">
                            <tr>
                                <td colspan="3">
                                    Rows per page
                                    <select v-model="perPage" class="border">
                                        <option :value="5">5</option>
                                        <option :value="10">10</option>
                                        <option :value="20">20</option>
                                    </select>
                                </td>
                                <td colspan="4" class="tw-table-pagination text-end">
                                    <Pagination :totalItems="numberOfEvents" :currentPage="page" :perPage="perPage" @pageChanged="setPage" :goButton="false" styled="centered" borderActiveColor="" borderTextActiveColor="text-white" />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div v-if="eventsView == 'calendar'" class="tw-events-calendar">
                    <Calendar ref="Calendar" :month="filterDate" :jurisdiction="jurisdiction" @monthChanged="handleMonthChanged" :events="events" :requestResolved="requestResolved" @countSelected="countSelected" @openCreateEventModal="openCreateEventModal" />
                </div>
            </div>
        </div>
        <CreateEventModal ref="CreateEventModal" :editing="editing" :editingEventId="editingEventId" :eventWithUuid="eventWithUuid" :intervalLabels="intervalLabels" @eventSaved="handleEventSaved" @modalHidden="handleModalHidden" />
        <!-- Import Events Modal -->
        <div class="modal fade" id="import-tribe-events" tabindex="0" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header tw-red-bg-color text-white">
                        <div class="d-flex">
                            <span class="tw-create-modal-icon d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                                <i class="fas fa-plus"></i>
                            </span>
                            <span class="d-inline-flex">
                                <div class="row mt-3">
                                    <h5 class="modal-title col-12" id="modal-title">Import tribe events</h5>
                                    <p class="col-12"></p>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="importTribeEvents">
                            <div class="row p-3">
                                <div class="col-6 mb-3">
                                    <label>Start Date</label>
                                    <label id="tw-import-start-date" class="tw-create-modal-schedule p-2 w-100 cursor-pointer position-relative border-1">
                                        <Datepicker
                                            class="tw-create-modal-date-recurrence-end position-absolute w-100"
                                            menuClassName="tw-create-event-modal-date-recurrence-end"
                                            teleport="#tw-import-start-date"
                                            inputClassName="border-0 bg-transparent"
                                            :format="formatDate"
                                            v-model="importStartDate"
                                            placeholder="Start date"
                                            weekStart="0"
                                        />
                                    </label>
                                </div>
                                <div class="col-6 mb-3">
                                    <label>End Date</label>
                                    <label id="tw-import-end-date" class="tw-create-modal-schedule p-2 w-100 cursor-pointer position-relative border-1">
                                        <Datepicker
                                            class="tw-create-modal-date-recurrence-end position-absolute w-100"
                                            menuClassName="tw-create-event-modal-date-recurrence-end"
                                            teleport="#tw-import-end-date"
                                            inputClassName="border-0 bg-transparent"
                                            :format="formatDate"
                                            v-model="importEndDate"
                                            placeholder="End date"
                                            weekStart="0"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div v-if="importStarted" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
                                    <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                                </div>
                            </div>
                            <div class="row p-3">
                                <div class="col-12 my-3">
                                    <button class="tw-dark-grey-bg-color text-white py-2 px-3 rounded-pill border-0 cursor-pointer fs-5 float-end" type="button" @click="closeImportEventsModal">Cancel</button>
                                    <button class="tw-red-bg-color text-white py-2 px-3 rounded-pill border-0" type="submit">
                                        <i class="fas fa-upload"></i>
                                        <span class="fs-5 ps-2">Import</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import wordpressMixin from "@/mixins/wordpressMixin";
import helperMixin from "@/mixins/helperMixin";
import siteMixin from "@/mixins/siteMixin";
import heyGovMixin from "@/mixins/heyGovMixin";
import userMixin from "@/mixins/userMixin";
import Pagination from "@/components/pagination/Pagination";
import Calendar from "@/components/events-components/Calendar";
import CreateEventModal from "@/components/modals/CreateEventModal";
import { Tooltip, Modal } from "bootstrap";

export default {
    name: "Events",
    components: {
        Pagination,
        Calendar,
        CreateEventModal,
    },
    data: (vm) => ({
        events: [],
        numberOfShowedEvents: 0,
        numberOfEvents: 0,
        numberOfPages: 0,
        querySearch: "",
        eventsView: "list",
        requestResolved: false,
        page: 1,
        perPage: 10,
        order: "desc",
        orderBy: "start_date",
        selected: 0,
        intervalLabels: {},
        editing: false,
        editingEventId: null,
        format: "MMMM Y",
        filterDate: vm.moment(),
        jurisdiction: null,
        hgCategories: [],
        eventWithUuid: null,
        isStuff: false,
        headers: null,
        wpBasicHeaders: null,
        eventEdit: null,
        eventLocation: null,
        eventLocationModal: null,
        location: null,
        importStartDate: vm.moment().subtract(1, "years"),
        importEndDate: vm.moment().add(1, "years"),
        formatDate: "LLL dd, y",
        importStarted: false,
        importedEvents: [],
    }),
    mounted() {
        let user = this.getCurrentUser();
        this.headers = { Authorization: "Bearer " + user.auth, "Content-Type": "application/json" };
        this.wpBasicHeaders = { Authorization: "Basic " + user.auth, "Content-Type": "application/json" };
        if (this.$route.hash) {
            this.$refs.CreateEventModal.showModal(true);
        }

        this.setIntervalLabels();
        this.getHeyGovJurisdiction();
        this.isStuff = this.getIsStuff();
        this.$modalImportEvents = new Modal(document.getElementById("import-tribe-events"));
    },
    updated() {
        let tooltopElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

        tooltopElements.forEach((element) => {
            let tooltip = Tooltip.getInstance(element);

            if (tooltip) {
                tooltip.dispose();
            }

            if (!Tooltip.getInstance(element)) {
                new Tooltip(element);
            }
        });
    },
    watch: {
        perPage: function () {
            // this.fullName = val + ' ' + this.lastName
            // this.posts = [];
            this.page = 1;
            this.setEvents();
        },
        page: function () {
            // this.fullName = val + ' ' + this.lastName
            // this.posts = [];
            this.setEvents();
        },
        orderBy: function () {
            // this.fullName = val + ' ' + this.lastName
            // this.posts = [];
            this.setEvents();
        },
        order: function () {
            // this.fullName = val + ' ' + this.lastName
            // this.posts = [];
            this.setEvents();
        },
        querySearch: function () {
            // this.fullName = val + ' ' + this.lastName
            // this.posts = [];
            this.page = 1;
            this.setEvents();
        },
        filterDate: function (val) {
            // this.fullName = val + ' ' + this.lastName
            // this.posts = [];
            if (!val) {
                this.filterDate = this.moment();
            }
            this.page = 1;
            this.setEvents();
        },
        eventsView: function (val) {
            if (val == "list") {
                this.perPage = 10;
            } else {
                this.perPage = 100;
            }
        },
    },
    methods: {
        async getHeyGovEventUuid(uuid) {
            await this.getHeyGovEvent(uuid);
        },
        async setEvents() {
            this.requestResolved = false;

            let startDate = this.moment(this.filterDate).startOf("month").format("Y-MM-DDTHH:mm:ss");
            let endDate = this.moment(this.filterDate).endOf("month").format("Y-MM-DDTHH:mm:ss");

            if (this.eventsView == "calendar") {
                startDate = this.moment(this.filterDate).startOf("month").startOf("week").format("Y-MM-DDTHH:mm:ss");
                endDate = this.moment(this.filterDate).endOf("month").endOf("week").format("Y-MM-DDTHH:mm:ss");
            }

            if (!this.hasHeyGovEvents) {
                let res = await this.getEvents(this.page, this.perPage, this.order, this.orderBy, this.querySearch, startDate, endDate);

                if (res && res.status === 200) {
                    this.requestResolved = true;
                    this.events = res.data.events;
                    this.numberOfShowedEvents = this.events.length;
                    this.numberOfEvents = res.data.total;
                    this.numberOfPages = res.data.total_pages;
                }
            } else {
                if (this.orderBy === "start_date") {
                    this.orderBy = "starts_at_local";
                } else if (this.orderBy === "title") {
                    this.orderBy = "name";
                }

                const filters = {
                    date_start: startDate,
                    date_end: endDate,
                    q: this.querySearch,
                };
                const sort = {
                    order: this.order,
                    orderBy: this.orderBy,
                };
                const params = {
                    ...filters,
                    ...sort,
                    limit: this.perPage,
                    page: this.page,
                    // status: "public",
                };

                let res = await this.axios.get(`${this.apiRoute}/${this.domain}/events?expand=parent&apiKey=${this.apiKey}`, { params });

                if (res.status && res.status === 200) {
                    this.requestResolved = true;
                    this.events = res.data;
                    this.events.map((e) => {
                        e.selected = false;
                        if (this.getTime(e.starts_at) === "12:00 AM" && this.getTime(e.ends_at) === "11:59 PM") {
                            e.all_day = true;
                        } else {
                            e.all_day = false;
                        }
                    });

                    this.numberOfEvents = parseInt(res.headers["x-total"]);
                    this.numberOfPages = Math.ceil(this.numberOfEvents / this.perPage);
                    this.numberOfShowedEvents = this.events.length;

                    if (this.events.length == 0) {
                        this.loadEvents();
                    }
                }
            }
        },
        setIntervalLabels() {
            this.intervalLabels = this.getIntervalLabels();
        },
        setPage(n) {
            this.page = n;
        },
        async removeEvent(event) {
            let options = {
                title: "Are you sure?",
                showCancelButton: true,
                icon: "warning",
                text: "Are you sure you want to delete this event?",
                confirmButtonText: "Yes, delete it!",
                showLoaderOnConfirm: true,
                preConfirm: async (data) => {
                    try {
                        if (this.hasHeyGovEvents) {
                            if (data === 1) {
                                let id = event.parent[0]?.pid ?? event.parent[0].id;
                                if (event.type === "recurrent") {
                                    id = {
                                        id: event.id,
                                    };
                                }
                                return await this.deleteHeyGovEvent({ id });
                            } else {
                                return await this.deleteHeyGovEvent(event);
                            }
                        } else {
                            return await this.deleteEvent(event.id);
                        }
                    } catch (error) {
                        this.$swal.showValidationMessage(`Request failed: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            };

            // Setting different options for

            if (this.hasHeyGovEvents) {
                if (event.type == "recurrence") {
                    options = {
                        ...options,
                        input: "checkbox",
                        inputValue: 0,
                        inputPlaceholder: "Delete full recurrence ⚠️",
                        footer: '<small class="text-muted">Child event</small>',
                    };
                } else if (event.type === "recurrent") {
                    options = {
                        ...options,
                        footer: '<small class="text-muted">Parent event: </i>Deleting this event will delete full recurrence ⚠️ </small>',
                    };
                } else {
                    options = {
                        ...options,
                        footer: '<small class="text-muted">Single event</small>',
                    };
                }
            }
            this.$swal(options).then(async (result) => {
                if (result.isConfirmed) {
                    this.$swal("Deleted!", "Event has been deleted!", "success").then(() => {
                        this.requestResolved = false;

                        this.setEvents();
                    });
                }
            });
        },
        async deleteHeyGovEvent(event) {
            let res = await this.axios.delete(`${this.apiRoute}/${this.domain}/events/${event.id}?apiKey=${this.apiKey}`);
            if (res && (res.status === 200 || res.status === 202)) {
                if (this.siteType == "cms6") {
                    this.axios.post(
                        process.env.VUE_APP_JUNIPER_API + `clients/regenerate/events`,
                        {},
                        {
                            //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                            headers: this.headers,
                        }
                    );
                } else if (this.siteType === "wordpress") {
                    this.heyGovEventsClearCache();
                }
            }
        },
        async bulkDelete() {
            this.$swal({
                title: "Are you sure?",
                showCancelButton: true,
                icon: "warning",
                text: "Are you sure you want to delete all selected events?",
                confirmButtonText: "Yes, delete!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.requestResolved = false;

                    let self = this;
                    let events = this.events;

                    if (this.hasHeyGovEvents) {
                        let result = await Promise.all(
                            events.map(async (event) => {
                                if (event.selected) {
                                    let res = await this.axios.delete(`${this.apiRoute}/${this.domain}/events/${event?.uuid ?? event?.id}?apiKey=${this.apiKey}`, { status: "deleted" });
                                    if (res && res.status >= 200) {
                                        console.log(res.data);
                                    }
                                }
                            })
                        );
                        if (result) {
                            this.heyGovEventsClearCache();
                        }
                    } else {
                        await Promise.all(
                            events.map(async function (event) {
                                if (event.selected) {
                                    await self.deleteEvent(event.id);
                                }
                            })
                        );
                    }
                    this.events = [];
                    this.selected = 0;
                    this.setEvents();
                    this.$swal("Deleted!", "Events have been deleted!", "success");
                }
            });
        },
        setOrder(orderBy) {
            if (this.hasHeyGovEvents) {
                if (orderBy === "start_date") {
                    orderBy = "starts_at_local";
                } else if (orderBy === "title") {
                    orderBy = "name";
                }
            }
            if (this.orderBy != orderBy) {
                this.order = "desc";
            } else if (this.orderBy == orderBy && this.order == "desc") {
                this.order = "asc";
            } else if (this.orderBy == orderBy && this.order == "asc") {
                this.order = "desc";
            }
            this.orderBy = orderBy;
        },
        countSelected(index, event) {
            if (event.target.checked) {
                this.events[index].selected = true;
            } else {
                this.events[index].selected = false;
            }

            this.selected = this.events.filter(function (post) {
                return post.selected;
            }).length;
        },
        openCreateEventModal(event) {
            if (event.id) {
                this.editing = true;
                this.editingEventId = event.id;
            }
            if (this.hasHeyGovEvents) {
                this.eventWithUuid = event;
            }

            this.$refs.CreateEventModal.showModal(true);
        },
        handleModalHidden() {
            this.editing = false;
            this.editingEventId = null;
            if (this.hasHeyGovEvents) {
                this.eventWithUuid = null;
            }
        },
        handleEventSaved() {
            this.$swal("Event saved!", "Event saved successfully!", "success").then(() => {
                this.requestResolved = false;
                // this.posts = [];
                this.getEventCategories();
                this.setEvents();
            });
        },
        changeView(view) {
            // this.setEvents();
            this.eventsView = view;
        },
        handleMonthChanged(month) {
            // console.log('radi kao nesto')
            // console.log(month.months())
            // console.log(this.filterDate.months)
            // this.filterDate = month;
            this.filterDate = {
                month: month.month(),
                year: month.year(),
            };
            // this.$refs.filterDate.$forceUpdate()
        },
        async getHeyGovJurisdiction() {
            this.jurisdiction = await this.heyGovJurisdiction();
            if (this.jurisdiction) {
                await this.getEventCategories();
            } else {
                this.setEvents();
            }
        },
        async getEventCategories() {
            if (this.jurisdiction) {
                let res = await this.axios.get(`${this.apiRoute}/${this.jurisdiction.slug}/events/categories`);
                if (res.data) {
                    this.hgCategories = res.data;
                    await this.setEvents();
                }
            }
        },
        getTime(time) {
            return new Date(time).toLocaleTimeString("en-US", {
                timeZone: this.jurisdiction.timezone,
                hour: "numeric",
                minute: "numeric",
            });
        },
        getDate(date) {
            return new Date(date).toLocaleDateString("en-US", {
                timeZone: this.jurisdiction.timezone,
            });
        },
        async turnOnEventLocation(event) {
            let uuid;
            if (event.type === "single" || event.type === "recurrent") {
                uuid = event?.uuid ?? event?.id;
            } else {
                uuid = event.parent[0].uuid;
            }
            let metadata = {
                ...event.metadata,
                hide_location: false,
            };
            let res = await this.axios.put(`${this.apiRoute}/${this.domain}/events/${uuid}?apiKey=${this.apiKey}`, { metadata });
            if (res && res.status == 200) {
                this.setEvents();
            }
        },
        openImportEventsModal() {
            this.$modalImportEvents.show();
        },
        closeImportEventsModal() {
            this.$modalImportEvents.hide();
        },
        importTribeEvents() {
            // import tribe events
            if (typeof this.importStartDate == "object") {
                this.importStartDate = this.moment(this.importStartDate).format("Y-MM-DDTHH:mm:ss");
            }
            if (typeof this.importEndDate == "object") {
                this.importEndDate = this.moment(this.importEndDate).format("Y-MM-DDTHH:mm:ss");
            }

            let params = {
                start_date: this.importStartDate || "",
                end_date: this.importEndDate || "",
            };

            this.importStarted = true;

            this.axios.get(`${this.apiRoute}/${this.domain}/events/import-tribe-events?apiKey=${this.apiKey}`, { params }).then((data) => {
                if (data.status === 200) {
                    this.$modalImportEvents.hide();
                    this.importStarted = false;
                    if (data.data.length == 0) {
                        return this.$swal("No tribe events imported", "Try changing import date range");
                    }
                    this.getEventCategories();
                    this.setEvents();
                }
            });
        },
        importEventCategories() {
            // import-tribe-categories
            this.importStarted = true;
            this.axios.get(`${this.apiRoute}/${this.domain}/events/import-tribe-categories?apiKey=${this.apiKey}`).then((data) => {
                if (data.status === 200) {
                    this.importStarted = false;
                    if (data.data.length == 0) {
                        return this.$swal("No tribe categories imported!", "Check tribe events categories data in wp-admin");
                    }
                    this.handleCategoriesImported("Categories Imported", "Success!", "success");
                }
            });
        },
        findCategoryValue(cat, value) {
            let property;
            if (this.hgCategories.length) {
                for (let category of this.hgCategories) {
                    if (category.id === cat || category.uuid === cat) {
                        property = category[value];
                    }
                }
            }
            return property;
        },
        handleCategoriesImported(title, text, icon) {
            this.$swal({
                title: title,
                text: text,
                icon: icon,
            }).then(() => {
                this.getEventCategories();
                this.setEvents();
            });
        },
        async loadEvents() {
            let res = await this.axios.get(`${this.apiRoute}/${this.domain}/events?limit=10`);
            if (res && res.status == 200) {
                this.importedEvents = res.data;
            }
        },
        heyGovEventsClearCache() {
            this.axios.get(this.siteUrl + `/wp-json/tw/v1/events-transient-wprocketcache-clear`, {
                headers: this.wpBasicHeaders,
            });
        },
    },
    computed: {
        siteType() {
            return this.getSiteType();
        },
        siteUrl() {
            return this.getSiteUrl();
        },
    },
    mixins: [wordpressMixin, helperMixin, heyGovMixin, userMixin, siteMixin],
};
</script>

<style scoped>
#tw-events-view-wrapper button.active {
    color: #fff !important;
    background: var(--tw-red) !important;
}
</style>
