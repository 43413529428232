<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="element-wrapper" :style="styleWrapper" :class="element.id + 'wrap'" v-if="element.props" @dblclick.stop="onClick()">
        <div :id="element.id" :style="styleObject" class="carousel slide" :class="[{ 'carousel-fade': element.props.fadeAnimation }]" data-bs-ride="carousel">
            <div v-if="element.props.enableIndicators" class="carousel-indicators">
                <button type="button" :data-bs-target="'#' + element.id" :data-bs-slide-to="0" class="active" aria-current="true" :aria-label="element.props.items[0].alt"></button>
                <template v-for="(slide, index) in element.props.items" :key="index">
                    <button v-if="index > 0" type="button" :data-bs-target="'#' + element.id" :data-bs-slide-to="index" :aria-label="slide.alt"></button>
                </template>
            </div>
            <div class="carousel-inner">
                <template v-for="(slide, index) in element.props.items" :key="index">
                    <div class="carousel-item" :class="{ active: index === 0 }" :data-bs-interval="element.props.interval">
                        <div class="w-100 d-flex">
                            <template v-if="slide.type === 'text'">
                                <div class="w-100 text-content" :style="contentStyleObject">
                                    <div class="w-100">
                                        <h3 :style="hedingStyleObject">{{ slide.heading }}</h3>
                                        <div :style="textStyleObject" class="h-100" v-html="slide.text"></div>
                                    </div>
                                </div>
                            </template>

                            <template v-if="slide.type === 'text+image'">
                                <div class="w-50 text-content" :style="contentStyleObject">
                                    <div class="w-100">
                                        <h3 :style="hedingStyleObject">{{ slide.heading }}</h3>
                                        <div :style="textStyleObject" class="h-100" v-html="slide.text"></div>
                                    </div>
                                </div>
                                <div class="w-50 image-content">
                                    <img :style="imgStyleObject" v-if="slide.image !== ''" :src="slide.image" alt="" class="w-100" />
                                    <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 150px">Add your image in properties</div>
                                </div>
                            </template>

                            <template v-if="slide.type === 'text+video'">
                                <div class="w-50 text-content" :style="contentStyleObject">
                                    <div class="w-100">
                                        <h3 :style="hedingStyleObject">{{ slide.heading }}</h3>
                                        <div :style="textStyleObject" class="h-100" v-html="slide.text"></div>
                                    </div>
                                </div>
                                <div class="w-50 video-content">
                                    <div v-if="slide.video !== ''" v-html="slide.video"></div>
                                    <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 150px">Add your embeded code in properties</div>
                                </div>
                            </template>

                            <template v-if="slide.type === 'image+text'">
                                <div class="w-50 image-content">
                                    <img :style="imgStyleObject" v-if="slide.image !== ''" :src="slide.image" alt="" class="w-100" />
                                    <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 150px">Add your image in properties</div>
                                </div>
                                <div class="w-50 text-content" :style="contentStyleObject">
                                    <div class="w-100">
                                        <h3 :style="hedingStyleObject">{{ slide.heading }}</h3>
                                        <div :style="textStyleObject" class="h-100" v-html="slide.text"></div>
                                    </div>
                                </div>
                            </template>

                            <template v-if="slide.type === 'video+text'">
                                <div class="w-50 video-content">
                                    <div v-if="slide.video !== ''" v-html="slide.video"></div>
                                    <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 150px">Add your embeded code in properties</div>
                                </div>
                                <div class="w-50 text-content" :style="contentStyleObject">
                                    <div class="w-100">
                                        <h3 :style="hedingStyleObject">{{ slide.heading }}</h3>
                                        <div :style="textStyleObject" class="h-100" v-html="slide.text"></div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>

            <template v-if="element.props.enableControlButtons">
                <!-- Controls -->
                <button class="carousel-control-prev" type="button" :data-bs-target="'#' + element.id" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" :data-bs-target="'#' + element.id" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
                <!-- #Controls -->
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: "ContentSlider",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
        getImageSrc(src) {
            return src;
        },
        checkInputValue(value) {
            if (value && value != "0px" && value != "0%" && value != "0rem") {
                return true;
            } else {
                return false;
            }
        },

        checkTwoValuesReturn(first, second) {
            if (this.checkInputValue(first)) {
                return first;
            } else if (this.checkInputValue(second)) {
                return second;
            } else {
                return "auto";
            }
        },
    },
    computed: {
        styleWrapper() {
            return {
                textAlign: this.element.style.textAlign,
            };
        },
        styleObject() {
            return {
                width: this.checkTwoValuesReturn(this.element.style.fitWidth, this.element.style.width),
                height: this.element.style.fitHeight ? this.element.style.fitHeight : "auto",
                padding: this.element.style.padding,
                display: "inline-block",
            };
        },
        contentStyleObject() {
            return {
                backgroundColor: this.element.style.backgroundColor,
                padding: this.element.style.contentPadding,
                margin: this.element.style.contentMargin,
                display: "flex",
                alignItems: this.element.style.textVerticalPosition ? this.element.style.textVerticalPosition : "top",
                textAlign: this.element.style.textTextAlign ? this.element.style.textTextAlign : "left",
                width: "100%",
            };
        },
        imgStyleObject() {
            return {
                width: this.checkInputValue(this.element.style.fitWidth) ? this.element.style.fitWidth : "auto",
                height: this.checkInputValue(this.element.style.fitHeight) ? this.element.style.fitHeight : "auto",
                borderRadius: this.element.style.imageBorderRadius,
                objectFit: "cover",
            };
        },
        hedingStyleObject() {
            return {
                color: this.element.style.titleColor,
            };
        },
        textStyleObject() {
            return {
                color: this.element.style.textColor,
            };
        },
    },
    emits: ["onClick"],
};
</script>
<style scoped>
.text-item {
    min-height: 50px;
}
</style>
