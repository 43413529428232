<template>
	<section id="tw-view-subscribers" class="tw-content col-12">
		<div class="bg-white">
			<div class="p-4">
				<div class="tw-table-header row">
					<div class="tw-table-search-wrapper col-lg-4 text-end">
                        <input type="search" class="form-control" v-model="querySearch" placeholder="Search subscribers" />
                        <span class="tw-table-header-search-icon"><i class="fas fa-search"></i></span>
                    </div>
                    <div class="col-1 mt-3 mt-lg-0">
						<button ref="downloadBtn" class="tw-subscribers-download-button tw-green-bg-color" @click="download" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to download subscriber list.">
							<span v-show="downloading"><i class="fa fa-spinner fa-pulse"></i></span>
							<span v-show="!downloading"><i class="fas fa-download"></i></span>
						</button>
                    </div>
					<div class="col text-end mt-3 mt-lg-0 d-flex">
						<button class="tw-default-bg-color text-white d-block d-lg-flex align-items-center ms-auto justify-content-center px-3 me-2 me-xl-0" v-if="selected" @click.prevent="bulkDelete">
							<i class="fa fa-trash"></i>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1">Delete {{ selected }} Subscribers</span>
						</button>
						<button class="tw-red-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0" @click="openCreateSubscriberModal">
							<span class="d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
								<i class="fas fa-plus"></i>
							</span>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1 pe-5">Add Subscriber</span>
						</button>
					</div>
				</div>
				<div class="table-responsive">
					<table ng-if="!vm.permissions.notices" st-table="vm.results" st-pipe="vm.loadResults" class="tw-table table text-nowrap">
						<thead>
							<tr>
								<th class="tw-table-checkbox-th"></th>
								<th st-sort="title">
									<span class="cursor-pointer d-flex align-items-center" @click="setOrder('title')">
										<span class="me-3">Name</span>
										<span v-if="orderBy != 'title'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up me-2">
												<span class="d-block"></span>
											</span>
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'title' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'title' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
									</span>
								</th>
								<th>Notifications</th>
								<th style="min-width: 300px;">News & Notices</th>
								<th style="min-width: 300px;">Agendas</th>
								<th st-sort="date">
									<span class="cursor-pointer d-flex align-items-center" @click="setOrder('date')">
										<span class="me-3">Date</span>
										<span v-if="orderBy != 'date'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up me-2">
												<span class="d-block"></span>
											</span>
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'date' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'date' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
									</span>
								</th>
								<th></th>
							</tr>
						</thead>

						<tbody class="position-relative">
							<div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
								<i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
							</div>
						<!-- <tbody v-if="posts && posts.length"> -->
							<tr v-for="(subscriber, index) in subscribers" :key="index">
								<td class="tw-checkbox"><input :id="'subscriber' + subscriber.id" type="checkbox" @click="countSelected(index, $event)" value="1" :checked="subscriber.checked"/><label :for="'subscriber' + subscriber.id"></label></td>
								<td class="text-wrap">
									<a class="tw-table-title-column" href="" @click.stop.prevent="openCreateSubscriberModal($event, subscriber.id)" v-html="subscriber.name"></a> <small v-if="subscriber.status != 'publish' && subscriber.type == 'twd_subscribe'" class="bg-info text-white px-1 rounded text-capitalize">{{ subscriber.status }}</small>
								</td>
								<td>
									<div class="d-flex align-items-center">
										<span v-if="subscriber.receive_email == 'on'"><i class="fas fa-envelope"></i></span>
										<span v-if="subscriber.receive_text_message == 'on'" class="ms-2 mt-1"><i class="far fa-comment-alt"></i></span>
									</div>
								</td>
								<td class="text-wrap">
									<span v-if="subscriber.post_term_names.length">
										<i class="far fa-check-square"></i> -
									</span>
									<span v-for="(term, index) in subscriber.post_term_names" :key="index"><span v-html="term"></span><span v-if="(index + 1) != subscriber.post_term_names.length">, </span> </span>
								</td>
								<td class="text-wrap">
									<span v-if="subscriber.agenda_term_names.length">
										<i class="far fa-check-square"></i> -
									</span>
									<span v-for="(term, index) in subscriber.agenda_term_names" :key="index"><span v-html="term"></span><span v-if="(index + 1) != subscriber.agenda_term_names.length">, </span> </span>
								</td>
								<td>{{ moment(subscriber.post_date || subscriber.date).format('ll') }}</td>
								<td class="text-end column-actions">
									<div class="d-flex justify-content-end">
										<a href="" @click.prevent="removeSubscriber(subscriber)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span></a>
										<a href="" @click.stop.prevent="openCreateSubscriberModal($event, subscriber.id)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span></a>
									</div>
								</td>
							</tr>
						</tbody>

						<tbody v-if="requestResolved && (!subscribers || !subscribers.length)">
							<tr>
								<td colspan="6" class="text-center">Nothing to show, no results found.</td>
							</tr>
						</tbody>

						<tfoot v-if="subscribers.length">
							<tr>
								<td colspan="3">
									Rows per page
									<select v-model="perPage" class="border">
										<option :value="5">5</option>
										<option :value="10">10</option>
										<option :value="20">20</option>
									</select>
								</td>
								<td colspan="4" class="tw-table-pagination text-end">

									<Pagination
										:totalItems="numberOfSubscribers"
										:currentPage="page"
										:perPage="perPage"
										@pageChanged="setPage"
										:goButton="false"
										styled="centered"
										borderActiveColor=""
										borderTextActiveColor="text-white"
									/>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
		<CreateSubscriberModal ref="CreateSubscriberModal" :editing="editing" :editingSubscriberId="editingSubscriberId" @subscriberSaved="handleSubscriberSaved" @modalHidden="handleModalHidden" />
	</section>
</template>

<script>
	import wordpressMixin from '@/mixins/wordpressMixin';
	import cms6Mixin from '@/mixins/cms6Mixin';
	import siteMixin from '@/mixins/siteMixin';
	import Pagination from '@/components/pagination/Pagination'
	import CreateSubscriberModal from '@/components/modals/CreateSubscriberModal';
	import helperMixin from '@/mixins/helperMixin';
	import { Tooltip } from "bootstrap";

	let site = localStorage.getObject('site');

	let mixins;

	if (site.type == 'wordpress') {
		mixins = [wordpressMixin, helperMixin, siteMixin];
	} else if(site.type == 'cms6') {
		mixins = [cms6Mixin, helperMixin, siteMixin];
	}

	export default {
		name: 'Subscribers',
		components: {
			Pagination,
			CreateSubscriberModal,
		},
		data: () => ({
			subscribers: [],
			numberOfShowedSubscribers: 0,
			numberOfSubscribers: 0,
			querySearch: '',
			filterDate: null,
			format: 'MMMM Y',
			requestResolved: false,
			page: 1,
			perPage: 10,
			order: 'desc',
			orderBy: 'date',
			selected: 0,
			// tableColumns: [{title:'Picture'},{title:'Title'},{title:'Category'},{title:'Date'},{title:'Notifications'}],
			defaultImage: '<div class="tw-defaul-featured-img"><i class="fas fa-image"></i></div>',
			// defaultImage: 'https://via.placeholder.com/35x35?text=Photo',
			editing: false,
			editingSubscriberId: null,
			downloading: false
		}),
		mounted() {
			if (this.$route.hash) {
				this.$refs.CreateSubscriberModal.showModal(true);
			}
			this.setSubscribers();

			if (!Tooltip.getInstance(this.$refs.downloadBtn)) {
				new Tooltip(this.$refs.downloadBtn)
			}
		},
		watch: {
			perPage: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.page = 1;
				this.setSubscribers();
			},
			page: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setSubscribers();
			},
			orderBy: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setSubscribers();
			},
			order: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setSubscribers();
			},
			querySearch: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setSubscribers();
			},
			filterDate: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setSubscribers();
			},
		},
		methods: {
			async setSubscribers() {
				this.requestResolved = false;

				this.$el.querySelectorAll("td.tw-checkbox input").forEach( function(checkbox){
					checkbox.checked = false;
				});
				this.selected = 0;

				let res = await this.getSubscribers(this.page, this.perPage, this.order, this.orderBy, this.querySearch, this.filterDate);
				if (res && res.status === 200) {
					this.requestResolved = true;
					this.subscribers = res.data || [];
					this.numberOfShowedPosts = this.subscribers.length;
					this.numberOfSubscribers = parseInt( res.headers['x-wp-total'] );
				}
			},
			setPage(n) {
				this.page = n;
			},
			async removeSubscriber(subscriber) {
				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete this subscriber?",
                    confirmButtonText: 'Yes, delete it!',
                    showLoaderOnConfirm: true,
					preConfirm: async () => {
						try {

							return await this.deleteSubscriber(subscriber.id);

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then(async (result) => {
					if (result.isConfirmed) {

						this.$swal(
							'Deleted!',
							"Subscriber has been deleted!",
							'success',
						)
						.then(() => {
							this.requestResolved = false;

							this.setSubscribers();
						});
					}
                });
			},
			async bulkDelete () {


				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete all selected subscribers?",
                    confirmButtonText: 'Yes, delete!'
                }).then(async (result) => {

					if (result.isConfirmed) {
						this.requestResolved = false;

						let self = this;
						let subscribers = this.subscribers;

						await Promise.all(subscribers.map( async function(subscriber){

							if (subscriber.selected) {
								await self.deleteSubscriber(subscriber.id);
							}

						}));

						this.setSubscribers();

						this.$swal(
							'Deleted!',
							"Subscriber have been deleted!",
							'success',
						);

					}
                });
			},
			setOrder(orderBy) {
				if (this.orderBy != orderBy) {
					this.order = 'desc';
				} else if (this.orderBy == orderBy && this.order == 'desc') {
					this.order = 'asc';
				} else if (this.orderBy == orderBy && this.order == 'asc') {
					this.order = 'desc';
				}
				this.orderBy = orderBy;
			},
			countSelected(index, event) {

				if (event.target.checked) {
					this.subscribers[index].selected = true;
				} else {
					this.subscribers[index].selected = false;
				}

				this.selected = this.subscribers.filter(function(subscriber) {return subscriber.selected}).length;
			},
			openCreateSubscriberModal(event, subscriberId = null) {
				if (subscriberId) {this.editing = true;}
				this.editingSubscriberId = subscriberId;
				this.$refs.CreateSubscriberModal.showModal(true);
			},
			handleModalHidden() {
				this.editing = false;
				this.editingSubscriberId = null;
			},
			handleSubscriberSaved(action) {
				this.$swal(
					`Subscriber ${action}!`,
					`Subscriber ${action} successfully!`,
					'success',
				)
				.then(() => {

					this.requestResolved = false;
					// this.posts = [];
					this.setSubscribers();
				});
			},
			async download() {

				this.downloading = true;

				let res = await this.downloadSubscribersList();

				if (res.status === 201 || res.status === 200) {
					const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);

					let site = this.getSite();
					let domain = this.getDomain(site.url);


					link.download = domain + '_subscribers-' + this.moment().format('YMMDDHHmmss') + '.xlsx'
					link.click();
					URL.revokeObjectURL(link.href);

					this.downloading = false;

				}
			},
		},
		mixins: mixins
    }
</script>
<style scoped>
	.tw-subscribers-download-button {
		height: 45px;
		width: 45px;
	}
</style>
