<template>
	<section id="tw-view-emails" class="flex-grow-1 d-flex flex-column">
		<div class="bg-white tw-account-settings-section d-flex flex-column flex-grow-1">
			<div class="p-3 d-flex flex-column flex-grow-1">
				<h3 class="fw-bold mb-4">Emails <small v-if="total" class="fw-normal"><i>Total</i> {{ total }}</small></h3>
				<div class="row d-flex flex-column flex-grow-1">
					<div class="col-12 position-relative d-flex flex-column flex-grow-1">
						<div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
							<i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
						</div>
						<div v-if="emails.length" class="tw-scrollable-table-wrapp">
							<table class="table table-bordered">
								<thead>
									<th class="header bg-white" scope="col">Email</th>
									<th class="header bg-white" scope="col">Name</th>
								</thead>
								<tbody>
									<tr v-for="(email, index) in emails" :key="index">
										<td>
											{{ email.email }}
										</td>
										<td>
											{{ email.name }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="!emails.length && requestResolved" class="position-absolute top-0 end-0 bottom-0 start-0 d-flex align-items-center justify-content-center flex-column p-3">
							<h4><i class="fas fa-lock"></i> Locked</h4>
							<p><small>Locked for yor current account. You need to upgrade account to get this functioning.</small></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import accountSettingsMixin from '@/mixins/accountSettingsMixin';
	export default {
		name: 'Emails',
		components: {

		},
		data: () => ({
			emails: [],
			total: 0,
			requestResolved: false,
		}),
		mounted() {
			this.setEmails();
		},
		methods: {
			async setEmails() {
				let res = await this.getEmails();

				if (res && res.emails && res.total) {
					this.emails = res.emails;
					this.total = res.total;
				}
				this.requestResolved = true;
			}
		},
		mixins: [accountSettingsMixin]
    }
</script>

<style scoped>
	small {
		font-size: 12px;
	}
	table {
		border-collapse: separate; /* Don't collapse */
		border-spacing: 0;
	}
	.table>:not(:first-child) {
		/*border-top: none;*/
	}
	table td {
		border-bottom: 1px solid #dee2e6;
	}
	.tw-scrollable-table-wrapp {
		max-height: 400px;
		overflow: auto;
		/*display:inline-block;*/
	}
	.header{
		padding-top: 10px;
		padding-bottom: 10px;
        position:sticky;
        top: 0 ;
    }
</style>