import { cuPlugin } from "@/tinymce/plugins/myCustomToolbarButton/plugin.js";

const config = {
    height: "100%",
    toolbar_location: "top",
    content_style: "body#tinymce { margin-bottom: 50px; }",
    menubar: false,
    plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen typography", "insertdatetime media table paste code help wordcount", "myCustomToolbarButton"],
    contextmenu: false,
    toolbar:
        "undo redo | formatselect | bold italic underline | \
        alignleft aligncenter alignright alignjustify | fontselect | \
        numlist bullist | forecolor backcolor | link myCustomToolbarButton media image | removeformat | code",
    toolbar2:
        "undo redo | formatselect | bold italic underline | \
        alignleft aligncenter alignright alignjustify |  fontselect | \
        numlist bullist | forecolor backcolor | link myCustomToolbarButton media image | removeformat | code",
    font_formats:
        "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;",
    setup: function () {
        window.tinymce.PluginManager.add("myCustomToolbarButton", cuPlugin);
    },
};

export default { config };
