<template>
	<section id="tw-view-subscription-info" class="flex-grow-1 d-flex flex-column">
		<div class="bg-white tw-account-settings-section flex-grow-1">
			<div class="p-3">
				<h3 class="fw-bold mb-4">Contract Info</h3>
				<div class="row">
					<div class="col-md-6">
						<h4 class="fw-bold mb-3">YOUR PLAN</h4>
						<div id="tw-plan-details-wrapper" class="p-3 tw-font-size-18">
							<h3 class="fw-bold">{{ subscription.package }}</h3>
							<small>{{ subscription.contract_type }}</small>
							<div v-if="requestResolved">
								<!-- Town Web -->
								<div class="p-2 mt-2 fw-bold d-flex align-items-center justify-content-between" :class="tw_client ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Town Web</span><i :class="tw_client ? 'fa-check' : 'fa-lock'" class="fas"></i>
								</div>
								<!-- HeyGov -->
								<div class="p-2 mt-2 fw-bold d-flex align-items-center justify-content-between" :class="hg_client ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>HeyGov</span><i :class="hg_client ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
								<!-- Live Streaming -->
								<div class="p-2 mt-2 fw-bold d-flex align-items-center justify-content-between" :class="live_stream ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Live Streaming</span><i :class="live_stream ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
								<!-- Hosting -->
								<div class="p-2 mt-2 fw-bold d-flex align-items-center justify-content-between" :class="hosting ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Hosting</span><i :class="hosting ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
								<!-- Facebook Syndication -->
								<div class="p-2 mt-2 fw-bold d-flex align-items-center justify-content-between" :class="facebook_syndication ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Facebook Sync</span><i :class="facebook_syndication ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
								<!-- Branded Emails -->
								<div class="p-2 mt-2 fw-bold d-flex align-items-center justify-content-between" :class="branded_emails ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Branded Emails</span><i :class="branded_emails ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
							</div>
						</div>
						<div class="mt-5 d-flex justify-content-center">
							<!-- link to https://www.townweb.com/upgrade -->
							<a href="https://www.townweb.com/upgrade" class="text-decoration-none" target="_blank">
								<button class="tw-red-bg-color text-white d-flex align-items-center px-3 justify-content-center">
									Upgrade
								</button>
							</a>
						</div>
					</div>
					<div class="col-md-6 tw-font-size-18">
						<h4 class="fw-bold mb-3">CONTRACT EXPIRATION</h4>
						<div class="mb-5">
							<p>
								You are on the {{ subscription.package }} subscription<span v-if="this.dayLeft" class="d-inline"> and you have <b>{{ dayLeft }}</b> {{ dayLeft == 1 ? 'day' : 'days' }} until it expires</span>!
							</p>
						</div>
						<!-- <div class="fw-bold mb-3">
							{{ date }}
						</div>
						<div class="fw-bold">
							{{ cost }}
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import accountSettingsMixin from '@/mixins/accountSettingsMixin';
	export default {
		name: 'SubscriptionInfo',
		components: {

		},
		data: () => ({
			subscription: {},
			// subscriptionn: "Small Town",
			// daysLeft: 23,
			// tw_client: false,
			// hg_client: false,
			// live_stream: false,
			// hosting: false,
			// facebook_syndication: false,
			// branded_emails: false,
			requestResolved: false,
			date: "12/2/2022",
			cost: "$100",
		}),
		mounted() {
			this.setSubscription();
		},
		methods: {
			async setSubscription() {
				let res = await this.getSubscriptionInfo();

				this.subscription = await res;
				this.requestResolved = true;
				
			}
		},
		computed: {
			dayLeft() {
				if (this.subscription.contract_ends_at) {
					let eventdate = this.moment(this.subscription.contract_ends_at);
					// let eventdate = this.moment('2022-06-26');
					let todaysdate = this.moment();

                    return eventdate.diff(todaysdate, 'days');
                }
                return null;
			},
			tw_client() {
				return this.subscription.tw_client;
			},
			hg_client() {
				return this.subscription.hg_client;
			},
			live_stream() {
				console.log(this.subscription.live_stream)
				return ['yes,not implemented', 'yes,implemented'].includes(this.subscription.live_stream);
			},
			hosting() {
				return this.subscription.hosting == 'yes';
			},
			facebook_syndication() {
				return ['yes,not implemented', 'yes,implemented'].includes(this.subscription.facebook_syndication);
			},
			branded_emails() {
				return ['yes,not implemented', 'yes,implemented'].includes(this.subscription.branded_emails);
			}
		},
		mixins: [accountSettingsMixin]
    }
</script>
<style scoped>
	h4 {
		color: #C0C0C0;
	}
	#tw-plan-details-wrapper {
		box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.07);
	}
	small {
		font-size: 14px;
	}
	#tw-plan-details-wrapper > small + div > div {
		/*border: 1px solid var(--tw-light-green);*/
		border-radius: 9px;
	}
	.tw-package-item-active {
		border: 1px solid var(--tw-light-green);
		color: var(--tw-light-green);
	}
	.tw-package-item-inactive {
		border: 1px solid #D8D5D5;
		color: #D8D5D5;
	}
	button {
		height: 41px;
		font-size: 20px;
		border-radius: 4px;
		border: none;
		width: 153px;
	}
</style>