<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="sk-element-wrapper" :class="element.id + '-wrapper'" v-if="element.props" :style="styleWrapper" @dblclick.stop="onClick()">
        <div :id="element.id" :style="styleObject" class="carousel slide d-inline-block" :class="[{ 'carousel-fade': element.props.fadeAnimation }]" data-bs-ride="carousel">
            <div v-if="element.props.enableIndicators" class="carousel-indicators">
                <button type="button" :data-bs-target="'#' + element.id" :data-bs-slide-to="0" class="active" aria-current="true" :aria-label="element.props.items[0].title"></button>
                <template v-for="(slide, index) in element.props.items" :key="index">
                    <button v-if="index > 0" type="button" :data-bs-target="'#' + element.id" :data-bs-slide-to="index" :aria-label="slide.title"></button>
                </template>
            </div>
            <div class="carousel-inner">
                <template v-for="(slide, index) in element.props.items" :key="index">
                    <div class="carousel-item" :class="{ active: index === 0 }" :data-bs-interval="element.props.interval">
                        <img v-if="slide.image && slide.image !== '' && slide.image !== 'null'" :src="slide.image" class="" :style="imgStyleObject" alt="" />
                        <div v-else class="no-image">Select Slider Images</div>
                        <div v-if="(slide.title !== '' && slide.title !== 'null') || (slide.text !== '' && slide.text !== 'null')" class="carousel-caption d-none d-md-block">
                            <h5 v-if="slide.title !== ''" :style="sliderTitleStyleObject">{{ slide.title }}</h5>
                            <p v-if="slide.text !== ''" :style="sliderTextStyleObject">{{ slide.text }}</p>
                        </div>
                    </div>
                </template>
            </div>

            <template v-if="element.props.enableControlButtons">
                <!-- Controls -->
                <button class="carousel-control-prev" type="button" :data-bs-target="'#' + element.id" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" :data-bs-target="'#' + element.id" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
                <!-- #Controls -->
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: "Slider",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },

        checkInputValue(value) {
            if (value && value != "0px" && value != "0%" && value != "0rem") {
                return true;
            } else {
                return false;
            }
        },

        checkTwoValuesReturn(first, second) {
            if (this.checkInputValue(first)) {
                return first;
            } else if (this.checkInputValue(second)) {
                return second;
            } else {
                return "auto";
            }
        },
    },
    computed: {
        styleObject() {
            return {
                width: this.checkTwoValuesReturn(this.element.style.fitWidth, this.element.style.width),
                height: this.element.style.fitHeight ? this.element.style.fitHeight : "auto",
                float: this.element.style.float,
                backgroundColor: this.element.style.backgroundColor,
                color: this.element.style.color,
                padding: this.element.style.padding,
                margin: this.element.style.margin,
            };
        },
        styleWrapper() {
            return {
                textAlign: this.element.style.textAlign,
            };
        },
        imgStyleObject() {
            return {
                width: this.checkInputValue(this.element.style.fitWidth) ? this.element.style.fitWidth : "auto",
                height: this.checkInputValue(this.element.style.fitHeight) ? this.element.style.fitHeight : "auto",
                borderRadius: this.element.style.borderRadius,
                objectFit: "cover",
            };
        },
        sliderTitleStyleObject() {
            return {
                color: this.element.style.slideTitleColor ? this.element.style.slideTitleColor : "#ffffff",
            };
        },
        sliderTextStyleObject() {
            return {
                color: this.element.style.slideTextColor ? this.element.style.slideTextColor : "#ffffff",
            };
        },
    },
    emits: ["onClick"],
};
</script>
<style scoped>
.no-image {
    height: 300px;
    width: 100%;
    background-color: #f8f7f7;
    border-radius: 10px;
    color: #4e6078;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
