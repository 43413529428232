<template>
    <section id="tw-view-pages" class="tw-content col-12">
        <div class="bg-white">
            <div class="p-4">
                <div class="tw-table-header row">
                    <div class="tw-table-search-wrapper col-lg-8 text-end">
                        <input type="search" class="form-control" v-model="querySearch" placeholder="Search Pages" />
                        <span class="tw-table-header-search-icon"><i class="fas fa-search"></i></span>
                    </div>
                    <div v-if="siteType == 'cms6' && userHasCms6Permission('view_deleted_pages')" id="tw-pages-view-wrapper" class="col mt-3 mt-lg-0">
                        <div class="d-flex">
                            <div>
                                <button class="tw-light-grey-bg-color tw-default-text-color d-flex align-items-center justify-content-center w-100" :class="{ active: showingTrashed }" @click.prevent.stop="showTrashed">
                                    <span class="d-flex justify-content-center align-items-center">
                                        <i class="fas fa-dumpster"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="(siteType == 'cms6' && userHasCms6Permission('create_pages')) || supportsPagePublish" class="col text-end mt-3 mt-lg-0 d-flex">
                        <button class="tw-red-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0" @click="openCreatePageModal">
                            <span class="d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                                <i class="fas fa-plus"></i>
                            </span>
                            <span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1 pe-5">Create Page</span>
                        </button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table ng-if="!vm.permissions.notices" class="tw-table table text-nowrap">
                        <thead>
                            <tr>
                                <!-- <th class="tw-table-checkbox-th"></th> -->
                                <th>Picture</th>
                                <th st-sort="title" style="cursor: pointer" @click="setOrder('title')">
                                    <span class="me-3">Name</span>
                                    <span v-if="orderBy != 'title'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-up me-2">
                                            <span class="d-block"></span>
                                        </span>
                                        <span class="tw-toggle-angle-down">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                    <span v-if="orderBy == 'title' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-up">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                    <span v-if="orderBy == 'title' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-down">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                </th>
                                <th v-if="siteType == 'cms6'" st-sort="slug" style="cursor: pointer" @click="setOrder('slug')">
                                    <span class="me-3">Permalink</span>
                                    <span v-if="orderBy != 'slug'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-up me-2">
                                            <span class="d-block"></span>
                                        </span>
                                        <span class="tw-toggle-angle-down">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                    <span v-if="orderBy == 'slug' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-up">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                    <span v-if="orderBy == 'slug' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-down">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                </th>
                                <th st-sort="date" style="cursor: pointer" @click="setOrder('date')">
                                    <span class="me-3">Date Created/Modified</span>
                                    <span v-if="orderBy != 'date'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-up me-2">
                                            <span class="d-block"></span>
                                        </span>
                                        <span class="tw-toggle-angle-down">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                    <span v-if="orderBy == 'date' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-up">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                    <span v-if="orderBy == 'date' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
                                        <span class="tw-toggle-angle-down">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="position-relative">
                            <div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
                                <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                            </div>
                            <tr v-for="(page, index) in pages" :key="index">
                                <!-- <td class="tw-checkbox"><input :id="'page' + page.id" type="checkbox" @click="countSelected(index, $event)" value="1" :checked="page.checked"/><label :for="'page' + page.id"></label></td> -->
                                <td class="ps-4">
                                    <img
                                        v-if="page.featured_media || page.thumbnail_url"
                                        :src="page.featured_media && page.featured_media.media_details && page.featured_media.media_details.sizes && page.featured_media.media_details.sizes.thumbnail ? page.featured_media.media_details.sizes.thumbnail.source_url : page.thumbnail_url || page.featured_media.source_url"
                                        :alt="page.post_title || page.title"
                                        height="53"
                                        width="55"
                                    />
                                    <span v-else class="d-flex align-items-center">
                                        <span v-html="defaultImage"></span> <a v-if="(siteType == 'cms6' && userHasCms6Permission('update_pages', 'pages', page.id)) || supportsPagePublish" class="tw-red-color ms-md-3 fw-bold" href="#" @click.stop.prevent="openCreatePageModal($event, page.id)">Click to add</a>
                                    </span>
                                </td>
                                <td v-if="(siteType == 'cms6' && userHasCms6Permission('update_pages', 'pages', page.id)) || supportsPagePublish">
                                    <a
                                        class="tw-table-title-column"
                                        href=""
                                        @click.stop.prevent="!showingTrashed && openCreatePageModal($event, page.id)"
                                        v-html="page.title.rendered || page.title.rendered === '' ? sliceString(page.title.rendered, 70) : sliceString(page.title, 70)"
                                        data-bs-html="true"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :title="page.title.rendered || page.title.rendered === '' ? page.title.rendered : page.title"
                                    ></a>
                                    <small v-if="page.status != 'publish'" class="bg-info text-white px-1 rounded text-capitalize">{{ page.status }}</small>
                                </td>
                                <td v-else>
                                    <a
                                        class="tw-table-title-column"
                                        href=""
                                        @click.stop.prevent="openPreviewPageModal($event, page.id)"
                                        v-html="sliceString(page.title.rendered, 70)"
                                        data-bs-html="true"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :title="page.title.rendered || page.title.rendered === '' ? page.title.rendered : page.title"
                                    ></a>
                                    <small v-if="page.status != 'publish'" class="bg-info text-white px-1 rounded text-capitalize">{{ page.status }}</small>
                                </td>
                                <td v-if="siteType == 'cms6'">
                                    <a v-if="page.slug_prefix" :href="siteUrl + '/' + page.slug_prefix + page.slug" target="_blank">{{ siteUrl + "/" + page.slug_prefix + page.slug }}</a>
                                    <a v-else :href="siteUrl + '/' + page.slug" target="_blank">{{ siteUrl + "/" + page.slug }}</a>
                                </td>
                                <td>
                                    {{ moment(page.post_date || page.date).format("ll") }}
                                </td>
                                <td class="text-end column-actions">
                                    <div v-if="!showingTrashed" class="d-flex justify-content-end">
                                        <a v-if="(siteType == 'cms6' && userHasCms6Permission('update_pages', 'pages', page.id)) || supportsPagePublish" href="#" class="d-flex align-items-center" @click="openCreatePageModal($event, page.id)"
                                            ><span class="fa-stack"><i class="tw-dark-grey-color fa fa-circle fa-stack-2x"></i><i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span
                                        ></a>

                                        <a v-if="(siteType == 'cms6' && userHasCms6Permission('view_pages', 'pages'))" href="#" class="d-flex align-items-center" @click="openPreviewCMS6PageModal($event, page.id)"
                                            ><span class="fa-stack"><i class="tw-dark-grey-color fa fa-circle fa-stack-2x"></i><i class="far fa-eye fa-stack-1x fa-inverse"></i></span
                                        ></a>

                                        <a v-if="siteType == 'cms6' && userHasCms6Permission('delete_pages')" href="" @click.prevent="removePage(page)" class="d-flex align-items-center"
                                            ><span class="fa-stack"><i class="tw-dark-grey-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span
                                        ></a>

                                        <!-- if wordpress site supports tw page builder -->
                                        <a
                                            v-if="siteType == 'wordpress' && supportsPagePublish"
                                            :href="page.link + (page.status == 'draft' ? '&' : '?') + 'preview=true&dashboard-auth=' + auth"
                                            target="_blank"
                                            class="d-flex align-items-center"
                                            ><span class="fa-stack"><i class="tw-dark-grey-color fa fa-circle fa-stack-2x"></i><i class="far fa-eye fa-stack-1x fa-inverse"></i></span
                                        ></a>

                                        <!-- if wordpress site -->
                                        <a v-if="siteType == 'wordpress' && !supportsPagePublish" href="#" class="d-flex align-items-center" @click="openPreviewPageModal($event, page.id)"
                                            ><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="far fa-eye fa-stack-1x fa-inverse"></i></span
                                        ></a>

                                        <!-- if wordpress site uses Beaver Builder plugin -->
                                        <a
                                            v-if="siteType == 'wordpress' && !supportsPagePublish"
                                            :href="page.link + (page.status == 'draft' ? '&' : '?') + 'fl_builder&dashboard-auth=' + auth"
                                            target="_blank"
                                            class="d-flex align-items-center"
                                            ><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span
                                        ></a>
                                    </div>
                                    <div v-if="showingTrashed && siteType == 'cms6' && userHasCms6Permission('restore_deleted_pages')" class="d-flex justify-content-end">
                                        <!-- if pages view showing trashed -->
                                        <a href="#" class="d-flex align-items-center" @click.prevent="restore(page.id)"
                                            ><span class="fa-stack"><i class="tw-light-green-color fa fa-circle fa-stack-2x"></i><i class="fas fa-trash-restore fa-stack-1x fa-inverse"></i></span
                                        ></a>
                                        <a v-if="userHasCms6Permission('force_delete_pages')" href="" @click.prevent="forceRemovePage(page)" class="d-flex align-items-center"
                                            ><span class="fa-stack"><i class="tw-dark-grey-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span
                                        ></a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="requestResolved && (!pages || !pages.length)">
                            <tr>
                                <td colspan="5" class="text-center">Nothing to show, no results found.</td>
                            </tr>
                        </tbody>
                        <tfoot v-if="pages.length">
                            <tr>
                                <td colspan="2">
                                    Rows per page
                                    <select v-model="perPage" class="border">
                                        <option :value="5">5</option>
                                        <option :value="10">10</option>
                                        <option :value="20">20</option>
                                    </select>
                                </td>
                                <td colspan="2" class="tw-table-pagination text-end">
                                    <Pagination :totalItems="numberOfPages" :currentPage="page" :perPage="perPage" @pageChanged="setPage" :goButton="false" styled="centered" borderActiveColor="" borderTextActiveColor="text-white" />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <CreatePageModal ref="CreatePageModal" :editing="editing" @pageSaved="handlePageSaved" @modalHidden="handleModalHidden" @slugUpdated="handleSlugUpdated" :supportsPagePublish="supportsPagePublish" />
    <PreviewPageModal ref="PreviewPageModal" />
    <PreviewCMS6PageModal ref="PreviewCMS6PageModal" />
</template>

<script>
import wordpressMixin from "@/mixins/wordpressMixin";
import cms6Mixin from "@/mixins/cms6Mixin";
import Pagination from "@/components/pagination/Pagination";
import CreatePageModal from "@/components/modals/CreatePageModal";
import PreviewPageModal from "@/components/modals/PreviewPageModal";
import PreviewCMS6PageModal from "@/components/modals/PreviewCMS6PageModal";
import helperMixin from "@/mixins/helperMixin";
import userMixin from "@/mixins/userMixin";
import { Tooltip } from "bootstrap";

let site = localStorage.getObject("site");

let mixins;

if (site.type == "wordpress") {
    mixins = [wordpressMixin, helperMixin, userMixin];
} else if (site.type == "cms6") {
    mixins = [cms6Mixin, helperMixin, userMixin];
}

export default {
    name: "Pages",
    components: {
        Pagination,
        CreatePageModal,
        PreviewPageModal,
        PreviewCMS6PageModal,
    },
    data: () => ({
        pages: [],
        numberOfShowedPages: 0,
        numberOfPages: 0,
        querySearch: "",
        requestResolved: false,
        page: 1,
        perPage: 10,
        order: "desc",
        orderBy: "date",
        selected: 0,
        auth: null,
        editing: false,
        editingPageId: null,
        defaultImage: '<div class="tw-defaul-featured-img"><i class="fas fa-image"></i></div>',
        showingTrashed: false,
    }),
    mounted() {
        this.setPages();
        this.setAuth();
    },
    updated() {
        let tooltopElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

        tooltopElements.forEach((element) => {
            let tooltip = Tooltip.getInstance(element);

            if (tooltip) {
                tooltip.dispose();
            }

            if (!Tooltip.getInstance(element)) {
                new Tooltip(element);
            }
        });
    },
    watch: {
        perPage: function () {
            // this.pages = [];
            this.setPages();
        },
        page: function () {
            // this.pages = [];
            this.setPages();
        },
        orderBy: function () {
            // this.pages = [];
            this.setPages();
        },
        order: function () {
            // this.pages = [];
            this.setPages();
        },
        querySearch: function () {
            // this.pages = [];
            this.setPages();
        },
    },
    methods: {
        async setPages(showTrashed = false) {
            this.requestResolved = false;

            if (this.siteType == "cms6" && this.showingTrashed) {
                showTrashed = true;
            }

            let res = await this.getPages(this.page, this.perPage, this.order, this.orderBy, this.querySearch, showTrashed);
            if (res && res.status === 200) {
                this.requestResolved = true;
                this.pages = res.data || [];
                if (this.siteType == "wordpress") {
                    this.setFeaturedMedia();
                }
                this.numberOfShowedPages = this.pages.length;
                this.numberOfPages = parseInt(res.headers["x-wp-total"]);
            }
        },
        openCreatePageModal(event, pageId = null) {
            if (pageId) {
                this.editing = true;
            }
            // this.editingPageId = pageId;
            // console.log(this.editingPageId)
            this.$refs.CreatePageModal.showModal(true, pageId);
            // this.$refs.PreviewPageModal.showModal(true, pageId, this.auth);
        },
        openPreviewPageModal(event, pageId) {
            this.$refs.PreviewPageModal.showModal(true, pageId, this.auth);
        },
        openPreviewCMS6PageModal(event, pageId) {
            this.$refs.PreviewCMS6PageModal.showModal(true, pageId);
        },
        setPage(n) {
            this.page = n;
        },
        async removePage(page) {
            this.$swal({
                title: "Are you sure?",
                showCancelButton: true,
                icon: "warning",
                text: "Are you sure you want to delete this page?",
                confirmButtonText: "Yes, delete it!",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        let res = await this.deletePage(page.id);

                        return res;
                    } catch (error) {
                        this.$swal.showValidationMessage(`Request failed: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$swal("Deleted!", "Page has been deleted!", "success").then(() => {
                        this.requestResolved = false;

                        this.setPages();
                    });
                }
            });
        },
        setOrder(orderBy) {
            if (this.orderBy != orderBy) {
                this.order = "desc";
            } else if (this.orderBy == orderBy && this.order == "desc") {
                this.order = "asc";
            } else if (this.orderBy == orderBy && this.order == "asc") {
                this.order = "desc";
            }
            this.orderBy = orderBy;
        },
        countSelected(index, event) {
            if (event.target.checked) {
                this.pages[index].selected = true;
            } else {
                this.pages[index].selected = false;
            }

            this.selected = this.pages.filter(function (page) {
                return page.selected;
            }).length;
        },
        setAuth() {
            this.auth = this.getAuth();
        },
        setFeaturedMedia() {
            let self = this;
            this.pages.map(function (page, index) {
                if (page._embedded["wp:featuredmedia"]) {
                    self.pages[index].featured_media = page._embedded["wp:featuredmedia"].find(function (media) {
                        return media.id == page.featured_media;
                    });
                }
            });
        },
        handlePageSaved(action) {
            this.$swal(`Page ${action}!`, `Page ${action} successfully!`, "success").then(() => {
                this.requestResolved = false;
                // this.posts = [];
                this.setPages();
            });
        },
        handleModalHidden() {
            this.editing = false;
            this.editingPageId = null;
        },
        showTrashed() {
            this.showingTrashed = !this.showingTrashed;

            this.setPages();
        },
        async restore(id) {
            this.requestResolved = false;
            await this.pageRestore(id);

            this.setPages();
        },
        handleSlugUpdated() {
            this.setPages();
        },
        async forceRemovePage(page) {
            this.$swal({
                title: "Are you sure?",
                showCancelButton: true,
                icon: "warning",
                text: "There is no revert. Are you sure you want to delete this page from trash?",
                confirmButtonText: "Yes, delete the page permanently!",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        let res = await this.forceDeletePage(page.id);

                        return res;
                    } catch (error) {
                        this.$swal.showValidationMessage(`Request failed: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$swal("Deleted!", "Page has been deleted permanently!", "success").then(() => {
                        this.requestResolved = false;

                        this.setPages();
                    });
                }
            });
        },
    },
    computed: {
        siteType() {
            return this.getSiteType();
        },
        supportsPagePublish() {
            return this.getSiteType() == "wordpress" && this.supportsTWPageBuilder();
        },
        siteUrl() {
            return this.getSiteUrl();
        },
    },
    mixins: mixins,
};
</script>

<style scoped>
#tw-pages-view-wrapper button.active {
    color: #fff !important;
    background: var(--tw-red) !important;
}
</style>
