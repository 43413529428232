<!-- Modal -->
<template>
    <!-- <button type="button" class="btn btn-primary" @click="modal.show()">
        Launch demo modal
    </button> -->
    <div class="modal fade" id="previewCMS6PageModal" ref="PreviewCMS6PageModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="modal-title">Page preview: {{ title }}</h3>
                    <span type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></span>
                </div>
                <div class="modal-body position-relative" id="modal-body">
                    <div v-show="preview && preview.page_preview" id="tw-page-preview"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Modal } from 'bootstrap';
    import cms6Mixin from '@/mixins/cms6Mixin';
    export default {
        name: 'SupportModal',
        data: () => ({
            modal: null,
            title: '',
            content: '',
            url: '',
            auth: null,
            spinner: false,
            sendError: false,
            preview: null,
            pageId: null,
        }),
        mounted() {
            this.modal = new Modal(this.$refs.PreviewCMS6PageModal);

            let previewCMS6PageModal = document.getElementById("previewCMS6PageModal");

            let self = this;

            previewCMS6PageModal.addEventListener("shown.bs.modal", function () {
                self.setPreview(self.pageId);
            });

            previewCMS6PageModal.addEventListener("hidden.bs.modal", function () {
                self.title = '';
                self.preview = false;
                self.pageId = null;
            });
        },
        methods: {
            showModal: function(value, pageId) {
                if (value) {
                    this.pageId = pageId;
                    this.modal.show();
                }
            },
            async setPreview(pageId) {
                let res = await this.getPagePreview(pageId);
                this.preview = await res.data;

                this.title = this.preview.page_title;
                new window.pym.Parent('tw-page-preview', this.preview.page_preview, {srcdoc: true});
            },
        },
        mixins: [cms6Mixin]
    }
</script>
<style scoped>
    iframe {
        pointer-events: none;
    }
    #previewCMS6PageModal {
        padding: 0;
    }
    #previewCMS6PageModal .modal-header {
        border-radius: 0;
    }
</style>
