<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="sk-element-wrapper" :class="element.id + '-wrapper'" v-if="element.props" :style="styleWrapper" @dblclick.stop="onClick()">
        <template v-if="element.props.text && element.props.text !== '' && element.props.text !== 'null'">
            <a type="button" class="btn" :class="element.props.class" :href="element.link" :style="styleObject">
                {{ element.props.text }}
            </a>
        </template>
        <template v-else>
            <div class="sk-not-selected-element">Add Button Content</div>
        </template>
    </div>
</template>

<script>
export default {
    name: "Button",
    props: {
        element: {
            required: false,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
    },
    computed: {
        styleObject() {
            return {
                width: this.element.style.width && this.element.style.width != "0%" && this.element.style.width != "0px" && this.element.style.width != "0rem" ? this.element.style.width : "auto",
                backgroundColor: this.element.style.backgroundColor,
                color: this.element.style.color,
                border: this.element.style.borderNumber ? `${this.element.style.borderNumber}px solid ${this.element.style.borderColor}` : "",
                padding: this.element.style.padding,
                margin: this.element.style.margin,
                borderRadius: this.element.style.borderRadius,
                fontSize: this.element.style.fontSize && this.element.style.fontSize != "0%" && this.element.style.fontSize != "0px" && this.element.style.fontSize != "0rem" ? this.element.style.fontSize : "auto",
                textDecoration: this.element.style.textDecoration ? this.element.style.textDecoration : "none",
            };
        },
        styleWrapper() {
            return {
                textAlign: this.element.style.textAlign,
            };
        },
    },
    emits: ["onClick"],
};
</script>
