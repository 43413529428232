<template>
    <div>
        <div class="setting-navigation">
            <template :key="'tab-item-' + i" v-for="(tab, i) in property.tabs">
                <span @click="activateTab(tab)" class="btn btn-sm tab" :class="[{ 'active-tab': tab.active }]">{{ tab.name }}</span>
            </template>
        </div>
        <div class="setting-content">
            <template :key="'tab-item-' + i" v-for="(tab, i) in property.tabs">
                <div v-if="tab.active" class="general p-3">
                    <template :key="'tab-group-' + i + '-' + j" v-for="(group, j) in tab.groups">
                        <h6 class="border-bottom mb-3">{{ group.name }}</h6>
                        <div class="row element-setting-contant-group">
                            <template :key="'tab-group-attribute' + i + '-' + j + '-' + k" v-for="(attribute, k) in group.attributes">
                                <div class="">
                                    <!-- Attribute Chooser inputText -->
                                    <template v-if="attribute.chooser == 'inputText'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <input v-if="attribute.attr.includes('props.')" type="text" class="form-control form-control-sm text-input" v-model="elementSetting.props[attribute.attr.replace('props.', '')]" />
                                                <input v-else-if="attribute.attr.includes('style.')" type="text" class="form-control form-control-sm text-input" v-model="elementSetting.style[attribute.attr.replace('style.', '')]" />
                                                <input v-else type="text" class="form-control form-control-sm text-input" v-model="elementSetting[attribute.attr]" />
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputLink -->
                                    <template v-if="attribute.chooser == 'inputLink'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <input
                                                    placeholder="http://www.example.com"
                                                    v-if="attribute.attr.includes('props.')"
                                                    type="text"
                                                    class="form-control form-control-sm text-input"
                                                    v-model="elementSetting.props[attribute.attr.replace('props.', '')]"
                                                />
                                                <input placeholder="http://www.example.com" v-else type="text" class="form-control form-control-sm text-input" v-model="elementSetting[attribute.attr]" />
                                                <div class="additionals-wrapper d-flex justify-content-between" v-if="elementSetting.props">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" v-model="elementSetting.props.newWindow" />
                                                        <label for="">New Window</label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" v-model="elementSetting.props.noFollow" />
                                                        <label for="">No Follow</label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" v-model="elementSetting.props.forceDownload" />
                                                        <label for="">Force Download</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputTextarea -->
                                    <template v-if="attribute.chooser == 'inputTextarea'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <textarea
                                                    v-if="attribute.attr.includes('props.')"
                                                    rows="3"
                                                    @input="changeSetting()"
                                                    type="text"
                                                    class="form-control form-control-sm text-input"
                                                    v-model="elementSetting.props[attribute.attr.replace('props.', '')]"
                                                />
                                                <textarea v-else type="text" rows="3" @input="changeSetting()" class="form-control form-control-sm text-input" v-model="elementSetting[attribute.attr]" />
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputHeading -->
                                    <template v-if="attribute.chooser == 'inputHeadingTag'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select style="width: 65px" class="form-select form-select-sm rounded-1 select-input" v-model="elementSetting.props[attribute.attr.replace('props.', '')]">
                                                    <option value="h1">h1</option>
                                                    <option value="h2">h2</option>
                                                    <option value="h3">h3</option>
                                                    <option value="h4">h4</option>
                                                    <option value="h5">h5</option>
                                                    <option value="h6">h6</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputIconOpen -->
                                    <template v-if="attribute.chooser == 'inputIconOpen'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <div class="form-group mb-2">
                                                    <div id="accordion-element-collapse-icon-open" class="dropdown">
                                                        <button class="btn btn-light btn-sm dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Open <font-awesome-icon :icon="['fas', this.parseUnicodeIcon(elementSetting.props[attribute.attr.replace('props.', '')])]" />
                                                        </button>
                                                        <ul class="dropdown-menu w-100">
                                                            <li v-for="icon in faIcons" :key="`${icon}-class`">
                                                                <a class="dropdown-item" @click="setIcon(icon, 'open', attribute.attr)">
                                                                    <font-awesome-icon :icon="['fas', icon]" />
                                                                    {{ icon }}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputIconClose -->
                                    <template v-if="attribute.chooser == 'inputIconClose'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <div class="form-group mb-2">
                                                    <div id="accordion-element-collapse-icon-close" class="dropdown">
                                                        <button class="btn btn-light btn-sm dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Close <font-awesome-icon :icon="['fas', this.parseUnicodeIcon(elementSetting.props[attribute.attr.replace('props.', '')])]" />
                                                        </button>
                                                        <ul class="dropdown-menu w-100">
                                                            <li v-for="icon in faIcons" :key="`${icon}-class`">
                                                                <a class="dropdown-item" @click="setIcon(icon, 'close', attribute.attr)">
                                                                    <font-awesome-icon :icon="['fas', icon]" />
                                                                    {{ icon }}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputColor -->
                                    <template v-if="attribute.chooser == 'inputColor'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <ColorChooser
                                                    v-on:change="elementSetting.props[attribute.attr.replace('props.', '')] = $event"
                                                    v-if="attribute.attr.includes('props.')"
                                                    :themeSettings="themeSettings"
                                                    :value="elementSetting.props[attribute.attr.replace('props.', '')]"
                                                />
                                                <ColorChooser
                                                    v-on:change="elementSetting.style[attribute.attr.replace('style.', '')] = $event"
                                                    v-else-if="attribute.attr.includes('style.')"
                                                    :themeSettings="themeSettings"
                                                    :value="elementSetting.style[attribute.attr.replace('style.', '')]"
                                                />
                                                <ColorChooser v-on:change="elementSetting[attribute.attr] = $event" v-else :themeSettings="themeSettings" :value="elementSetting[attribute.attr]" />
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputWithUnit -->
                                    <template v-if="attribute.chooser == 'inputWithUnit'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <InputWithUnit :title="attribute.label" v-on:changeValue="changeInputWithUnitValue($event, attribute.name)" :values="elementSetting.style[attribute.attr.replace('style.', '')]" />
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputFourValues -->
                                    <template v-if="attribute.chooser == 'inputFourValues'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <FourValues
                                                    v-if="attribute.attr.includes('style.')"
                                                    :title="attribute.label"
                                                    v-on:changeValue="changeValue($event, attribute.name)"
                                                    :values="elementSetting.style[attribute.attr.replace('style.', '')]"
                                                />
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser InputColumnSize -->
                                    <template v-if="attribute.chooser == 'inputColumnSize'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select class="form-select form-select-sm rounded-1 select-input" v-model="elementSetting[attribute.attr]">
                                                    <option value="col-12 col-md-12 col-lg-12">1 Column (col-12)</option>
                                                    <option value="col-12 col-md-6 col-lg-6">2 Columns (col-6)</option>
                                                    <option value="col-12 col-md-4 col-lg-4">3 Columns (col-4)</option>
                                                    <option value="col-12 col-md-3 col-lg-3">4 Columns (col-3)</option>
                                                    <option value="col-12 col-md-2 col-lg-2">6 Columns (col-2)</option>
                                                    <option value="col-12 col-md-5 col-lg-5">(col-5)</option>
                                                    <option value="col-12 col-md-7 col-lg-7">(col-7)</option>
                                                    <option value="col-12 col-md-8 col-lg-8">(col-8)</option>
                                                    <option value="col-12 col-md-9 col-lg-9">(col-9)</option>
                                                    <option value="col-12 col-md-10 col-lg-10">(col-10)</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputImage -->
                                    <template v-if="attribute.chooser == 'inputImage'">
                                        <div class="chooser-element input-image">
                                            <div class="chooser-element-title">
                                                <div class="chooser-element-title">
                                                    <span class="title">{{ attribute.label }}</span>
                                                    <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                                </div>
                                            </div>
                                            <div class="chooser-element-content">
                                                <div class="src-wrapper d-flex justify-content-start align-items-end">
                                                    <div class="form-group w-100">
                                                        <input v-if="attribute.attr.includes('props.')" type="text" class="form-control text-input" v-model="elementSetting.props[attribute.attr.replace('props.', '')]" />
                                                        <input v-else-if="attribute.attr.includes('style.')" type="text" class="form-control text-input" v-model="elementSetting.style[attribute.attr.replace('style.', '')]" />
                                                        <input v-else type="text" class="form-control text-input" v-model="elementSetting[attribute.attr]" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm text-white" @click="showWordpressMediaLibraryModal(attribute.attr)">
                                                        <i class="fas fa-photo-video"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputBackgroundSize -->
                                    <template v-if="attribute.chooser == 'inputBackgroundSize'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select class="form-select form-select-sm rounded-1 select-input" v-model="elementSetting.style[attribute.attr.replace('style.', '')]">
                                                    <option value="">Select</option>
                                                    <option value="auto">auto</option>
                                                    <option value="contain">contain</option>
                                                    <option value="cover">cover</option>
                                                    <option value="inherit">inherit</option>
                                                    <option value="unset">unset</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputBackgroundPosition -->
                                    <template v-if="attribute.chooser == 'inputBackgroundPosition'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select class="form-select form-select-sm rounded-1 select-input" v-model="elementSetting.style[attribute.attr.replace('style.', '')]">
                                                    <option value="">Select</option>
                                                    <option value="bottom">bottom</option>
                                                    <option value="top">top</option>
                                                    <option value="center">center</option>
                                                    <option value="left">left</option>
                                                    <option value="right">right</option>
                                                    <option value="unset">unset</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputBackgroundRepeat -->
                                    <template v-if="attribute.chooser == 'inputBackgroundRepeat'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select class="form-select form-select-sm rounded-1 select-input" v-model="elementSetting.style[attribute.attr.replace('style.', '')]">
                                                    <option value="">Select</option>
                                                    <option value="no-repeat">no-repeat</option>
                                                    <option value="repeat">repeat</option>
                                                    <option value="repeat-x">repeat-x</option>
                                                    <option value="repeat-y">repeat-y</option>
                                                    <option value="round">round</option>
                                                    <option value="space">space</option>
                                                    <option value="unset">unset</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputItemsPerRow -->
                                    <template v-if="attribute.chooser == 'inputItemsPerRow'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select class="form-select form-select-sm rounded-1 select-input" v-model="elementSetting.props[attribute.attr.replace('props.', '')]">
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="6">6</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputCheckbox -->
                                    <template v-if="attribute.chooser == 'inputCheckbox'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <div class="form-check d-flex justify-content-start align-items-center">
                                                    <input v-if="attribute.attr.includes('props.')" type="checkbox" class="form-check-input" v-model="elementSetting.props[attribute.attr.replace('props.', '')]" />
                                                    <input v-else-if="attribute.attr.includes('style.')" type="checkbox" class="form-check-input" v-model="elementSetting.style[attribute.attr.replace('style.', '')]" />
                                                    <input v-else type="checkbox" class="form-check-input" v-model="elementSetting[attribute.attr]" />
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputMiliseconds -->
                                    <template v-if="attribute.chooser == 'inputMiliseconds'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <div class="input-group input-group-sm">
                                                    <input class="form-control form-control-sm text-input" type="number" min="0" max="20000" v-model="elementSetting.props[attribute.attr.replace('props.', '')]" />
                                                    <span class="input-group-text input-group-sm select-input">ms</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputTextEditor -->
                                    <template v-if="attribute.chooser == 'inputTextEditor'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-content w-100 h-100">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                                <div class="pb-text-editor">
                                                    <editor
                                                        v-if="attribute.attr.includes('props.')"
                                                        api-key=""
                                                        v-model="elementSetting.props[attribute.attr.replace('props.', '')]"
                                                        class=""
                                                        theme="advanced"
                                                        theme_advanced_toolbar_location="top"
                                                        :init="tinymcInit"
                                                        @execCommand="tinyMCEMedLib"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputCodeEditor -->
                                    <template v-if="attribute.chooser == 'inputCodeEditor'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-content w-100 h-100">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                                <!-- TODO -->
                                                <CodeEditor
                                                    :languages="[
                                                        ['html', 'HTML'],
                                                        ['javascript', 'JS'],
                                                    ]"
                                                    :line-nums="true"
                                                    v-model="elementSetting.props[attribute.attr.replace('props.', '')]"
                                                ></CodeEditor>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputGalleryItems -->
                                    <template v-if="attribute.chooser == 'inputGalleryItems'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <div class="row">
                                                    <draggable ghost-class="ghost" class="dragArea row" :list="elementSetting.props[attribute.attr.replace('props.', '')]" :group="{ name: 'blocks' }">
                                                        <div class="col-md-6 mb-2" v-for="(item, index) in elementSetting.props[attribute.attr.replace('props.', '')]" :key="'gallery-item-' + index">
                                                            <div class="gallery-item">
                                                                <div class="image">
                                                                    <span>{{ index + 1 }}</span>
                                                                    <img v-if="item.image && item.image !== '' && item.image !== 'null'" @click="showWordpressMediaLibraryModal(item)" class="gallery-item-preview" :src="item.image" alt="" />
                                                                    <div v-else class="gallery-item-no-image" @click="showWordpressMediaLibraryModal(item)">Select</div>
                                                                </div>
                                                                <div class="content">
                                                                    <input type="text" placeholder="title" v-model="item.title" class="" />

                                                                    <div class="actions">
                                                                        <a role="button" class="btn btn-primary btn-sm" @click="showWordpressMediaLibraryModal(item)"><i class="fas fa-images"></i></a>
                                                                        <button v-if="index > 0" class="btn btn-danger btn-sm remove-gallery-item" @click="removePropertyItem(item.id)">
                                                                            <i class="fas fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-2" style="padding: 10px 20px 10px 20px">
                                                            <button class="btn btn-outline-primary btn-sm w-100 gallery-item text-center" @click="addPropertyItem('gallery')" style="height: 100px; padding-left: 20px">Add item</button>
                                                        </div>
                                                    </draggable>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputSliderItems -->
                                    <template v-if="attribute.chooser == 'inputSliderItems'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-content">
                                                <div class="row">
                                                    <draggable ghost-class="ghost" class="dragArea row" :list="elementSetting.props[attribute.attr.replace('props.', '')]" :group="{ name: 'blocks' }">
                                                        <div class="col-md-6 mb-2" v-for="(item, index) in elementSetting.props[attribute.attr.replace('props.', '')]" :key="'gallery-item-' + index">
                                                            <div class="gallery-item">
                                                                <div class="image">
                                                                    <span>{{ index + 1 }}</span>
                                                                    <img v-if="item.image && item.image !== '' && item.image !== 'null'" @click="showWordpressMediaLibraryModal(item)" class="gallery-item-preview" :src="item.image" alt="" />
                                                                    <div v-else class="gallery-item-no-image" @click="showWordpressMediaLibraryModal(item)">Select</div>
                                                                </div>
                                                                <div class="content">
                                                                    <input type="text" placeholder="title" v-model="item.title" class="" />
                                                                    <textarea placeholder="" v-model="item.text" rows="3" class="w-100" />

                                                                    <div class="actions">
                                                                        <a role="button" class="btn btn-primary btn-sm d-none" @click="showWordpressMediaLibraryModal(item)"><i class="fas fa-images"></i></a>
                                                                        <button v-if="index > 1" class="btn btn-danger btn-sm remove-gallery-item" @click="removePropertyItem(item.id)">
                                                                            <i class="fas fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-2" style="padding: 10px 20px 10px 20px">
                                                            <button class="btn btn-outline-primary btn-sm w-100 gallery-item text-center" @click="addPropertyItem('slider')" style="height: 100px; padding-left: 20px">Add item</button>
                                                        </div>
                                                    </draggable>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputContentSliderItems -->
                                    <template v-if="attribute.chooser == 'inputContentSliderItems'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-content">
                                                <div class="row">
                                                    <draggable ghost-class="ghost" class="dragArea row" :list="elementSetting.props[attribute.attr.replace('props.', '')]" :group="{ name: 'blocks' }">
                                                        <div class="col-md-12 mb-2" v-for="(item, index) in elementSetting.props[attribute.attr.replace('props.', '')]" :key="'gallery-item-' + index">
                                                            <div class="gallery-item">
                                                                <div class="video" v-if="item.type && item.type == 'video+text'">
                                                                    <textarea type="text" class="" v-model="item.video" placeholder="Add video URL" />
                                                                </div>
                                                                <div class="image" v-if="item.type && item.type == 'image+text'">
                                                                    <img v-if="item.image && item.image !== '' && item.image !== 'null'" @click="showWordpressMediaLibraryModal(item)" class="gallery-item-preview" :src="item.image" alt="" />
                                                                    <div v-else class="gallery-item-no-image" @click="showWordpressMediaLibraryModal(item)">Select</div>
                                                                </div>
                                                                <div class="content">
                                                                    <div class="d-flex justify-content-between align-items-center w-100">
                                                                        <input type="text" placeholder="Heading" v-model="item.heading" class="" />
                                                                        <select class="" v-model="item.type">
                                                                            <option value="text">Text</option>
                                                                            <option value="text+image">Text & Image</option>
                                                                            <option value="text+video">Text & Video</option>
                                                                            <option value="image+text">Image & Text</option>
                                                                            <option value="video+text">Video & Text</option>
                                                                        </select>
                                                                    </div>
                                                                    <button class="btn btn-outline-primary btn-sm mb-2" @click="item.showContent = true" v-if="!item.showContent">Show Content</button>
                                                                    <div class="jp-elements-item-editor w-100" v-if="item.showContent">
                                                                        <editor api-key="" v-model="item.text" class="" theme="advanced" theme_advanced_toolbar_location="top" :init="tinymcInit" @execCommand="tinyMCEMedLib" />
                                                                    </div>
                                                                    <button class="btn btn-outline-primary btn-sm mb-2" @click="item.showContent = false" v-if="item.showContent">Hide Content</button>
                                                                    <div class="actions">
                                                                        <a role="button" class="btn btn-primary btn-sm d-none" @click="showWordpressMediaLibraryModal(item)"><i class="fas fa-images"></i></a>
                                                                        <button v-if="index > 1" class="btn btn-danger btn-sm remove-gallery-item" @click="removePropertyItem(item.id)">
                                                                            <i class="fas fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="image" v-if="item.type && item.type == 'text+image'">
                                                                    <img v-if="item.image && item.image !== '' && item.image !== 'null'" @click="showWordpressMediaLibraryModal(item)" class="gallery-item-preview" :src="item.image" alt="" />
                                                                    <div v-else class="gallery-item-no-image" @click="showWordpressMediaLibraryModal(item)">Select</div>
                                                                </div>
                                                                <div class="video" v-if="item.type && item.type == 'text+video'">
                                                                    <textarea type="text" class="" v-model="item.video" placeholder="Add video URL" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </draggable>
                                                    <div class="col-md-12 mb-2 text-center">
                                                        <button class="btn btn-primary btn-sm" @click="addPropertyItem('contentSlider')">+ Add Item</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputAccordionItems -->
                                    <template v-if="attribute.chooser == 'inputAccordionItems'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-content position-relative p-4">
                                                <div v-show="accordionSorting" class="position-absolute start-0 end-0 top-0 bottom-0 opacity-75 bg-white" style="z-index: 9999;"></div>
                                                <draggable ghost-class="ghost" class="dragArea row" :list="elementSetting.props[attribute.attr.replace('props.', '')]" :group="{ name: 'blocks' }"  @end="onDragEnd($event, 'accordion')" :key="componentKey">
                                                    <div class="col-md-12 mb-2" v-for="(item, index) in elementSetting.props[attribute.attr.replace('props.', '')]" :key="'element-item-' + index">
                                                        <div class="element-item">
                                                            <div class="content">
                                                                <div class="form-group mb-2">
                                                                    <label for="">Title</label>
                                                                    <input type="text" class="form-control form-control-sm" placeholder="title" v-model="item.title" />
                                                                </div>
                                                                <div class="pb-text-editor">
                                                                    <editor api-key="" v-model="item.content" class="" theme="advanced" theme_advanced_toolbar_location="top" :init="tinymcInit" @execCommand="tinyMCEMedLib" />
                                                                </div>
                                                                <div class="actions">
                                                                    <a role="button" class="btn btn-primary btn-sm d-none" @click="showWordpressMediaLibraryModal(item)"><i class="fas fa-images"></i></a>
                                                                    <button v-if="index > 0" class="btn btn-danger btn-sm element-item-remove" @click="removePropertyItem(item.id)">
                                                                        <i class="fas fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div class="col-md-12 mb-2" style="padding: 10px 20px 10px 20px">
                                                        <button class="btn btn-primary btn-sm w-100 element-item" @click="addPropertyItem('accordion')">Add item</button>
                                                    </div>
                                                </draggable>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputTabItems -->
                                    <template v-if="attribute.chooser == 'inputTabItems'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-content">
                                                <draggable ghost-class="ghost" class="dragArea row" :list="elementSetting.props[attribute.attr.replace('props.', '')]" :group="{ name: 'blocks' }">
                                                    <div class="col-md-12 mb-2" v-for="(item, index) in elementSetting.props[attribute.attr.replace('props.', '')]" :key="'element-item-' + index">
                                                        <div class="element-item">
                                                            <div class="content">
                                                                <div class="form-group mb-2">
                                                                    <label for="">Title</label>
                                                                    <input type="text" placeholder="title" v-model="item.title" class="form-control" />
                                                                </div>
                                                                <div class="pb-text-editor">
                                                                    <editor api-key="" v-model="item.content" class="" theme="advanced" theme_advanced_toolbar_location="top" :init="tinymcInit" @execCommand="tinyMCEMedLib" />
                                                                </div>
                                                                <div class="actions">
                                                                    <a role="button" class="btn btn-primary btn-sm d-none" @click="showWordpressMediaLibraryModal(item)"><i class="fas fa-images"></i></a>
                                                                    <button v-if="index > 0" class="btn btn-danger btn-sm element-item-remove" @click="removePropertyItem(item.id)">
                                                                        <i class="fas fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 mb-2">
                                                        <button class="btn btn-primary btn-sm w-100 element-item" @click="addPropertyItem('tab')">Add item</button>
                                                    </div>
                                                </draggable>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputAlign -->
                                    <template v-if="attribute.chooser == 'inputAlign'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <div class="chooser-align-wrapper">
                                                    <button
                                                        @click="elementSetting.style[attribute.attr.replace('style.', '')] = 'left'"
                                                        class="align-btn"
                                                        :class="[{ active: elementSetting.style[attribute.attr.replace('style.', '')] == 'left' }]"
                                                    >
                                                        <i class="fas fa-align-left"></i>
                                                    </button>
                                                    <button
                                                        @click="elementSetting.style[attribute.attr.replace('style.', '')] = 'center'"
                                                        class="align-btn"
                                                        :class="[{ active: elementSetting.style[attribute.attr.replace('style.', '')] == 'center' }]"
                                                    >
                                                        <i class="fas fa-align-center"></i>
                                                    </button>
                                                    <button
                                                        @click="elementSetting.style[attribute.attr.replace('style.', '')] = 'right'"
                                                        class="align-btn"
                                                        :class="[{ active: elementSetting.style[attribute.attr.replace('style.', '')] == 'right' }]"
                                                    >
                                                        <i class="fas fa-align-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputCalloutImagePosition -->
                                    <template v-if="attribute.chooser == 'inputCalloutImagePosition'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select class="form-select form-select-sm rounded-1 select-input" v-model="elementSetting.props[attribute.attr.replace('props.', '')]">
                                                    <option value="AboveHeading">Above Heading</option>
                                                    <option value="BelowHeading">Below Heading</option>
                                                    <option value="LeftOfTextAndHeading">Left Of Text And Heading</option>
                                                    <option value="RightOfTextAndHeading">Right Of Text And Heading</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputBorderStyle -->
                                    <template v-if="attribute.chooser == 'inputBorderStyle'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select class="form-select form-select-sm rounded-1" style="width: 116px" v-model="elementSetting.style[attribute.attr.replace('style.', '')]">
                                                    <option value="solid">Solid</option>
                                                    <option value="dashed">Dashed</option>
                                                    <option value="dotted">Dotted</option>
                                                    <option value="double">Double</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputBorderStyle -->
                                    <template v-if="attribute.chooser == 'inputTextDecoration'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select class="form-select form-select-sm rounded-1" style="width: 130px" v-model="elementSetting.style[attribute.attr.replace('style.', '')]">
                                                    <option value="none">None</option>
                                                    <option value="overline">Overline</option>
                                                    <option value="underline">Underline</option>
                                                    <option value="line-through">Line Through</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputTextVerticalPosition -->
                                    <template v-if="attribute.chooser == 'inputTextVerticalPosition'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-title">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                            </div>
                                            <div class="chooser-element-content">
                                                <select class="form-select form-select-sm rounded-1" style="width: 130px" v-model="elementSetting.style[attribute.attr.replace('style.', '')]">
                                                    <option value="top">None</option>
                                                    <option value="start">Top</option>
                                                    <option value="center">Center</option>
                                                    <option value="end">Bottom</option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>

                                    <!-- Attribute Chooser inputCodeEditor -->
                                    <template v-if="attribute.chooser == 'inputCustomCss'">
                                        <div class="chooser-element">
                                            <div class="chooser-element-content w-100 h-100">
                                                <span class="title">{{ attribute.label }}</span>
                                                <span class="title-help" v-if="attribute.help !== ''"><i class="fas fa-info-circle text-info ms-1" :title="attribute.help"></i></span>
                                                <br />
                                                <span>ID: {{ elementSetting.id }}</span>
                                                <!-- TODO -->
                                                <CodeEditor :languages="[['css', 'CSS']]" :line-nums="true" v-model="elementSetting.style[attribute.attr.replace('style.', '')]"></CodeEditor>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </template>
            <div class="d-flex justify-content-between align-items-center">
                <button @click="cancelChanges()" class="btn btn-secondary">Cancel</button>
                <button @click="closeElementSetting()" class="btn btn-primary">Apply</button>
            </div>
        </div>
    </div>
    <WordpressMediaLibraryModal v-on:mediaInsert="insertPhoto($event)" ref="wordpressMediaLibraryModal" />
</template>

<script>
import pageBuilderMixin from "@/mixins/pageBuilderMixin";
import { VueDraggableNext } from "vue-draggable-next";
import WordpressMediaLibraryModal from "@/components/modals/WordpressMediaLibraryModal";
import FourValues from "@/components/page-builder/elements/parts/FourValues";
import ColorChooser from "@/components/page-builder/elements/parts/choosers/ColorChooser";
import InputWithUnit from "@/components/page-builder/elements/parts/InputWithUnit";
import editor from "@tinymce/tinymce-vue";
import tinymceConfig from "@/tinymce/pbConfig";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
//import hljs from "highlight.js";
import CodeEditor from "simple-code-editor";
export default {
    name: "ElementSettingProperty",
    components: {
        WordpressMediaLibraryModal,
        draggable: VueDraggableNext,
        FourValues,
        InputWithUnit,
        ColorChooser,
        editor,
        CodeEditor,
    },
    props: {
        element: {
            required: true,
            type: Object,
        },
        property: {
            required: true,
            type: Object,
        },
    },
    data: () => ({
        elementSetting: null,
        backupElementSetting: null,
        themeSettings: [],
        applyImage: null,
        faIcons: ["plus", "minus", "arrow-up", "arrow-down", "chevron-up", "chevron-down", "folder-open", "folder"],
        onlyImages: true,
        hasChanges: false,
        componentKey: 0,
        accordionSorting: false
    }),

    mounted() {
        // Prevent Bootstrap dialog from blocking focusin
        document.addEventListener("focusin", (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }
        });
        /* getTinymce().activeEditor.ui.hide(); */
    },

    updated() {
        this.accordionSorting = false;
    },

    async created() {
        this.elementSetting = this.element;
        this.backupElementSetting = JSON.parse(JSON.stringify(this.element));
        // Handle if lemenet missing margin and padding and any other
        if (!this.elementSetting.style.margin) {
            this.elementSetting.style.margin = "0px 0px 0px 0px";
        }
        if (!this.elementSetting.style.padding) {
            this.elementSetting.style.padding = "0px 0px 0px 0px";
        }
        if (this.elementSetting.type == "ContentSlider" && !this.elementSetting.style.textAreaPadding) {
            this.elementSetting.style.textAreaPadding = "0px 0px 0px 0px";
        }
        if (this.elementSetting.type == "ContentSlider" && !this.elementSetting.style.textAreaMargin) {
            this.elementSetting.style.textAreaMargin = "0px 0px 0px 0px";
        }
        if (this.elementSetting.type == "ContentSlider" && !this.elementSetting.style.textAreaBorderRadius) {
            this.elementSetting.style.textAreaBorderRadius = "0px 0px 0px 0px";
        }
        this.themeSettings = await this.getClientThemeSetting();
    },

    methods: {
        showWordpressMediaLibraryModal(item) {
            this.applyImage = item;
            this.$refs.wordpressMediaLibraryModal.showModal(true);
        },

        insertPhoto(photoUrl) {
            // Check applyImage object and where to insert that selected image
            if (typeof this.applyImage == "object") {
                this.applyImage.image = photoUrl;
            } else {
                if (this.applyImage == "tiny") {
                    if (photoUrl.match(/\.(jpeg|jpg|gif|png)$/) != null) {
                        photoUrl = '<img src="' + photoUrl + '"/>';
                    } else {
                        photoUrl = '<a href="' + photoUrl + '">' + photoUrl + "</a>";
                    }
                    getTinymce().activeEditor.insertContent(photoUrl);
                } else {
                    if (this.applyImage.includes("props.")) {
                        this.elementSetting.props[this.applyImage.replace("props.", "")] = photoUrl;
                    } else if (this.applyImage.includes("style.")) {
                        this.elementSetting.style[this.applyImage.replace("style.", "")] = photoUrl;
                    } else {
                        this.elementSetting[this.applyImage] = photoUrl;
                    }
                }
            }
            this.changeSetting();
        },

        changeSetting() {
            this.$emit("elementSetting", this.elementSetting);
        },

        openWordpressMediaLibrary() {
            this.$refs.wordpressMediaLibraryModal.showModal(true, false);
        },

        changeValue(e, type) {
            if (type === "padding") {
                this.elementSetting.style.padding = e;
            }
            if (type === "labelPadding") {
                this.elementSetting.style.labelPadding = e;
            }
            if (type === "contentPadding") {
                this.elementSetting.style.contentPadding = e;
            }
            if (type === "headingPadding") {
                this.elementSetting.style.headingPadding = e;
            }
            if (type === "textPadding") {
                this.elementSetting.style.textPadding = e;
            }
            if (type === "margin") {
                this.elementSetting.style.margin = e;
            }
            if (type === "contentMargin") {
                this.elementSetting.style.contentMargin = e;
            }
            if (type === "radius") {
                this.elementSetting.style.borderRadius = e;
            }
            if (type === "textAreaPadding") {
                this.elementSetting.style.textAreaPadding = e;
            }
            if (type === "textAreaMargin") {
                this.elementSetting.style.textAreaMargin = e;
            }
            if (type === "textAreaBorderRadius") {
                this.elementSetting.style.textAreaBorderRadius = e;
            }
            if (type === "imageBorderRadius") {
                this.elementSetting.style.imageBorderRadius = e;
            }
            if (type === "textDecoration") {
                this.elementSetting.style.textDecoration = e;
            }
        },

        changeInputWithUnitValue(e, type) {
            if (type === "height") {
                this.elementSetting.style.height = e;
            }
            if (type === "width") {
                this.elementSetting.style.width = e;
            }
            if (type === "fontSize") {
                this.elementSetting.style.fontSize = e;
            }
            if (type === "labelFontSize") {
                this.elementSetting.style.labelFontSize = e;
            }
            if (type === "itemSpacing") {
                this.elementSetting.style.itemSpacing = e;
            }
            if (type === "fitWidth") {
                this.elementSetting.style.fitWidth = e;
            }
            if (type === "fitHeight") {
                this.elementSetting.style.fitHeight = e;
            }
            if (type === "border-width") {
                this.elementSetting.style.borderWidth = e;
            }
        },

        activateTab(tab) {
            this.property.tabs.forEach((t) => {
                t.active = false;
            });
            tab.active = true;
        },
        // Add properti item
        addPropertyItem(type) {
            if (type == "accordion") {
                let item = {
                    id: "accordion-item-" + Date.now() + "-" + this.elementSetting.props.items.length,
                    collapse: false,
                    title: "",
                    content: "",
                };
                this.elementSetting.props.items.push(item);
            }

            if (type == "tab") {
                let item = {
                    id: "tab-item-" + Date.now() + "-" + this.elementSetting.props.items.length,
                    active: false,
                    title: "",
                    content: "",
                };
                this.elementSetting.props.items.push(item);
            }

            if (type == "gallery") {
                let item = {
                    id: "gallery-item-" + Date.now() + "-" + this.elementSetting.props.items.length,
                    image: "",
                    title: "",
                };
                this.elementSetting.props.items.push(item);
            }

            if (type == "slider") {
                let item = {
                    id: "slider-item-" + Date.now() + "-" + this.elementSetting.props.items.length,
                    image: "",
                    title: "",
                    text: "",
                };
                this.elementSetting.props.items.push(item);
            }
            if (type == "contentSlider") {
                let item = {
                    id: "content-slider-item-" + Date.now() + "-" + this.elementSetting.props.items.length,
                    heading: "",
                    text: "",
                    image: "",
                    video: "",
                    type: "text",
                };
                this.elementSetting.props.items.push(item);
            }
        },

        setIcon(icon, state, prop) {
            let iconUnicode = this.getIconUnicode(icon);
            if (state === "open") {
                this.elementSetting.props[prop.replace("props.", "")] = iconUnicode;
            } else if (state === "close") {
                this.elementSetting.props[prop.replace("props.", "")] = iconUnicode;
            }

            this.changeSetting();
        },
        getIconUnicode(icon) {
            let iconUnicode = "";
            if (icon === "plus") {
                iconUnicode = "2b";
            } else if (icon === "minus") {
                iconUnicode = "f068";
            } else if (icon === "arrow-up") {
                iconUnicode = "f062";
            } else if (icon === "arrow-down") {
                iconUnicode = "f063";
            } else if (icon === "chevron-up") {
                iconUnicode = "f077";
            } else if (icon === "chevron-down") {
                iconUnicode = "f078";
            } else if (icon === "folder-open") {
                iconUnicode = "f07c";
            } else if (icon === "folder") {
                iconUnicode = "f07b";
            }
            return iconUnicode;
        },

        parseUnicodeIcon(unicodeIcon) {
            let icon = "";
            if (unicodeIcon === "2b") {
                icon = "plus";
            } else if (unicodeIcon === "f068") {
                icon = "minus";
            } else if (unicodeIcon === "f062") {
                icon = "arrow-up";
            } else if (unicodeIcon === "f063") {
                icon = "arrow-down";
            } else if (unicodeIcon === "f077") {
                icon = "chevron-up";
            } else if (unicodeIcon === "f078") {
                icon = "chevron-down";
            } else if (unicodeIcon === "f07c") {
                icon = "folder-open";
            } else if (unicodeIcon === "f07b") {
                icon = "folder";
            }
            return icon;
        },

        // Remove Gallery item
        removePropertyItem(id) {
            this.elementSetting.props.items = this.elementSetting.props.items.filter((item) => item.id !== id);
        },

        tinyMCEMedLib(event) {
            if (event.command === "openMediaLib") {
                this.applyImage = "tiny";
                this.openWordpressMediaLibrary();
            }
        },

        handleMediaInsert(content) {
            if (content.match(/\.(jpeg|jpg|gif|png)$/) != null) {
                content = '<img src="' + content + '"/>';
            } else {
                content = '<a href="' + content + '">' + content + "</a>";
            }

            getTinymce().activeEditor.insertContent(content);
        },
        closeElementSetting() {
            this.$emit("close");
        },
        cancelChanges() {
            this.assignObjectValues(this.elementSetting, this.backupElementSetting);
            this.$emit("close");
        },
        assignObjectValues(targetObj, sourceObj) {
            for (let key in sourceObj) {
                if (Object.prototype.hasOwnProperty.call(sourceObj, key)) {
                    if (typeof sourceObj[key] === "object" && sourceObj[key] !== null) {
                        if (!Object.prototype.hasOwnProperty.call(targetObj, key)) {
                            targetObj[key] = {};
                        }
                        this.assignObjectValues(targetObj[key], sourceObj[key]);
                    } else {
                        targetObj[key] = sourceObj[key];
                    }
                }
            }
        },
        onDragEnd: function(evt, type){

            if (type === 'accordion') {

                this.accordionSorting = true;
                this.componentKey += 1;
            }
        },
    },
    computed: {
        tinymcInit() {
            return tinymceConfig.config;
        },
    },
    mixins: [pageBuilderMixin],
};
</script>

<style scoped>
.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.element-setting-contant-group .group-name {
    margin-bottom: 0;
}
.element-setting-contant-group .group-help {
    font-size: 11px;
}

.element-item {
    position: relative;
}

.element-item-remove {
    position: absolute;
    right: -15px;
    top: 0px;
    border-radius: 50%;
}

.gallery-item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}

.gallery-item .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f7f7;
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.gallery-item .content input {
    border-radius: 5px !important;
    background-color: transparent;
    border: none;
    margin: 10px;
    width: 100%;
    padding-left: 5px;
    background-color: white;
}

.gallery-item .content select {
    border-radius: 5px !important;
    background-color: transparent;
    border: none;
    margin: 10px;
    padding-left: 5px;
    background-color: white;
}

.gallery-item .content textarea {
    border-radius: 5px !important;
    background-color: transparent;
    border: none;
    margin: 10px;
    width: 100%;
    padding-left: 5px;
    background-color: white;
}

.gallery-item .content .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f7f7;
    padding: 5px;
}

.gallery-item .image {
    min-width: 100px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.gallery-item .gallery-item-no-image {
    min-width: 100px;
    width: 100px;
    height: 100px;
    background-color: #f8f7f7;
    border-radius: 10px;
    color: #4e6078;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.gallery-item .video {
    min-width: 100px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;

    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.gallery-item .video textarea {
    min-width: 100px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px !important;
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    font-size: 12px;
    padding: 5px;
}

.gallery-item .image span {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 2px;
    border-bottom-right-radius: 7px;
}

.gallery-item .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
}

.gallery-item-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.chooser-element {
    display: flex;
    margin-bottom: 20px;
}

.chooser-element-title {
    font-size: 12px;
    width: 200px;
}
.chooser-element-title .title {
    color: #333;
}
.chooser-element-title .title-help {
    font-size: 12px;
    cursor: pointer;
}
.chooser-element-content {
    min-width: 300px;
}
.remove-gallery-item {
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 50%;
}

.text-input {
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 4px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    color: #333 !important;
    display: inline;
    font-size: 13px;
}

.select-input {
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 4px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    color: #333 !important;
    display: inline;
    font-size: 13px;
}

.chooser-align-wrapper {
    background: #e7ebef;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    float: left;
    height: 36px;
}

.align-btn {
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    border-radius: 0px;
    border: 2px solid transparent;
    background: transparent !important;
    width: 40px;
}
.align-btn:hover {
    box-shadow: none;
    transform: none;
}
.align-btn.active {
    background-color: white !important;
}
.form-check-input {
    cursor: pointer;
}
</style>
