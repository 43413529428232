<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="element-wrapper" :class="element.id + 'wrap'" v-if="element.props" @dblclick.stop="onClick()">
        <template v-if="element.props.value && element.props.value !== '' && element.props.value !== 'null'">
            <div class="sk-code-editor" :class="element.props.class" :style="styleObject" v-html="element.props.value"></div>
        </template>
        <template v-else>
            <div class="sk-not-selected-element">Add HTML Content</div>
        </template>
    </div>
</template>

<script>
export default {
    name: "Html",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
    },
    computed: {
        styleObject() {
            return {
                padding: this.element.style.padding,
                margin: this.element.style.margin,
                borderRadius: this.element.style.borderRadius,
            };
        },
    },
    emits: ["onClick"],
};
</script>
<style scoped>
.code-editor {
    min-height: 30px;
}
</style>
