<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="sk-element-wrapper" :class="element.id + '-wrapper'" v-if="element.props" @dblclick.stop="onClick()">
        <template v-if="element.props && element.props.value && element.props.value !== '' && element.props.value !== 'null'">
            <p :style="styleObject" :class="element.props.class">
                {{ element.props.value }}
            </p>
        </template>
        <template v-else>
            <div class="sk-not-selected-element">Add paragraph content</div>
        </template>
    </div>
</template>

<script>
export default {
    name: "Paragraph",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
    },
    computed: {
        styleObject() {
            return {
                textAlign: this.element.style.textAlign,
                backgroundColor: this.element.style.backgroundColor,
                color: this.element.style.color,
                border: this.element.style.borderNumber ? `${this.element.style.borderNumber}px solid ${this.element.style.borderColor}` : "",
                padding: this.element.style.padding,
                margin: this.element.style.margin,
                borderRadius: this.element.style.borderRadius,
            };
        },
    },
    emits: ["onClick"],
};
</script>
