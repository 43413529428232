<template>
	<section id="tw-view-meeting-repository">
		<div class="bg-white">
			<div class="p-4">
				<div class="tw-table-header row">
					<div class="tw-table-search-wrapper col-lg-4 text-end">
                        <input type="search" class="form-control" v-model="querySearch" placeholder="Search Meeting Repositories" />
                        <span class="tw-table-header-search-icon"><i class="fas fa-search"></i></span>
                    </div>
					<div class="col text-end mt-3 mt-lg-0 d-flex">
						<button class="tw-default-bg-color text-white d-block d-lg-flex align-items-center ms-auto justify-content-center px-3 me-auto me-lg-0" v-if="selected" @click.prevent="bulkDelete">
							<i class="fa fa-trash"></i>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1">Delete {{ selected }} Posts</span>
						</button>
						<button class="tw-red-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0" @click="openCreateMeetingRepositoryModal">
							<span class="d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
								<i class="fas fa-plus"></i>
							</span>
							<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1 pe-5">Upload Minutes or Agendas</span>
						</button>
					</div>
				</div>
				<div class="table-responsive">
					<table ng-if="!vm.permissions.notices" st-table="vm.results" st-pipe="vm.loadResults" class="tw-table table text-nowrap">
						<thead>
							<tr>
								<th class="tw-table-checkbox-th"></th>
								<th st-sort="title">
									<span class="cursor-pointer d-flex align-items-center" @click="setOrder('title')">
										<span class="me-3">Name</span>
										<span v-if="orderBy != 'title'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up me-2">
												<span class="d-block"></span>
											</span>
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'title' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'title' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
									</span>
								</th>
								<th st-sort="date">
									<span class="cursor-pointer d-flex align-items-center" @click="setOrder('date')">
										<span class="me-3">Date</span>
										<span v-if="orderBy != 'date'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up me-2">
												<span class="d-block"></span>
											</span>
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'date' && order == 'asc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-up">
												<span class="d-block"></span>
											</span>
										</span>
										<span v-if="orderBy == 'date' && order == 'desc'" class="d-inline-flex align-items-center justify-content-center">
											<span class="tw-toggle-angle-down">
												<span class="d-block"></span>
											</span>
										</span>
									</span>
								</th>
								<th>Category</th>
								<th>Agendas</th>
								<th>Agenda Packet</th>
								<th>Minutes</th>
								<th>Notifications</th>
								<th>Notify</th>
								<th></th>
							</tr>
						</thead>

						<tbody class="position-relative">
							<div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
								<i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
							</div>
						<!-- <tbody v-if="posts && posts.length"> -->
							<tr v-for="(repository, index) in repositories" :key="index">
								<td class="tw-checkbox"><input :id="'repository' + repository.id" type="checkbox" @click="countSelected(index, $event)" value="1" :checked="repository.checked"/><label :for="'repository' + repository.id"></label></td>
								<td>
									<span data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top" :title="(repository.title.rendered || repository.title.rendered === '') ? repository.title.rendered : repository.title">{{decodeHTMLEntities(sliceString( repository.title.rendered, 70)) }}</span>
								</td>
								<td>{{ moment.utc(repository.meeting_date).format('ll') }}</td>
								<td>
									<span v-for="(category, index) in repository.twd_repository_cat" :key="index"><span v-html="categories[category] ? categories[category].name : '' "></span><span v-if="(index + 1) != repository.twd_repository_cat.length">,</span> </span>
								</td>
								<td>
									<span v-if="repository.agenda"><a class="text-decoration-none text-dark" :href="repository.agenda.guid" target="_blank"><i class="fa fa-paperclip"></i> View document</a></span>
									<span v-show="!repository.agenda"><a @click="openCreateMeetingRepositoryModal($event, repository.id)" href="#" class="tw-red-color ms-md-3 fw-bold">Click to add</a></span>
								</td>
								<td>
									<span v-if="repository.agenda_pack"><a class="text-decoration-none text-dark" :href="repository.agenda_pack.guid" target="_blank"><i class="fa fa-paperclip"></i> View document</a></span>
									<span v-show="!repository.agenda_pack"><a @click="openCreateMeetingRepositoryModal($event, repository.id)" href="#" class="tw-red-color ms-md-3 fw-bold">Click to add</a></span>
								</td>
								<td>
									<span v-if="repository.meeting_minutes"><a class="text-decoration-none text-dark" :href="repository.meeting_minutes.guid" target="_blank"><i class="fa fa-paperclip"></i> View document</a></span>
									<span v-show="!repository.meeting_minutes"><a @click="openCreateMeetingRepositoryModal($event, repository.id)" href="#" class="tw-red-color ms-md-3 fw-bold">Click to add</a></span>
								</td>
								<td><b>{{ (repository.meta && repository.meta['tw-notifications-sent']) ? repository.meta['tw-notifications-sent'].slice(0,-14).split(' ')[0] : '' }}</b> {{ (repository.meta && repository.meta['tw-notifications-sent']) ? repository.meta['tw-notifications-sent'].slice(0,-14).split(' ')[1] : '' }}</td>



								<td>{{ ('times_notified' in repository) ? `Times used: ${repository.times_notified}/3` : 'Not implemented.' }} <span v-if="'times_notified' in repository" class="twd-subscribers-notify-from-list" :class="( notifying || ( repository.times_notified >= 3 ) ) ? 'disabled' : ''" :disabled="notifying || ( repository.times_notified >= 3 )" v-html="repository.notifying ? notifyBtnNotifying : notifyBtn" @click="!( notifying || ( repository.times_notified >= 3 ) ) && notify($event, repository)"></span></td>






								<td class="text-end column-actions">
									<div class="d-flex justify-content-end">
										<a href="#" @click.prevent="removeMeetingRepository(repository)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span></a>
										<a href="#" @click.stop.prevent="openCreateMeetingRepositoryModal($event, repository.id)" class="d-flex align-items-center"><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span></a>
									</div>
								</td>
							</tr>
						</tbody>

						<!-- <tbody v-if="!requestResolved && (!posts || !posts.length)">
							<tr>
								<td colspan="6" class="text-center">Loading <i class="fa fa-spinner fa-spin"></i></td>
							</tr>
						</tbody> -->

						<tbody v-if="requestResolved && (!repositories || !repositories.length)">
							<tr>
								<td colspan="9" class="text-center">Nothing to show, no results found.</td>
							</tr>
						</tbody>

						<tfoot v-if="repositories.length">
							<tr>
								<td colspan="4">
									Rows per page
									<select v-model="perPage" class="border">
										<option :value="5">5</option>
										<option :value="10">10</option>
										<option :value="20">20</option>
									</select>
								</td>
								<td colspan="5" class="tw-table-pagination text-end">

									<Pagination
										:totalItems="numberOfRepositories"
										:currentPage="page"
										:perPage="perPage"
										@pageChanged="setPage"
										:goButton="false"
										styled="centered"
										borderActiveColor=""
										borderTextActiveColor="text-white"
									/>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
		<CreateMeetingRepositoryModal ref="CreateMeetingRepositoryModal" :editing="editing" :editingMeetingRepositoryId="editingRepositoryId" @modalHidden="handleModalHidden" @repositorySaved="handleRepositorySaved" />
	</section>
</template>

<script>
	import CreateMeetingRepositoryModal from '@/components/modals/CreateMeetingRepositoryModal';
	import Pagination from '@/components/pagination/Pagination'
	// import wordpressMixin from '@/mixins/wordpressMixin';
	import wordpressMeetingRepositoryMixin from '@/mixins/wordpressMeetingRepositoryMixin';
	import heyGovMeetingRepositoryMixin from '@/mixins/heyGovMeetingRepositoryMixin';
	import helperMixin from '@/mixins/helperMixin';
    import { Tooltip } from "bootstrap";

	let site = localStorage.getObject('site');

	let mixins;

	if ((site.type == 'wordpress' && site.supportsHeyGovMeetRepo) || site.type == 'cms6') {
		mixins = [heyGovMeetingRepositoryMixin,helperMixin];
	} else {
		mixins = [wordpressMeetingRepositoryMixin,helperMixin];
	}

	export default {
		name: 'MeetingRepository',
		components: {
			CreateMeetingRepositoryModal,
			Pagination
		},
		data: () => ({
			repositories: [],
			numberOfShowedRepositories: 0,
			numberOfRepositories: 0,
			// numberOfPages: 0,
			querySearch: '',
			requestResolved: false,
			page: 1,
			perPage: 10,
			order: 'desc',
			orderBy: 'date',
			selected: 0,
			editing: false,
			editingRepositoryId: null,
			categories: [],
			notifyBtn: '&#10150;',
			notifyBtnNotifying: '<div class="dot-flashing d-inline-block"></div>',
			notifying: false
		}),
		watch: {
			perPage: function () {
				this.setRepositories();
			},
			page: function () {
				this.setRepositories();
			},
			orderBy: function () {
				this.setRepositories();
			},
			order: function () {
				this.setRepositories();
			},
			querySearch: function () {
				this.setRepositories();
			},
		},
		mounted() {
			if (this.$route.hash) {
				this.$refs.CreateMeetingRepositoryModal.showModal(true);
			}
			this.setCategories();
			this.setRepositories();
		},
        updated() {
            let tooltopElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

            tooltopElements.forEach(element => {

                let tooltip = Tooltip.getInstance(element);

                if (tooltip) {
                    tooltip.dispose();
                }

                if (!Tooltip.getInstance(element)) {
                    new Tooltip(element)
                }
            });
        },
		methods: {
			async setRepositories() {
				this.requestResolved = false;
				let res = await this.getMeetingRepositories(true, this.page, this.perPage, this.order, this.orderBy, this.querySearch);

				// let raw = res.data || [];
				// console.log(raw)
				// this.repository = await this.setMedia(raw);
				if (res && res.status === 200) {
					this.requestResolved = true;

					this.repositories = res.data || [];

					this.numberOfShowedRepositories = this.repositories.length;
					this.numberOfRepositories = parseInt( res.headers['x-wp-total'] );
					// this.numberOfPages = parseInt( res.headers['x-wp-totalpages'] );
					// this.setMedia();
				}

				// console.log(this.repository)
			},
			setPage(n) {
				this.page = n;
			},
			setOrder(orderBy) {
				if (this.orderBy != orderBy) {
					this.order = 'desc';
				} else if (this.orderBy == orderBy && this.order == 'desc') {
					this.order = 'asc';
				} else if (this.orderBy == orderBy && this.order == 'asc') {
					this.order = 'desc';
				}
				this.orderBy = orderBy;
			},
			countSelected(index, event) {

				if (event.target.checked) {
					this.repositories[index].selected = true;
				} else {
					this.repositories[index].selected = false;
				}

				this.selected = this.repositories.filter(function(repository) {return repository.selected}).length;
			},
			async setCategories() {
				let self = this;
				let res = await this.getMeetingRepositoryCategories();

				res.data.map(function(cat){
					self.categories[cat.id] = cat;
				});
			},
			async setMedia() {
				let self = this;
				let tempRepo = this.repositories;
				await Promise.all(this.repositories.map(async function(repo,index){
					let res;
					if (self.repositories && repo.agenda) {
						res = await self.getMedia(repo.agenda);
						tempRepo[index].agenda = res.data;
					}
					if (self.repositories && repo.agenda_pack) {
						res = await self.getMedia(repo.agenda_pack);
						tempRepo[index].agenda_pack = res.data;
					}
					if (self.repositories && repo.meeting_minutes) {
						res = await self.getMedia(repo.meeting_minutes);
						tempRepo[index].meeting_minutes = res.data;
					}
					if (self.repositories && repo.additional_file) {
						res = await self.getMedia(repo.additional_file);
						tempRepo[index].additional_file = res.data;
					}
				}));
				// this.repository = tempRepo;
			},
			async removeMeetingRepository(repository) {


				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete this repository?",
                    confirmButtonText: 'Yes, delete it!',
                    showLoaderOnConfirm: true,
					preConfirm: async () => {
						try {

							return await this.deleteMeetingRepository(repository.id);

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then(async (result) => {
					if (result.isConfirmed) {

						this.$swal(
							'Deleted!',
							"Repository has been deleted!",
							'success',
						)
						.then(() => {
							this.requestResolved = false;

							this.setRepositories();
						});
					}
                });
			},
			async bulkDelete () {




				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to delete all selected repositories?",
                    confirmButtonText: 'Yes, delete it!',
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
						let self = this;
						let repositories = this.repositories;
						try {

							await Promise.all(repositories.map( async function(repository){

								if (repository.selected) {
									await self.deleteMeetingRepository(repository.id);
								}

							}));

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then(async (result) => {

					if (result.isConfirmed) {

						this.$swal(
							'Deleted!',
							"Notices have been deleted!",
							'success',
						)
						.then(() => {
							this.requestResolved = false;

							this.selected = null;
							this.setRepositories();

						});

					}
                });
			},
			openCreateMeetingRepositoryModal(event, repositoryId = null) {
				if (repositoryId) {this.editing = true;}
				this.editingRepositoryId = repositoryId;
				this.$refs.CreateMeetingRepositoryModal.showModal(true);
			},
			handleModalHidden() {
				this.editing = false;
				this.editingRepositoryId = null;
			},
			handleRepositorySaved() {
				this.$swal(
					'Repository saved!',
					"Repository saved successfully!",
					'success',
				)
				.then(() => {

					this.requestResolved = false;
					// this.posts = [];
					this.setRepositories();
				});
				// this.requestResolved = false;
				// this.repositories = [];
				// this.setRepositories();
			},
			notify(event, repository) {

				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to notify subscribers about this repository?",
                    confirmButtonText: 'Yes, do it!',
                    showLoaderOnConfirm: true,
					preConfirm: async () => {
						try {

							return await this.meetingRepositoryNotify(repository.id);

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then(async (result) => {
					let message = result.value.data;
					if (result.isConfirmed) {

                        let title = 'Success!';
                        let type = 'success';


                        if (message.type && message.type == 'warning') {
                            title = 'Warning!';
                            type = 'warning';
                            message = message.message;
                        }


                        this.$swal(
                            title,
                            message + '!',
                            // "Subscribers notified!",
                            type,
                        )
                        .then(() => {
                            this.requestResolved = false;

                            this.setRepositories();
                        });

					}
                });
			}
		},
		mixins: mixins
    }
</script>
