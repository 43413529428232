<!-- Modal -->
<template>
    <div class="modal fade" id="createSubscriberModal" ref="createSubscriberModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="d-flex">
                        <span class="tw-create-modal-icon d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                            <i class="fas fa-plus"></i>
                        </span>
                        <span class="d-inline-flex">
                            <div v-if="!editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Create Subscriber</h5>
                                <p class="col-12 mb-0">Create a new subscriber</p>
                            </div>
                            <div v-if="editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Edit "{{ sliceString( title ) }}" subscriber</h5>
                                <p class="col-6 mb-0 d-none d-lg-flex">Edit subscribers data</p>
                                <p class="col-6 mb-0 text-lg-end">Subscribed: {{ moment(subscriber.post_date || subscriber.date).format('ll') }}</p>
                            </div>
                        </span>
                    </div>
                </div>

                <p class="text-center mt-3" v-show="editing && !subscriber.name">Loading subscriber details <i class="fa fa-spinner fa-spin"></i></p>

                <form v-show="!editing || (editing && subscriber && subscriber.name)" @submit.prevent.stop="storeNewSubscriber">
                    <div class="modal-body" id="modal-body">
                        <div v-show="sendError" class="alert alert-danger" role="alert">Error: Subscriber is not saved.</div>
                        <div class="row">
                            <div class="col">

                                <div class="row border p-5 my-5 mx-3 position-relative">
                                    <span class="tw-create-subscriber-box-description position-absolute start-0 text-center"><span class="bg-white px-3">Subscriber Details</span></span>
                                    <div class="col-lg-6">
                                        <div class="form-group mb-4">
                                            <label for="subscriber-name" class="mb-2">Name</label>
                                            <input type="text" class="form-control mb-4" v-model="name" required id="subscriber-name" placeholder="Subscribers name">

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group mb-4">

                                            <label for="subscriber-status" class="mb-2">Status</label>
                                            <select class="form-control mb-4 text-capitalize" required v-model="status" id="subscriber-status">
                                                <option v-for="status in statuses" :value="status" v-html="status" :key="status"></option>
                                            </select>

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group mb-4">
                                            <label for="subscriber-email" class="mb-2">Email</label>
                                            <input type="text" class="form-control mb-4" v-model="email" required id="subscriber-email" placeholder="Subscribers email">

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group mb-4">
                                            <label for="subscriber-phone" class="mb-2">Cell Phone Number</label>
                                            <input v-maska data-maska="(###) ###-####" type="text" class="form-control mb-4" v-model="phone" id="subscriber-phone" placeholder="Cell phone number">

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group mb-4">
                                            <label for="subscriber-address" class="mb-2">Address</label>
                                            <input type="text" class="form-control mb-4" v-model="address" id="subscriber-address" placeholder="Subscribers address">

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group mb-4">
                                            <label for="subscriber-city" class="mb-2">City</label>
                                            <input type="text" class="form-control mb-4" v-model="city" id="subscriber-city" placeholder="City">

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group mb-4 tw-checkbox col d-flex align-items-center">
                                            <input id="resident" type="checkbox" v-model="resident">
                                            <label class="fw-bold" for="resident">Resident</label>

                                        </div>
                                    </div>
                                </div>

                                <div class="row border p-5 my-5 mx-3 position-relative">
                                    <span class="tw-create-subscriber-box-description position-absolute start-0 text-center"><span class="bg-white px-3">Subscription Settings</span></span>

                                    <span class="mb-3">Topic(s) to Subscribe to</span>

                                    <div class="col-lg-6 mb-4">

                                        <div class="form-group mb-4 tw-checkbox col d-flex align-items-center">
                                            <input id="subscribe-to-posts" type="checkbox" v-model="subscribe_to_posts">
                                            <label class="fw-bold" for="subscribe-to-posts">Recent News & Notices Postings</label>

                                        </div>

                                        <div v-for="(post_category, index) in post_categories" :key="index" class="form-group mb-2 tw-checkbox col d-flex align-items-center">
                                            <input :id="post_category.slug" type="checkbox" v-model="post_terms[post_category.id]" :disabled="!subscribe_to_posts">
                                            <label class="fw-bold" :for="post_category.slug">{{ decodeHTMLEntities(post_category.name) }}</label>

                                        </div>
                                        <div v-show="!post_categories_resolved">
                                           <i class="fa fa-spinner fa-pulse"></i>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mb-4">

                                        <div class="form-group mb-4 tw-checkbox col d-flex align-items-center">
                                            <input id="subscribe-to-agendas" type="checkbox" v-model="subscribe_to_agendas">
                                            <label class="fw-bold" for="subscribe-to-agendas">New Agenda Posting</label>

                                        </div>

                                        <div v-for="(agenda_category, index) in agenda_categories" :key="index" class="form-group mb-2 tw-checkbox col d-flex align-items-center">
                                            <input :id="agenda_category.slug" type="checkbox" v-model="agenda_terms[agenda_category.id]" :disabled="!subscribe_to_agendas">
                                            <label class="fw-bold" :for="agenda_category.slug">{{ decodeHTMLEntities(agenda_category.name) }}</label>

                                        </div>
                                        <div v-show="!meet_repo_categories_resolved">
                                           <i class="fa fa-spinner fa-pulse"></i>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mb-4">

                                        <span class="d-flex mb-3">Agenda Documents</span>

                                        <div class="form-group mb-2 tw-checkbox col d-flex align-items-center">
                                            <input id="agenda" type="checkbox" v-model="agenda" :disabled="!subscribe_to_agendas || !getAgendaTerms().length">
                                            <label class="fw-bold" for="agenda">Agenda</label>

                                        </div>

                                        <div class="form-group mb-2 tw-checkbox col d-flex align-items-center">
                                            <input id="minutes" type="checkbox" v-model="minutes" :disabled="!subscribe_to_agendas || !getAgendaTerms().length">
                                            <label class="fw-bold" for="minutes">Minutes</label>

                                        </div>

                                        <div class="form-group tw-checkbox col d-flex align-items-center">
                                            <input id="packets" type="checkbox" v-model="packets" :disabled="!subscribe_to_agendas || !getAgendaTerms().length">
                                            <label class="fw-bold" for="packets">Packets</label>

                                        </div>

                                    </div>


                                    <div class="col-lg-6 mb-4">

                                        <span class="d-flex mb-3">Notification Method</span>

                                        <div class="form-group mb-2 tw-checkbox col d-flex align-items-center">
                                            <input id="receive_email" type="checkbox" v-model="receive_email">
                                            <label class="fw-bold" for="receive_email">Receive Email</label>

                                        </div>

                                        <div class="form-group tw-checkbox col d-flex align-items-center">
                                            <input id="receive_text_message" type="checkbox" v-model="receive_text_message">
                                            <label class="fw-bold" for="receive_text_message">Receive Text Message</label>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal-footer col-12">
                        <button class="btn tw-red-bg-color text-white">
                            Save
                            <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                        </button>
                        <button class="btn tw-dark-grey-bg-color text-white" type="button" @click="modal.hide()">Cancel</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <!-- <MediaModal ref="MediaModal" @mediaInsert="handleMediaInsert" @setMedia="handleSetFeaturedImage" /> -->
</template>
<script>
    import { Modal } from 'bootstrap';
    import { vMaska } from "maska"
    import wordpressMixin from '@/mixins/wordpressMixin';
    import wordpressMeetingRepositoryMixin from "@/mixins/wordpressMeetingRepositoryMixin";
    import heyGovMeetingRepositoryMixin from "@/mixins/heyGovMeetingRepositoryMixin";
    import cms6Mixin from '@/mixins/cms6Mixin';
    import helperMixin from '@/mixins/helperMixin';
    import siteMixin from '@/mixins/siteMixin';

    let site = localStorage.getObject('site');

    let mixins;

    if (site.type == 'wordpress') {
        mixins = [wordpressMixin,siteMixin,helperMixin];
    } else if(site.type == 'cms6') {
        mixins = [cms6Mixin,siteMixin,helperMixin];
    }
    if ((site.type == "wordpress" && site.supportsHeyGovMeetRepo) || site.type == "cms6") {
        mixins.push(heyGovMeetingRepositoryMixin);
    } else {
        mixins.push(wordpressMeetingRepositoryMixin);
    }
    export default {
        name: 'CreateSubscriberModal',
        components: {

        },
        directives: { maska: vMaska },
        emits: ["modalHidden", "subscriberSaved"],
        data: () => ({
            modal: null,
            toast: null,
            title: '',
            name: '',
            address: '',
            city: '',
            email: '',
            phone: '',
            resident: false,
            spinner: false,
            subscriber: {},
            status: 'publish',
            statuses: ['publish', 'draft'],
            format: 'LLL dd, Y, hh:mm a',
            date: null,
            sendError: false,
            post_terms: [],
            agenda_terms: [],
            post_categories: [],
            agenda_categories: [],
            subscribe_to_posts: false,
            subscribe_to_agendas: false,
            agenda: false,
            minutes: false,
            packets: false,
            receive_email: false,
            receive_text_message: false,
            allowed_terms: [],
            post_categories_resolved: false,
            meet_repo_categories_resolved: false,
        }),
        mounted() {
            this.modal = new Modal(this.$refs.createSubscriberModal)
            // this.showToast()

            let createSubscriberModal = document.getElementById('createSubscriberModal')

            // Prevent Bootstrap dialog from blocking focusin
            document.addEventListener('focusin', (e) => {
              if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
              }
            });

            let self = this;
            //hide.bs.modal
            createSubscriberModal.addEventListener('hide.bs.modal', function () {
                let el = document.querySelector('button[aria-label="More..."][aria-expanded="true"]');

                if (el) {
                    el.click();
                }
            });
            createSubscriberModal.addEventListener('hidden.bs.modal', function () {
                self.$emit('modalHidden');
                self.subscriber = {};
                self.title = '';
                self.name = '';
                self.address = '';
                self.city = '';
                self.email = '';
                self.phone = '';
                self.resident = false;
                self.status = 'publish';
                self.date = null;
                self.post_terms = [];
                self.agenda_terms = [];
                self.post_categories = [];
                self.agenda_categories = [];
                self.sendError = false;
                self.agenda = false;
                self.minutes = false;
                self.packets = false;
                self.receive_email = false;
                self.receive_text_message = false;
                self.subscribe_to_posts = false;
                self.subscribe_to_agendas = false;
            });
        },
        watch: {
            editingSubscriberId () {

                if (this.editingSubscriberId) {
                    this.setSubscriber();
                }
            },
            // post_terms: {
            //     handler() {
            //     console.log(this.getPostTerms())

            //     },
            //     deep: true
            // },
            // agenda_terms: {
            //     handler() {
            //     console.log(this.getAgendaTerms())


            //     },
            //     deep: true
            // }
        },
        methods: {
            showModal: async function(value) {
                if (value) {
                    this.modal.show();
                    await this.setAllowedTerms();
                    this.setCategories();
                }
            },
            async setSubscriber() {
                let res = await this.getSubscriber(this.$props.editingSubscriberId);
                this.subscriber = await res.data;

                // console.log(this.subscriber)

                if (this.subscriber.title) {
                    this.title = this.subscriber.title.rendered;
                }
                // this.category = this.post.categories[0];
                this.status = this.subscriber.status;
                this.date = this.subscriber.date;
                this.name = this.subscriber.name;
                this.address = this.subscriber.address;
                this.city = this.subscriber.city;
                this.phone = this.subscriber.phone;
                this.email = this.subscriber.email;

                if (this.subscriber.resident == 'on') {
                    this.resident = true;
                }

                if (this.subscriber.post == 'on') {
                    this.subscribe_to_posts = true;
                }

                if (this.subscriber.agenda == 'on') {
                    this.subscribe_to_agendas = true;
                }

                // post_terms
                // agenda_terms

                if (this.subscriber.post_terms) {
                    this.subscriber.post_terms.map((term) => this.post_terms[term] = true);
                }

                if (this.subscriber.agenda_terms) {
                    this.subscriber.agenda_terms.map((term) => this.agenda_terms[term] = true);
                }

                if (this.subscriber.agenda_docs) {

                    if (this.subscriber.agenda_docs.includes('agendas')) {
                        this.agenda = true;
                    }
                    if (this.subscriber.agenda_docs.includes('minutes')) {
                        this.minutes = true;
                    }
                    if (this.subscriber.agenda_docs.includes('packets')) {
                        this.packets = true;
                    }
                }

                if (this.subscriber.receive_email == 'on') {
                    this.receive_email = true;
                }

                if (this.subscriber.receive_text_message == 'on') {
                    this.receive_text_message = true;
                }
            },
            async setCategories() {

                let res, res2;

                this.post_categories_resolved = false;
                this.meet_repo_categories_resolved = false;

                if (this.siteType == 'cms6') {
                    if (this.allowed_terms['App\\Models\\NewsNoticeCategory'] && this.allowed_terms['App\\Models\\NewsNoticeCategory'].length) {
                        res = await this.getNewsNoticesCategories(null, this.allowed_terms['App\\Models\\NewsNoticeCategory']);
                    } else {
                        res = {data: []};
                    }
                } else {
                    if (this.allowed_terms.allowed_post_terms) {
                        res = await this.getCategories(null, this.allowed_terms.allowed_post_terms);
                    } else {
                        res = {data: []};
                    }
                }
                let allowed_meet_terms = this.allowed_terms.allowed_agenda_terms || this.allowed_terms['App\\Models\\MeetingRepositoryCategory'];
                if (allowed_meet_terms && allowed_meet_terms.length) {
                    res2 = await this.getMeetingRepositoryCategories(allowed_meet_terms);
                } else {
                    res2 = {data: []};
                }

                this.post_categories = await res.data;
                this.post_categories_resolved = true;
                this.agenda_categories = await res2.data;
                this.meet_repo_categories_resolved = true;
            },
            async storeNewSubscriber() {

                this.spinner = true;
                // let categories = [];
                // categories.push(this.category);

                let res;

                if (this.subscriber.title || this.siteType == 'wordpress') {
                    this.subscriber.title = this.name + ' ' + this.email;
                }
                // this.post.categories = categories;
                this.subscriber.name = this.name;
                this.subscriber.address = this.address;
                this.subscriber.city = this.city;
                this.subscriber.email = this.email;
                this.subscriber.phone = this.phone;
                this.subscriber.status = this.status;
                this.subscriber.comment_status = 'closed';
                this.subscriber.ping_status = 'closed';

                if (this.resident) {
                    this.subscriber.resident = 'on';
                } else {
                    this.subscriber.resident = this.resident;
                }

                if (this.subscribe_to_posts) {
                    this.subscriber.post = 'on';
                    this.subscriber.post_terms = this.getPostTerms();
                } else {
                    this.subscriber.post = this.subscribe_to_posts;
                }

                if (this.subscribe_to_agendas) {
                    this.subscriber.agenda = 'on';
                    this.subscriber.agenda_terms = this.getAgendaTerms();

                    this.subscriber.agenda_docs = [];

                    if (this.agenda) {
                        this.subscriber.agenda_docs.push('agendas');
                    }
                    if (this.minutes) {
                        this.subscriber.agenda_docs.push('minutes');
                    }
                    if (this.packets) {
                        this.subscriber.agenda_docs.push('packets');
                    }
                } else {
                    this.subscriber.agenda = this.subscribe_to_agendas;
                }

                if (this.receive_email && this.email != '') {
                    this.subscriber.receive_email = 'on';
                } else {
                    this.subscriber.receive_email = this.receive_email;
                }

                if (this.receive_text_message && this.phone != '') {
                    this.subscriber.receive_text_message = 'on';
                } else {
                    this.subscriber.receive_text_message = this.receive_text_message;
                }

                if (this.editingSubscriberId) {
                    res = await this.updateSubscriber(this.subscriber);
                } else {
                    res = await this.saveSubscriber(this.subscriber);
                }

                this.spinner = false;

                if (res.status === 201 || res.status === 200) {
                    let action = 'saved';
                    this.modal.hide();
                    this.$emit('subscriberSaved', action);
                } else {
                    this.sendError = true;
                }
            },
            getPostTerms() {
                console.log(this.post_terms)
                let post_terms = [];
                this.post_terms.map( (term, id) => { term ? post_terms.push(id) : false } );
                return post_terms;
            },
            getAgendaTerms() {
                let agenda_terms = [];
                this.agenda_terms.map( (term, id) => { term ? agenda_terms.push(id) : false } );
                return agenda_terms;
            },
            async setAllowedTerms() {
                let res = await this.getAllowedTerms();
                this.allowed_terms = await res.data;
            }
        },
        computed: {
            siteType() {
                return this.getSiteType();
            }
        },
        props: {
            editing: Boolean,
            editingSubscriberId: Number,
        },
        mixins: mixins
    }
</script>
<style scoped>
    .tw-create-subscriber-box-description {
        top: -10px;
    }
    .tw-checkbox label {
        margin-left: 30px;
    }
    .tw-checkbox label::before {
        left: -30px;
        top: calc(50% - 12px);
    }
    .tw-checkbox label::after {
        left: -23px;
        top: calc(50% - 6px);
    }
    .tw-checkbox input:disabled + label {
        opacity: .3;
    }
</style>
