<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="element-wrapper" :class="element.id + 'wrap'" v-if="element.props">
        <div class="contact-form-heading container">
            <h4>{{ element.props.heading ? element.props.heading : "" }}</h4>
        </div>
        <div :style="styleObject" v-if="elementHtml" v-html="elementHtml"></div>
    </div>
</template>

<script>
import pageBuilderMixin from "@/mixins/pageBuilderMixin";
export default {
    name: "ContactForm",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            elementHtml: null,
        };
    },
    async created() {
        this.elementHtml = await this.getElementFromOption();
        this.$emit("htmlValue", this.elementHtml);
    },
    methods: {
        /* onClick() {
            this.$emit("onClick", this.element);
        }, */
        //getElementFromOption() {},
    },
    computed: {
        styleObject() {
            return {
                padding: this.element.style.padding,
                margin: this.element.style.margin,
            };
        },
    },
    mixins: [pageBuilderMixin],
    emits: ["htmlValue"],
};
</script>
