<template>
    <div class="d-flex justify-content-center align-items-start flex-column">
        <div class="d-flex flex-row w-100 mb-2">
            <div class="d-flex justify-content-center align-items-center me-2">
                <a title="Unset Value" class="text-danger ms-1" v-if="value !== 0" style="font-size: 12px" @click="unsetValues()" role="button"><i class="fas fa-times-circle"></i></a>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-column">
                <input class="input-part input-part-first" type="number" v-model="value" style="width: 55px" min="0" @change="changeValue()" />
            </div>
            <div class="d-flex justify-content-start align-items-start flex-column">
                <select class="rounded-0 item-part select-part" v-model="unit" style="width: 58px" @change="changeUnit()">
                    <option value="px">px</option>
                    <option value="rem">rem</option>
                    <option value="%">%</option>
                </select>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "InputWithUnit",
    props: ["title", "values"],
    data: () => ({
        value: 0,
        unit: "px",
    }),
    created() {
        if (this.values) {
            const valueArray = this.values.split(" ");
            if (valueArray) {
                if (this.values.includes("px")) {
                    this.unit = "px";
                } else if (this.values.includes("rem")) {
                    this.unit = "rem";
                } else {
                    this.unit = "%";
                }
                this.value = valueArray[0].replace(this.unit, "");
            }
        } else {
            this.value = "auto";
        }
    },
    methods: {
        changeValue() {
            this.$emit("changeValue", this.val);
        },

        changeUnit() {
            this.$emit("changeValue", this.val);
        },

        unsetValues() {
            this.value = 0;
            this.$emit("changeValue", this.val);
        },
    },
    computed: {
        val() {
            return `${this.value}${this.unit}`;
        },
    },
    emits: ["changeValue"],
};
</script>
<style scoped>
.input-part {
    background: #fff !important;
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    color: #333 !important;
    display: inline;
    font-size: 13px;
    height: auto;
    line-height: 15px;
    margin: 1px;
    outline: none;
    padding: 3px 6px;
    width: auto;
    box-sizing: border-box;
    height: 31.5px;
}

.input-part-first {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.select-part {
    background: #e6eaed;
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    color: #333 !important;
    display: inline;
    font-size: 13px;
    height: auto;
    line-height: 15px;
    margin: 1px;
    outline: none;
    padding: 3px 6px;
    width: auto;
    box-sizing: border-box;
    height: 31.5px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.input-part-info {
    font-size: 10px;
    font-weight: bold;
}

.input-unit-title {
    font-size: 12px;
}
</style>
