<template>
	<section id="tw-view-403">
		<div class="bg-white">
			<div class="p-4 py-5">
				<div class="d-flex justify-content-center mt-5">
					<h2 class="display-1"><b>403</b></h2>
				</div>
				<div class="d-flex justify-content-center mb-5">
					<h4>Forbidden</h4>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: '403',
		components: {

		},
		data: () => ({

		}),
		mounted() {
            const path = this.$route.params.pathMatch;
            history.replaceState(null, null, path);
		},
		methods: {

		},
		mixins: []
    }
</script>
