<template>
	<section id="tw-view-account-settings-nav">
		<div class="bg-white tw-account-settings-section">
			<div class="p-3">
				<h3 class="fw-bold mb-4">Account Settings</h3>
				<ul class="nav flex-column">
					<li class="nav-item py-2">
						<router-link class="nav-link tw-default-text-color px-0" to="/account-settings/client-info" exact>Client Info</router-link>
					</li>
					<li class="nav-item py-2">
						<router-link class="nav-link tw-default-text-color px-0" to="/account-settings/contract-info" exact>Contract Info</router-link>
					</li>
					<li class="nav-item py-2">
						<router-link class="nav-link tw-default-text-color px-0" to="/account-settings/billing-info" exact>Billing Info</router-link>
					</li>
					<li class="nav-item py-2">
						<router-link class="nav-link tw-default-text-color px-0" to="/account-settings/website-insight" exact>Website Insight</router-link>
					</li>
					<li class="nav-item py-2">
						<router-link class="nav-link tw-default-text-color px-0" to="/account-settings/emails" exact>Emails</router-link>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'ASNav',
		components: {

		},
		data: () => ({

		}),
		mounted() {

		},
		methods: {

		},
		mixins: []
    }
</script>
<style scoped>
	li:not(:first-child) {
		border-top: 1px solid #C4C4C4;
	}
	a {
		font-size: 20px;
		font-weight: 300;
	}
	a.router-link-active {
		font-weight: 700;
		color: var(--tw-red) !important;
	}
</style>