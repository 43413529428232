<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="element-wrapper" :class="element.id + '-wrapper'" v-if="element.props" @dblclick.stop="onClick">
        <div :id="element.id" class="tab-element">
            <ul class="nav nav-tabs" :id="`tab-button-${element.id}`" role="tablist">
                <li class="nav-item" role="presentation" v-for="item in element.props.items" :key="`tab-button-${item.id}`">
                    <button
                        :id="`nav-${item.id}-tab`"
                        class="nav-link tab-element-button"
                        :class="item.active ? 'active' : ''"
                        data-bs-toggle="tab"
                        :data-bs-target="`#content-${item.id}`"
                        type="button"
                        role="tab"
                        :aria-controls="`nav-${item.id}`"
                        :style="[item.active ? labelActiveStyle : labelInactiveStyle]"
                        @click="changeTabActive(item)"
                    >
                        {{ item.title }}
                    </button>
                </li>
            </ul>
            <div :id="`tab-content-${element.id}`" class="tab-content">
                <div
                    v-for="item in element.props.items"
                    :key="`tab-body-${item.id}`"
                    role="tabpanel"
                    :id="`tab-content-${item.id}`"
                    :aria-labelledby="`nav-${item.id}-tab`"
                    class="tab-pane fade tab-element-content"
                    :class="item.active ? 'active show' : ''"
                    :style="styleObject"
                    tabindex="0"
                >
                    <div v-html="item.content"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Tab",
    props: {
        element: {
            required: false,
            type: Object,
        },
    },
    data: () => ({
        tabElement: null,
    }),
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
        changeTabActive(item) {
            this.tabElement.props.items.forEach((i) => {
                if (i.id !== item.id) {
                    i.active = false;
                } else {
                    i.active = true;
                }
            });
            // this.tabElement.props.items.find((i) => i.id === item.id).active = true;
            this.$emit("tabElement", this.tabElement);
        },
    },
    created() {
        this.tabElement = this.element;
    },
    computed: {
        styleObject() {
            return {
                backgroundColor: `${this.element.style.backgroundColor}`,
                padding: this.element.style.padding,
            };
        },
        labelInactiveStyle() {
            return {
                color: `${this.element.style.inactiveLabelColor}`,
                backgroundColor: this.element.style.inactiveLabelBackgroundColor,
                padding: this.element.style.labelPadding,
                fontSize: this.element.style.labelFontSize && this.element.style.labelFontSize != "0px" ? this.element.style.labelFontSize : "auto",
            };
        },
        labelActiveStyle() {
            return {
                color: `${this.element.style.activeLabelColor}`,
                backgroundColor: this.element.style.activeLabelBackgroundColor ? this.element.style.activeLabelBackgroundColor : this.element.style.backgroundColor ? this.element.style.backgroundColor : "white",
                padding: this.element.style.labelPadding,
                fontSize: this.element.style.labelFontSize && this.element.style.labelFontSize != "0px" ? this.element.style.labelFontSize : "auto",
            };
        },
    },
    emits: ["onClick", "tabElement"],
};
</script>
