<template>
	<section id="tw-view-website-insight" class="flex-grow-1 d-flex flex-column">
		<div class="bg-white tw-account-settings-section d-flex flex-column flex-grow-1">
			<div class="p-4 flex-grow-1 d-flex flex-column">
					
				<h3 class="fw-bold mb-4">Website Insights</h3>
				<div id="tw-ga-chart-wrapper" class="p-3 tw-light-grey-bg-color flex-grow-1 d-flex flex-column position-relative">
					<div v-if="commingSoonOverlay && loaded" class="position-absolute top-0 bottom-0 start-0 end-0 p-2 d-flex">
						<div class="bg-white opacity-75 flex-grow-1 rounded d-flex align-items-center justify-content-center tw-font-size-18 fw-bold">
							<span>Coming soon!</span>
						</div>
					</div>
					<div class="row tw-font-size-18">
						<div class="col-6">Website visits</div>
						<div class="col-6">
							<select id="tw-ga-chart-range-select" class="form-select tw-red-color text-center ms-auto" v-model="gaRange">
								<option v-for="range in dateRangeOptions" :value="range" :key="range">{{ range }}</option>
							</select>
						</div>
					</div>
					<div id="tw-ga-chart-inner-wrapper">
						<Line
						ref="line"
						v-if="loaded"
						:chart-options="chartOptions"
						:chart-data="chartData"
						:chart-id="chartId"
						:dataset-id-key="datasetIdKey"
						:plugins="plugins"
						:css-classes="cssClasses"
						:styles="styles"
						:width="width"
						:height="height"
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import accountSettingsMixin from '@/mixins/accountSettingsMixin';
	import { Line } from 'vue-chartjs'
	// import { Line, generateChart } from 'vue-chartjs'
	import { Chart as ChartJS, Title, Tooltip, LineElement, LinearScale, LineController, PointElement, CategoryScale, Filler } from 'chart.js'
	// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

	ChartJS.register(Title, Tooltip, LineElement, LinearScale, LineController, PointElement, CategoryScale, Filler)
	// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
	export default {
		name: 'WebsiteInsight',
		components: {
			Line,
		},
		data: () => ({
			chartData: {
				labels: [],
				datasets: [ 
							{ 
								label: '',
								lineTension: 0,
								borderColor: 'rgba(78,96,120,.8)',
								// backgroundColor: 'rgba(78,96,120,.8)',
								data: [],
								// fill: true,
							} 
						]
			},
			chartOptions: {
				responsive: true,
				bezierCurve : false,
				maintainAspectRatio: false,
				// aspectRatio: 1,
				legend: {
					fontColor: 'red'
				},
				layout: {
					padding: {
						top: 70
					}
				},
				scales: {
					y: {
						ticks: {
							stepSize: 25,
							color: "#969696",
							font: {
								size: '18px',
							},
						},
						grid: {
							borderDash: [8, 8],
						},
						beginAtZero: true,
					},
					x: {
						ticks: {
							color: '#969696',
							font: {
								size: '14px',
								// weight: '700'
							},
						},
						grid: {
							display: false,
						},
					},
				}
			},
			startDate: '7daysAgo',
			gaRange: 'Last 7 days',
			dateRangeOptions: ['Last 7 days', 'Last 30 Days', 'Last 90 Days', 'One Year'],
			loaded: false,
			commingSoonOverlay: false,
		}),
		mounted() {
			this.setChartData();
		},
		watch: {
			gaRange: async function (newVal, oldVal) {
				if (newVal != oldVal) {
					await this.setStartDate(this.gaRange);
					await this.setChartData();
				}
			},
		},
		methods: {
			async setChartData() {
				// this.loaded = false;
				let res = await this.getSiteGoogleMetrics(this.startDate);


				if (res.error && res.error === 'Missing GA Code') {

						// console.log(res.error === 'Missing GA Code')
						this.commingSoonOverlay = true;

				} else {
					let dataChart = this.chartData;
					dataChart.labels = this.prepareLabels(res.labels);
					dataChart.datasets[0].data = res.data;

					this.chartData = dataChart;
				}


				this.loaded = true;
			},
			setStartDate (dateExpresion) {
				switch (dateExpresion) {
					case 'Last 7 days':
						this.startDate = '7daysAgo';
						break;
					case 'Last 30 Days':
						this.startDate = '30daysAgo';
						break;
					case 'Last 90 Days':
						this.startDate = '90daysAgo';
						break;
					case 'One Year':
						this.startDate = '365daysAgo';
						break;
					default:
						//tab to get here;
				}
			},
			prepareLabels (rawLabels) {
				let formatedLabels = [];
				let self = this;
				rawLabels.forEach( function(label){
					formatedLabels.push(self.moment(label).format('M/D/YY'))
				})

				return formatedLabels;
			}
		},
		props: {
			chartId: {
				type: String,
				default: 'line-chart'
			},
			datasetIdKey: {
				type: String,
				default: 'label'
			},
			width: {
				type: Number,
				default: 50
			},
			height: {
				type: Number,
				default: 20
			},
			cssClasses: {
				default: 'chart-container mt-auto',
				type: String
			},
			styles: {
				//  class="chart-container" style="position: relative; height:40vh; width:80vw"
				type: Object,
				default: () => {}
			},
			plugins: {
				type: Array,
				default: () => {}
			}
		},
		mixins: [accountSettingsMixin]
    }
</script>
<style scoped>
	#tw-ga-chart-wrapper {
		border-radius: 10px;
	}
	#tw-ga-chart-inner-wrapper {
		min-height: 300px;
	}
	#tw-ga-chart-range-select {
		width: 160px;
		height: 42px;
		border: 1px solid var(--tw-red);
		border-radius: 5px !important;
	}
	#tw-ga-chart-range-select:focus {
		box-shadow: none;
	}
	.chart-container {
		height: 100%;
		min-height: 300px;
		/*width: 80vw;*/
	}
</style>