<template>
    <div v-if="element && element.style && element.style.customCss" v-html="'<style>' + element.style.customCss + '</style>'"></div>
    <div class="element-wrapper" :class="element.id + 'wrap'" v-if="element.props" @click.stop="onClick()" :style="styleObject">
        <template v-if="element.props.imagePosition === 'AboveHeading' || element.props.imagePosition === 'BelowHeading'">
            <!-- Image Above -->
            <div :style="imgWrapperStyle" v-if="element.props.image !== '' && element.props.imagePosition === 'AboveHeading'">
                <img :style="imgStyleObject" :src="element.props.image" class="" />
            </div>
            <!-- Heading  -->
            <h1 :style="headingStyle" v-if="element.props.headingTag == 'h1'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h1>
            <h2 :style="headingStyle" v-if="element.props.headingTag == 'h2'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h2>
            <h3 :style="headingStyle" v-if="element.props.headingTag == 'h3'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h3>
            <h4 :style="headingStyle" v-if="element.props.headingTag == 'h4'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h4>
            <h5 :style="headingStyle" v-if="element.props.headingTag == 'h5'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h5>
            <h6 :style="headingStyle" v-if="element.props.headingTag == 'h6'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h6>
            <!-- Image Below -->
            <div :style="imgWrapperStyle" v-if="element.props.image !== '' && element.props.imagePosition === 'BelowHeading'">
                <img :style="imgStyleObject" :src="element.props.image" class="" />
            </div>
            <!-- Text -->
            <div :style="textStyle" v-html="element.props.text"></div>
        </template>

        <div v-else class="row">
            <div class="col-6" v-if="element.props.imagePosition === 'LeftOfTextAndHeading'">
                <!-- Image -->
                <div :style="imgWrapperStyle" v-if="element.props.image !== ''">
                    <img :style="imgStyleObject" :src="element.props.image" class="" />
                </div>
            </div>
            <div class="col-6" v-if="element.props.imagePosition === 'LeftOfTextAndHeading'">
                <!-- Heading  -->
                <h1 :style="headingStyle" v-if="element.props.headingTag == 'h1'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h1>
                <h2 :style="headingStyle" v-if="element.props.headingTag == 'h2'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h2>
                <h3 :style="headingStyle" v-if="element.props.headingTag == 'h3'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h3>
                <h4 :style="headingStyle" v-if="element.props.headingTag == 'h4'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h4>
                <h5 :style="headingStyle" v-if="element.props.headingTag == 'h5'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h5>
                <h6 :style="headingStyle" v-if="element.props.headingTag == 'h6'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h6>
                <!-- Text -->
                <div :style="textStyle" v-html="element.props.text"></div>
            </div>
            <div class="col-6" v-if="element.props.imagePosition === 'RightOfTextAndHeading'">
                <!-- Heading  -->
                <h1 :style="headingStyle" v-if="element.props.headingTag == 'h1'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h1>
                <h2 :style="headingStyle" v-if="element.props.headingTag == 'h2'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h2>
                <h3 :style="headingStyle" v-if="element.props.headingTag == 'h3'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h3>
                <h4 :style="headingStyle" v-if="element.props.headingTag == 'h4'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h4>
                <h5 :style="headingStyle" v-if="element.props.headingTag == 'h5'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h5>
                <h6 :style="headingStyle" v-if="element.props.headingTag == 'h6'" :class="element.class">{{ element.props.heading ? element.props.heading : "Add your heading text" }}</h6>
                <!-- Text -->
                <div :style="textStyle" v-html="element.props.text"></div>
            </div>
            <div class="col-6" v-if="element.props.imagePosition === 'RightOfTextAndHeading'">
                <!-- Image -->
                <div :style="imgWrapperStyle" v-if="element.props.image !== ''">
                    <img :style="imgStyleObject" :src="element.props.image" class="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Callout",
    props: {
        element: {
            required: true,
            type: Object,
        },
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.element);
        },
        checkInputValue(value) {
            if (value && value != "0px" && value != "0%" && value != "0rem") {
                return true;
            } else {
                return false;
            }
        },

        checkTwoValuesReturn(first, second) {
            if (this.checkInputValue(first)) {
                return first;
            } else if (this.checkInputValue(second)) {
                return second;
            } else {
                return "auto";
            }
        },
    },
    computed: {
        styleObject() {
            return {
                width: this.element.style.width && this.element.style.width != "0%" && this.element.style.width != "0px" && this.element.style.width != "0rem" ? this.element.style.width : "auto",
                backgroundColor: this.element.style.backgroundColor,
                color: this.element.style.color,
                padding: this.element.style.padding,
                margin: this.element.style.margin,
                borderRadius: this.element.style.borderRadius,
            };
        },
        headingStyle() {
            return {
                textAlign: this.element.style.headingTextAlign ? this.element.style.headingTextAlign : "left",
                padding: this.element.style.headingPadding ? this.element.style.headingPadding : "auto",
                color: this.element.style.headingColor ? this.element.style.headingColor : "",
            };
        },
        textStyle() {
            return {
                padding: this.element.style.textPadding ? this.element.style.textPadding : "auto",
                color: this.element.style.textColor ? this.element.style.textColor : "",
            };
        },
        imgStyleObject() {
            return {
                width: this.checkInputValue(this.element.style.fitWidth) ? this.element.style.fitWidth : "auto",
                height: this.checkInputValue(this.element.style.fitHeight) ? this.element.style.fitHeight : "auto",
                borderRadius: this.element.style.imageBorderRadius,
                objectFit: "cover",
            };
        },
        imgWrapperStyle() {
            return {
                textAlign: this.element.style.imageAlign,
            };
        },
    },
    emits: ["onClick"],
};
</script>
