<template>
    <div v-if="showElementSetting" ref="draggableElementSetting" id="draggable-element-setting-container" :style="'top:' + top + 'px;'">
        <div id="draggable-header" @mousedown="dragMouseDown">
            <span
                >Element: <code>{{ element.id }}</code></span
            >
            <span @click="closeElementSetting()" class="close-button"><i class="fas fa-times"></i></span>
        </div>
        <div class="element-setting-container">
            <div v-if="loading" class="element-setting-container-loader"><i class="fa fa-spinner fa-spin me-2"></i> Loading</div>
            <div v-else>
                <template v-if="element.type === 'Column'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Column" />
                </template>
                <template v-if="element && element.type === 'Heading'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Heading" />
                </template>
                <template v-if="element && element.type === 'Paragraph'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Paragraph" />
                </template>
                <template v-if="element && element.type === 'Button'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Button" />
                </template>
                <template v-if="element && element.type === 'Photo'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Photo" />
                </template>
                <template v-if="element && element.type === 'Slider'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Slider" />
                </template>
                <template v-if="element && element.type === 'Accordion'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Accordion" />
                </template>
                <template v-if="element && element.type === 'Tab'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Tab" />
                </template>
                <template v-if="element && element.type === 'ContentSlider'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.ContentSlider" />
                </template>
                <template v-if="element && element.type === 'Gallery'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Gallery" />
                </template>
                <template v-if="element && element.type === 'Html'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Html" />
                </template>
                <template v-if="element && element.type === 'Callout'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Callout" />
                </template>
                <template v-if="element && element.type === 'Textblock'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Textblock" />
                </template>
                <template v-if="element && element.type === 'Separator'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.Separator" />
                </template>
                <template v-if="element && element.type === 'ContactForm'">
                    <ElementSettingProperty v-on:close="closeElementSetting" :element="element" :property="properties.ContactForm" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import PropertiesData from "../../views/page-builder/properties";
import ElementSettingProperty from "./ElementSettingProperty.vue";
export default {
    name: "ElementSetting",
    components: {
        ElementSettingProperty,
    },
    data: function () {
        return {
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0,
            },
            properties: PropertiesData,
        };
    },
    created() {},
    props: ["showElementSetting", "loading", "element", "top"],
    methods: {
        dragMouseDown: function (event) {
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag: function (event) {
            event.preventDefault();
            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            // set the element's new position:
            this.$refs.draggableElementSetting.style.top = this.$refs.draggableElementSetting.offsetTop - this.positions.movementY + "px";
            this.$refs.draggableElementSetting.style.left = this.$refs.draggableElementSetting.offsetLeft - this.positions.movementX + "px";
        },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
        closeElementSetting() {
            this.$emit("close");
        },
    },
};
</script>

<style>
#draggable-element-setting-container {
    position: absolute;
    /*top: 300px;*/
    left: 100px;
    z-index: 1051;
    border-radius: 5px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    resize: both;
    overflow: auto;
    min-height: 400px;
    min-width: 700px;
    width: 800px;
    max-width: calc(100vw - 300px);
    max-height: calc(100vh - 200px);
    background-color: rgba(255, 255, 255, 0.98);
    /*  -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px); */
}
#draggable-header {
    z-index: 10;
    background-color: #f5f7f9;
    min-width: 500px;
    padding: 10px;
    border-radius: 5px;
    cursor: move;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.element-setting-container {
    width: 100%;
    height: calc(100% - 39px);
}
.element-setting-container-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.01);
    color: #ccc;
    border-radius: 10px;
    height: calc(100% - 39px);
    min-height: 300px;
    font-size: 36px;
}
.close-button {
    cursor: pointer;
}

.setting-close-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.setting-content select {
    border-radius: 2px !important;
}

.setting-content label {
    font-size: 12px !important;
}

.setting-navigation {
    background-color: #e7ebef;
}

.setting-navigation .tab {
    border-radius: 0;
}

.setting-navigation .active-tab {
    background-color: white;
}

.setting-content {
    background-color: transparent;
    padding: 10px;
}
</style>
