<template>
    <div class="color-chooser d-flex align-items-center">
        <div class="d-flex justify-content-center align-items-center me-1">
            <a title="Unset Value" class="text-danger ms-1" v-if="value !== 0" style="font-size: 12px" @click="unsetValues()" role="button"><i class="fas fa-times-circle"></i></a>
        </div>
        <input type="text" class="form-control form-control-sm rounded color-input" style="width: 80px" v-model="colorValue" />
        <select class="form-select form-select-sm select-color" v-model="colorValue" style="min-width: 125px">
            <option value="">NONE</option>
            <option :key="i" v-for="(col, i) in colors" :value="col.value">{{ col.name }}</option>
        </select>
        <input type="color" v-model="colorValue" class="color-chooser-color-input" title="Choose your color" />
    </div>
</template>

<script>
//import juniperAPI from '../../../../api/juniperApi'
export default {
    name: "ColorChooser",
    props: ["value", "themeSettings"],
    data() {
        return {
            colors: [],
            colorValue: "",
        };
    },
    computed: {},
    watch: {
        colorValue: function () {
            this.$emit("change", this.colorValue);
        },
        themeSettings: function () {
            this.filterColors();
        },
    },
    created() {
        this.colorValue = this.value;
        this.filterColors();
    },
    methods: {
        filterColors() {
            if (this.themeSettings) {
                this.themeSettings.forEach((option) => {
                    if (option.code.includes("_COLOR")) {
                        let obj = {
                            name: option.code.replace("_", " "),
                            value: option.value,
                        };
                        this.colors.push(obj);
                    }
                });
            }
        },
        unsetValues() {
            this.colorValue = "";
            this.$emit("changeValue", this.colorValue);
        },
    },
    emits: ["change"],
};
</script>
<style scoped>
.color-chooser-color-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    width: 31.5px;
    min-width: 31.5px;
    height: 31.5px;
    min-height: 31.5px;
}
.color-chooser-color-input::-webkit-color-swatch {
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: transparent !important;
    border-style: solid;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    color: #333 !important;
    display: inline;
    font-size: 13px;
    height: auto;
    line-height: 15px;
    margin: 1px;
    outline: none;
    padding: 3px 6px;
    width: auto;
    box-sizing: border-box;
    height: 31.5px;
}

.select-color {
    border-radius: 4px;
    background: #e6eaed;
    border-color: transparent !important;
    border-style: solid;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    color: #333 !important;
    display: inline;
    font-size: 13px;
    height: auto;
    line-height: 15px;
    margin: 1px;
    outline: none;
    padding: 3px 6px;
    width: auto;
    box-sizing: border-box;
    height: 31.5px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.color-input {
    border-color: transparent !important;
    border-style: solid;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    color: #333 !important;
    display: inline;
    font-size: 13px;
    height: auto;
    line-height: 15px;
    margin: 1px;
    outline: none;
    padding: 3px 6px;
    width: auto;
    box-sizing: border-box;
    height: 31.5px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
</style>
