<!-- Modal -->
<template>
    <!-- <button type="button" class="btn btn-primary" @click="modal.show()">
        Launch demo modal
    </button> -->
    <div class="modal fade" id="supportModal" ref="supportModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="row">
                        <h3 class="modal-title col-12" id="modal-title">Edit page</h3>
                        <p class="col-12">Edit the contents of this page</p>
                    </div>
                </div>
                <div class="modal-body" id="modal-body">
                    <span>Title</span>
                    <h2>{{ title }}</h2>
                    <span>Content</span>
                    <div class="entry-content" v-html="content"></div>
                    <hr>
                    <p ng-show="vm.editing">For more editing options open &nbsp;<a :href="url" target="_blank" class="tw-red-color"><i class="fa fa-th-large"></i> Page builder</a></p>
                </div>
                <div class="modal-footer">
                    <button class="btn tw-dark-grey-bg-color text-white" type="button" @click="modal.hide()">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Modal } from 'bootstrap';
    import wordpressMixin from '@/mixins/wordpressMixin';
    export default {
        name: 'SupportModal',
        data: () => ({
            modal: null,
            page: {},
            title: '',
            content: '',
            url: '',
            auth: null,
            spinner: false,
            sendError: false,
        }),
        mounted() {
            this.modal = new Modal(this.$refs.supportModal);
        },
        methods: {
            showModal: function(value, pageId, auth) {
                if (value) {
                    this.auth = auth;
                    this.modal.show();
                    this.setPage(pageId, auth);
                }
            },
            async setPage(pageId, auth) {
                let res = await this.getPage(pageId);
                this.page = await res.data;

                this.title = this.page.title.rendered;
                this.content = this.page.content.rendered;
                this.url = this.page.link + '?fl_builder&dashboard-auth=' + auth;


//                 new Blob
// URL.createObjectURL(blob)



                
                // console.log(this.postContent)
                // this.$refs.editor.setHTML(this.postContent);
                // this.$refs.editor.pasteHTML(this.postContent);
                // this.category = this.post.categories[0];
                // this.status = this.post.status;
                // let self = this;
                // if (this.post._embedded && this.post._embedded['wp:featuredmedia']) {
                //     this.featuredMedia = this.post._embedded['wp:featuredmedia'].find(function(media) {
                //                             return media.id == self.post.featured_media;
                //                         });
                // }
            },
        },
        mixins: [wordpressMixin]
    }
</script>
<style scoped>
    
</style>